import React, { useEffect, useState } from "react";
import HubSpotSupport from "../HubSpotSupport";
import { Container, Grid, Paper, Typography } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";

export default function Support() {
  const theme = createMuiTheme({
    typography: {
      fontFamily: "Arial",
    },
    palette: {
      type: "light",
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <div style={{ marginTop: "2%" }}>
        <Container maxWidth="md">
          <Grid container style={{ backgroundColor: "#FFFFFF", padding: 15 }}>
            <Grid item xs={12}>
              <Paper elevation={0} style={{ textAlign: "center" }}>
                <Typography
                  style={{ color: "#2D383A" }}
                  variant="h5"
                  component="h5"
                >
                  <strong>
                    Feel free to email our support team at Securestack.com, or
                    fill out the form below.
                  </strong>
                </Typography>
              </Paper>
            </Grid>
            <br />
            <br />
            <Grid item xs={12}>
              <HubSpotSupport />
            </Grid>
          </Grid>
        </Container>
      </div>
    </ThemeProvider>
  );
}
