import axios from "axios";

const DownloadCode = (downloadUrl) => {
  console.log("The link was clicked.");
  axios({
    url: `${downloadUrl}`,
    method: "GET",
    responseType: "stream", // important
  }).then((response) => {
    console.log(JSON.stringify(response.data));
    const url = window.URL.createObjectURL(
      new Blob([JSON.stringify(response.data)], { type: "text" })
    );

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Solution");
    document.body.appendChild(link);
    link.click();
  });
};

export default DownloadCode;
