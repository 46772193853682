const postTaggingDB = async (
  scanreference,
  murl,
  region,
  size,
  vertical,
  type,
  email
) => {
  console.log(type);
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/tagging`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        scanreference: `${scanreference}`,
        murl: `${murl}`,
        region: `${region}`,
        size: `${size}`,
        vertical: `${vertical}`,
        type: `${type}`,
        test: "test",
        email: `${email}`,
      }),
    }
  );

  const responseData = await response.json();
  return responseData;
};
export default postTaggingDB;
