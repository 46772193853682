import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';



const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: 'red',
  },
  barColorPrimary: {
    backgroundColor: 'white',
  },
})(LinearProgress);



// Inspired by the Facebook spinners.
// const useStylesFacebook = makeStyles({
//   root: {
//     position: 'relative',
//   },
//   top: {
//     color: '#eef3fd',
//   },
//   bottom: {
//     color: '#6798e5',
//     animationDuration: '550ms',
//     position: 'absolute',
//     left: 0,
    
//   },
// });



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

export default function CustomizedProgressBars() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      
      <ColorLinearProgress  style={{height:5}} />
     
      
    </div>
  );
}