import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Typography, Button } from "@material-ui/core";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import apps from "../../../images/ArchitectureImages/apps.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "justify",
    color: theme.palette.text.secondary,
  },
  dot: {
    backgroundColor: "white",

    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.2)",
    },
  },
}));

export default function Application(props) {
  const classes = useStyles();
  const [applist, setApplist] = React.useState(false);
  console.log(props.data);
  let availableData = props.data.applicationver;
  if (!applist) {
    replaceCommaLine(availableData);

    function replaceCommaLine(data) {
      //convert string to array and remove whitespace
      let dataToArray = data.split("\n").map((item) => item.trim());
      console.log(dataToArray);
      //convert array to string replacing comma with new line
      //setlistip(dataToArray);
      setApplist(dataToArray);
      return dataToArray;
    }
  }

  function GenrealDetails(props) {
    return (
      <React.Fragment>
        <Grid container spacing={1}>
          <Paper elevation={0} className={classes.paper}>
            {/* {props.data.poweredby && (
              <Typography
                style={{ fontFamily: "courier" }}
                variant="h6"
                component="h6"
              >
                AppServer:
                <strong>{props.data.poweredby}</strong>
              </Typography>
            )}
            {props.data.aspnet != 0 && (
              <Typography
                style={{ fontFamily: "courier" }}
                variant="h6"
                component="h6"
              >
                Version:
                <strong>{props.data.aspnetversion}</strong>
              </Typography>
            )}
            {props.data.php != 0 && (
              <Typography
                style={{ fontFamily: "courier" }}
                variant="h6"
                component="h6"
              >
                Version:
                <strong>{props.data.phpversion}</strong>
              </Typography>
            )} */}
            <Typography
              style={{ fontFamily: "courier" }}
              variant="h6"
              component="h6"
            >
              Application List:
            </Typography>

            {applist &&
              applist.map((row) => (
                <Typography
                  style={{ fontFamily: "orbitron" }}
                  variant="h6"
                  component="h6"
                >
                  {row}
                </Typography>
              ))}
            {/* {props.data.express != 0 && (
            <Grid item xs={12}>
              <Paper elevation={0} className={classes.paper}>
                <Typography
                  style={{ fontFamily: "courier" }}
                  variant="h6"
                  component="h6"
                >
                  AppServer:
                  <strong>{props.data.poweredby}</strong>
                </Typography>
              </Paper>
            </Grid>
          )} */}
          </Paper>
        </Grid>
      </React.Fragment>
    );
  }
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <Timeline align="alternate">
            <TimelineItem>
              <TimelineDot variant="outlined" className={classes.dot}>
                <img src={apps} />
              </TimelineDot>
            </TimelineItem>
          </Timeline>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid container item xs={4} spacing={2}>
          <GenrealDetails data={props.data} />
        </Grid>
        <Grid container item xs={1} spacing={2}></Grid>
        <Grid container item xs={4} spacing={2}></Grid>
        <Grid container item xs={3} spacing={2}></Grid>
      </Grid>
    </div>
  );
}
