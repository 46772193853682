import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { createMuiTheme } from "@material-ui/core/styles";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Stepper from "../../components/timeline/Stepper";
import Typing from "react-typing-animation";
import SlideshowIcon from "@material-ui/icons/Slideshow";
import CloseIcon from "@material-ui/icons/Close";
import blood from "../../images/bloodhound.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Timeline from "../timeline/Timeline";
import Solutions from "../../components/TabComp/Solution/Solutions";
import dog from "../../images/dog.png";
import Graphs from "../../components/animatedComp";
import PrivateRoute from "../../components/PrivateRoute";
import AppBar from "@material-ui/core/AppBar";
import { ThemeProvider, mergeClasses } from "@material-ui/styles";
import CardComp from "../../components/ServicesCompCard/CardComp";
import Techstack from "../../components/Techstack";
import Toolbar from "@material-ui/core/Toolbar";
import { List, Tooltip, Link, Grid } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import ScanHistory from "../../views/ScanHistory";
import Download from "../../components/Download";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Profile from "../../components/Profile";
import IconButton from "@material-ui/core/IconButton";
import SolutionsInfo from "../../components/CSPInfo";
import Alert from "@material-ui/lab/Alert";
import Bloodhoundmain from "../../views/Bloodhoundmain";
import SnackBar from "../../components/SnackBar";
import CombinationDashboard from "../../views/CombinationDashboard";
import StackTimeline from "../../components/timeline/StackTimeline";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
  Paper,
  ListItem,
  Button,
  Avatar,
  Menu,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { default as Toggle } from "@material-ui/core/Switch";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { AiOutlineSecurityScan, AiOutlineHistory } from "react-icons/ai";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useAuth0 } from "../../react-auth0-spa";
import { FiTarget } from "react-icons/fi";
import { RiAccountCircleLine } from "react-icons/ri";
import { BsGraphUp } from "react-icons/bs";
import Bloodhound from "../../views/Bloodhound";
import About from "../../views/About";
import { Router, Route, Switch } from "react-router-dom";
import history from "../../utils/history";
import Nav from "../../components/NavBar/Nav";
import Targets from "../../views/Targets";
import History from "../../components/History";
import Support from "../../views/Support";
import Footer from "../Footer";
import ChangesHistory from "../../views/ChangesHistory";
import SecTimeline from "../SecTimeline";
import animeCompare from "../../components/animeCompare";
import SettingsBackupRestoreOutlinedIcon from "@material-ui/icons/SettingsBackupRestoreOutlined";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import CompareMurl from "../../components/CompareMurl";
import CompareOutlinedIcon from "@material-ui/icons/CompareOutlined";
import TodoApp from "../../components/Comparision/TodoTest";
import SwaggerApi from "../API/SwaggerApi";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  typography: {
    fontFamily: "Orbitron",
    color: "#ad2007",
    fontSize: 30,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  img: {
    maxHeight: "70%",
    maxWidth: "70%",
    marginRight: "26%",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  appBarShift: {
    marginLeft: drawerWidth,

    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  verticalBar: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
      // fontSize: 8,
    },
  },
  typoSmallscreen: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  videoButton: {
    fontSize: 30,
    color: "#ad2007",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const theme = createMuiTheme();

theme.typography.h6 = {
  fontSize: "0.4rem",
  "@media (min-width:600px)": {
    fontSize: "0.8rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "0.5rem",
  },
};

export default function DrawerNav(props) {
  const [dark, setDark] = useState(
    localStorage.getItem("Mode") == "dark" ? true : false
  );
  const classes = useStyles();
  const colortheme = createMuiTheme({
    typography: {
      fontFamily: "Arial",
    },
    palette: {
      type: dark ? "dark" : "light",
    },
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const { isAuthenticated, user, logout } = useAuth0();
  const [open, setOpen] = React.useState(false);
  const [compo, setCompo] = React.useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const handleOpenHowTo = () => {
    setOpenHow(true);
  };
  const [openHow, setOpenHow] = React.useState(false);
  const handleCloseHowTo = () => {
    setOpenHow(false);
  }; ///End Main

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function ChangeTheme(dark) {
    setDark(dark);
  }
  const divStyleDark = {
    backgroundColor: "#424242",
  };
  const divStyleLight = {
    backgroundColor: "#FFFFFF",
  };

  //Download Options
  const [anchorElDownload, setAnchorElDownload] = React.useState(null);

  const handleClickDownload = (event) => {
    setAnchorElDownload(event.currentTarget);
  };

  const handleCloseDownload = () => {
    setAnchorElDownload(null);
  };

  //End Download Options

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        style={dark ? divStyleDark : divStyleLight}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <div>
            {isAuthenticated && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <img className={classes.img} src={blood} />
              </IconButton>
            )}
          </div>
          <Typography className={classes.typography} variant="h6">
            Bloodhound
          </Typography>

          <Tooltip
            title={
              localStorage.getItem("Mode") == "dark"
                ? "Switch to Light Mode"
                : "Switch to Dark Mode"
            }
          >
            <Toggle
              checked={dark}
              onChange={() => {
                setDark(!dark);
                console.log(props.theme);
                if (localStorage.getItem("Mode") == "dark") {
                  localStorage.setItem("Mode", "light");
                } else {
                  localStorage.setItem("Mode", "dark");
                }
                props.pushData(!props.theme);
              }}
            />
          </Tooltip>

          {isAuthenticated && (
            <React.Fragment>
              <Button
                className={classes.button}
                style={{
                  marginRight: "auto",
                  marginTop: "auto",
                  position: "absolute",
                  right: 0,
                }}
                onClick={handleClick}
              >
                <Avatar alt={user.name} src={"/static/images/avatar/1.jpg"} />
              </Button>
              <IconButton
                onClick={handleOpenHowTo}
                className={classes.verticalBar}
                style={{
                  marginRight: "auto",
                  marginTop: "auto",
                  position: "absolute",
                  right: 100,
                }}
                aria-label="search"
              >
                Bloodhound YouTube
                <Tooltip title="Our How-To video">
                  <SlideshowIcon style={{ fontSize: 40, color: "#ad2007" }} />
                </Tooltip>
              </IconButton>

              <Dialog
                maxWidth="lg"
                fullScreen={fullScreen}
                allowfullscreen
                open={openHow}
                onClose={handleCloseHowTo}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title">
                  {"Bloodhound YouTube "}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/videoseries?list=PL_8Xjyi5rInyCLJPnVt4O9hP3MNv6DVbX"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                      webkitallowfullscreen
                      mozallowfullscreen
                      oallowfullscreen
                      msallowfullscreen
                    ></iframe>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={handleCloseHowTo} color="primary">
                    <CloseIcon style={{ color: "#ad2007" }} />
                  </Button>
                </DialogActions>
              </Dialog>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>
                  <Link href="/profile" color="inherit">
                    Profile
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link
                    style={{ textDecoration: "none" }}
                    href={`https://demolab.securestack.com`}
                    color="inherit"
                  >
                    CloudBuilder
                  </Link>
                </MenuItem>
                <MenuItem onClick={() => logout()}>Logout</MenuItem>
              </Menu>
              <Footer />
            </React.Fragment>
          )}
          {!isAuthenticated && (
            <React.Fragment>
              <IconButton
                onClick={handleOpenHowTo}
                className={classes.verticalBar}
                style={{
                  marginRight: "auto",
                  marginTop: "auto",
                  position: "absolute",
                  right: 100,
                }}
                aria-label="search"
              >
                What is Bloodhound?
                <Tooltip title="Our How-To video">
                  <SlideshowIcon style={{ fontSize: 40, color: "#ad2007" }} />
                </Tooltip>
              </IconButton>

              <Dialog
                maxWidth="lg"
                fullScreen={fullScreen}
                allowfullscreen
                open={openHow}
                onClose={handleCloseHowTo}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title">
                  {"Bloodhound YouTube "}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/n8f7Vy53QXs"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                      webkitallowfullscreen
                      mozallowfullscreen
                      oallowfullscreen
                      msallowfullscreen
                    ></iframe>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={handleCloseHowTo} color="primary">
                    <CloseIcon style={{ color: "#ad2007" }} />
                  </Button>
                </DialogActions>
              </Dialog>
              <Button
                className={classes.button}
                style={{
                  marginRight: "auto",
                  marginTop: "auto",
                  position: "absolute",
                  right: 0,
                }}
                href="/bloodhound"
              >
                <Typography
                  className={classes.typography}
                  variant="h6"
                  style={{ fontFamily: "Orbitron", color: "#ad2007" }}
                >
                  Login
                </Typography>
              </Button>
              <Alert
                className={classes.verticalBar}
                style={{ backgroundColor: "transparent" }}
                outline="none"
                severity="info"
              >
                <Typing> Scans are 100% FREE during BETA</Typing>
              </Alert>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              ></Menu>

              <Footer />
            </React.Fragment>
          )}
        </Toolbar>
      </AppBar>
      {!isAuthenticated && (
        <Paper className={classes.verticalBar}>
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <div className={classes.toolbar}>
              <img src={dog} style={{ width: "70%" }} />
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </div>
            <Divider />
            <List>
              <Link
                href="/"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ListItem button component={Link} to="/">
                  <ListItemIcon>
                    <Tooltip title="Scan New">
                      <span>
                        <AiOutlineSecurityScan style={{ fontSize: 30 }} />
                      </span>
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText primary={"New Scan"} />
                </ListItem>
              </Link>

              <Link
                href="/about"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ListItem button key={"About"}>
                  <ListItemIcon>
                    <Tooltip title="About">
                      <span>
                        <InfoOutlinedIcon style={{ fontSize: 30 }} />
                      </span>
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText primary={"About"} />
                </ListItem>
              </Link>

              <Link
                href="/support"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ListItem button key={"Support"}>
                  <ListItemIcon>
                    <Tooltip title="Support">
                      <span>
                        <HelpOutlineOutlinedIcon style={{ fontSize: 30 }} />
                      </span>
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText primary={"Support"} />
                </ListItem>
              </Link>
            </List>
            <Divider />

            <ListItem button key={"Watch Our Video"}>
              <IconButton
                className={classes.videoButton}
                onClick={handleOpenHowTo}
                aria-label="search"
              >
                <Tooltip title="Support">
                  <span>
                    <SlideshowIcon className={classes.videoButton} />
                  </span>
                </Tooltip>
              </IconButton>
            </ListItem>
          </Drawer>
        </Paper>
      )}

      {isAuthenticated && (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <img src={dog} style={{ width: "70%" }} />
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List>
            <Link href="/" style={{ textDecoration: "none", color: "inherit" }}>
              <ListItem button component={Link} to="/">
                <ListItemIcon>
                  <Tooltip title="Scan New">
                    <span>
                      <AiOutlineSecurityScan style={{ fontSize: 30 }} />
                    </span>
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={"Scan New"} />
              </ListItem>
            </Link>
            <Link
              href="/targets"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItem button key={"Targets"}>
                <ListItemIcon>
                  <Tooltip title="Targets">
                    <span>
                      <FiTarget style={{ fontSize: 30 }} />
                    </span>
                  </Tooltip>
                </ListItemIcon>

                <ListItemText primary={"Targets"} />
              </ListItem>
            </Link>

            <Link
              href="/history"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItem button key={"History"}>
                <ListItemIcon>
                  <Tooltip title="History">
                    <span>
                      <AiOutlineHistory style={{ fontSize: 30 }} />
                    </span>
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={"Scan History"} />
              </ListItem>
            </Link>
            <Link
              href="/sectimeline"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItem button key={"graphtimeline"}>
                <ListItemIcon>
                  <Tooltip title="Security Timeline">
                    <span>
                      <BsGraphUp style={{ fontSize: 25 }} />
                    </span>
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={"Security Timeline"} />
              </ListItem>
            </Link>

            <Link
              href="/CompareMurl"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItem button key={"com[aretargets"}>
                <ListItemIcon>
                  <Tooltip title="Compare Targets">
                    <span>
                      <CompareOutlinedIcon style={{ fontSize: 25 }} />
                    </span>
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={"Compare Targets"} />
              </ListItem>
            </Link>

            <Link
              href="/changeshistory"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItem button key={"ChangesHistory"}>
                <ListItemIcon>
                  <Tooltip title="ChangesHistory">
                    <span>
                      <SettingsBackupRestoreOutlinedIcon
                        style={{ fontSize: 30 }}
                      />
                    </span>
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={"Changes History"} />
              </ListItem>
            </Link>

            <Link
              href="/about"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItem button key={"About"}>
                <ListItemIcon>
                  <Tooltip title="About">
                    <span>
                      <InfoOutlinedIcon style={{ fontSize: 30 }} />
                    </span>
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={"About"} />
              </ListItem>
            </Link>

            <Link
              href="/support"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItem button key={"Support"}>
                <ListItemIcon>
                  <Tooltip title="Support">
                    <span>
                      <HelpOutlineOutlinedIcon style={{ fontSize: 30 }} />
                    </span>
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={"Support"} />
              </ListItem>
            </Link>
            <Link
              href="/profile"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItem button key={"Logout"}>
                <ListItemIcon>
                  <Tooltip title="Profile">
                    <span>
                      <RiAccountCircleLine style={{ fontSize: 30 }} />
                    </span>
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={"Profile"} />
              </ListItem>
            </Link>

            <ListItem button key={"downloadCli"} onClick={handleClickDownload}>
              <ListItemIcon>
                <Tooltip title="Download CLI">
                  <span>
                    <GetAppOutlinedIcon style={{ fontSize: 30 }} />
                  </span>
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary={"Download CLI"} />
            </ListItem>
          </List>
          <Menu
            id="simple-menu"
            anchorEl={anchorElDownload}
            keepMounted
            open={Boolean(anchorElDownload)}
            onClose={handleCloseDownload}
          >
            <MenuItem onClick={handleCloseDownload}>
              <Link
                href="https://bloodhound.securestack.com/cli/mac/bloodhound"
                style={{ textDecoration: "none", color: "inherit" }}
                target="_blank"
              >
                Mac-OS
              </Link>
            </MenuItem>
            <MenuItem onClick={handleCloseDownload}>
              <Link
                href="https://bloodhound.securestack.com/cli/linux/bloodhound"
                style={{ textDecoration: "none", color: "inherit" }}
                target="_blank"
              >
                Linux
              </Link>
            </MenuItem>
          </Menu>
          <Divider />
          <ListItem button key={"Watch Our Video"}>
            <ListItemIcon>
              <IconButton
                className={classes.videoButton}
                onClick={handleOpenHowTo}
                aria-label="search"
              >
                <SlideshowIcon className={classes.videoButton} />
              </IconButton>
            </ListItemIcon>
            <ListItemText primary={"Video"} />
          </ListItem>
        </Drawer>
      )}

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <ThemeProvider theme={colortheme}>
          <Router history={history}>
            <Switch>
              <Route path="/" exact component={Bloodhound} />

              <Route path="/techstack" component={Techstack} />
              <Route path="/cardcomp" component={CardComp} />
              <Route path="/about" component={About} />
              <Route path="/sectimeline" component={SecTimeline} />
              <Route path="/animeCompare" component={animeCompare} />
              <Route path="/CompareMurl" component={CompareMurl} />
              <Route path="/TodoApp" component={TodoApp} />

              <Route path="/support" component={Support} />
              <Route path="/bloodhoundscan/:paramHost" component={Bloodhound} />
              <Route
                path="/bloodhoundscandeep/:deepParamHost"
                component={Bloodhound}
              />

              <Route path="/timeline" component={StackTimeline} />
              <Route path="/snackbar" component={SnackBar} />
              <Route path="/solutionsinfo" component={SolutionsInfo} />
              <Route path="/DrawerNav" component={DrawerNav} />
              <Route path="/api-v1" component={SwaggerApi} />
              <PrivateRoute path="/solutions" component={Solutions} />
              <PrivateRoute path="/dash" component={CombinationDashboard} />
              <PrivateRoute path="/bloodhound" component={Bloodhoundmain} />
              <PrivateRoute path="/history" component={History} />
              <PrivateRoute path="/changeshistory" component={ChangesHistory} />
              <PrivateRoute path="/targets" component={Targets} />
              <PrivateRoute path="/graphtimeline" component={Graphs} />

              {/* <Route path="/websocket" component={WebSocket} />  */}
              <PrivateRoute path="/profile" component={Profile} />
              <PrivateRoute path="/scanhistory" component={ScanHistory} />
              <Route path="/download" component={Download} />
              <PrivateRoute path="*" exact={true} component={Bloodhound} />
            </Switch>
          </Router>
        </ThemeProvider>
      </main>
    </div>
  );
}
