import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Button, Grid, Paper, Container } from "@material-ui/core/";

export default function CompBar(props) {
  console.log(props.chartdata);
  // Object.values(props.chartdata1).map((item, index) => console.log(item.chart));
  // function sortData() {
  //   Object.values(props.chartdata).map((item, index) => {
  //     console.log(item.chart);
  //     dat.push(item.chart);
  //   });
  // }
  const statePar = {
    series: props.chartdata,
    // {
    //   name: props.murl1,
    //   data: [
    //     props.chartdata1.hsts,
    //     props.chartdata1.csp,
    //     props.chartdata1.redirect,
    //     props.chartdata1.scalability,
    //     props.chartdata1.waf,
    //     props.chartdata1.firewall,
    //     props.chartdata1.policy,
    //     props.chartdata1.sovereign,
    //   ],
    // },
    // {
    //   name: props.murl2,
    //   data: [
    //     props.chartdata2.hsts,
    //     props.chartdata2.csp,
    //     props.chartdata2.redirect,
    //     props.chartdata2.scalability,
    //     props.chartdata2.waf,
    //     props.chartdata2.firewall,
    //     props.chartdata2.policy,
    //     props.chartdata2.sovereign,
    //   ],
    // },
    // {
    //   name: props.murl2,
    //   data: [
    //     props.chartdata2.hsts,
    //     props.chartdata2.csp,
    //     props.chartdata2.redirect,
    //     props.chartdata2.scalability,
    //     props.chartdata2.waf,
    //     props.chartdata2.firewall,
    //     props.chartdata2.policy,
    //     props.chartdata2.sovereign,
    //   ],
    // },
  };
  const stateOptions = {
    options: {
      chart: {
        type: "bar",
        height: 430,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: "down",
          },
        },
      },

      dataLabels: {
        enabled: false,
        offsetX: -6,
        style: {
          fontSize: "12px",
          colors: ["#00FF00"],
        },
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#00FF00"],
      },

      xaxis: {
        categories: [
          "HSTS",
          "CSP",
          "REDIRECT",
          "SCALABILITY",
          "WAF",
          "FIREWALL",
          "POLICY",
          "SOVEREIGNITY",
          "SSLISSUE",
        ],
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
    },
  };

  return (
    <div>
      <div id="card">
        <div id="chart">
          <Grid
            item
            xs={12}
            style={{ backgroundColor: "#FFFFFF", color: "black" }}
          >
            <Chart
              options={stateOptions.options}
              series={statePar.series}
              type="bar"
              height={500}
            />
          </Grid>
        </div>
      </div>
    </div>
  );
}
