import React from "react";
import ReactDOM from "react-dom";

export default class HubSpotSupport extends React.Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "4074824",
          formId: "eab4d75d-d2e6-4319-8ca0-1146c05e82a4",
          target: "#hubspotForm",
        });
      }
    });
  }

  render() {
    return (
      <div>
        <div id="hubspotForm"></div>
      </div>
    );
  }
}
