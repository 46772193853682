import React from "react";
import { Typography, TextField, Button, Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

function CompareInputGrid(props) {
  const handleTargetValue = (event, value, reason) => {
    console.log(reason);
    console.log(value);
    if (value === null) {
    } else {
      props.pushData(value, props.item);
    }
  };
  return (
    <Grid data xs={12}>
      <Autocomplete
        id={props.item}
        onChange={handleTargetValue}
        options={
          props.targets &&
          Object.values(props.targets).map((option) => option.target)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            value="default value"
            variant="outlined"
            label="Select or Search"
            margin="normal"
            helperText="Please select a Target"
          />
        )}
      />
    </Grid>
  );
}

export default CompareInputGrid;
