import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SlideshowIcon from "@material-ui/icons/Slideshow";
import Button from "@material-ui/core/Button";
import Tagging from "../components/Tagging";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Skeleton from "@material-ui/lab/Skeleton";
import { useTheme } from "@material-ui/core/styles";
import Timeline from "../components/timeline/Timeline";
import Typing from "react-typing-animation";
import Stepper from "../components/timeline/Stepper";
import { Slide, Zoom, Badge } from "@material-ui/core";

import Map from "../components/map/Map";
import {
  Grid,
  Paper,
  Snackbar,
  Typography,
  Link,
  Card,
  CardActionArea,
  DialogTitle,
  CardActions,
  CardContent,
  InputBase,
  Tooltip,
  DialogActions,
  DialogContent,
  DialogContentText,
  CardMedia,
  Box,
  IconButton,
  ButtonBase,
  Container,
  Collapse,
  Dialog,
  Divider,
} from "@material-ui/core";
import { useAuth0 } from "../react-auth0-spa";
import { AiOutlineSecurityScan } from "react-icons/ai";
import { BsFillCollectionFill } from "react-icons/bs";
import { MdReportProblem } from "react-icons/md";
import ISP from "../images/internet.png";
import ServicesComp from "../components/ServicesComp";
import Score from "../components/newScore";
import ProblemFound from "../components/ProblemFound";
import FinalScoreGauge from "../components/Charts/Gauge/FinalScoreGauge";
import ProgressBarc from "../components/ProgressBarc";
import ResponsiveImg from "../components/ResponsiveImg";
import ScoreCardFile from "../components/ScoreCardFile";
import ScoreCardMobile from "../components/ScoreCardFileMobile";
import postDataDB from "../functions/postDataDB";
import LeaderBoard from "../components/LeaderBoard";
import getFreeToken from "../functions/getFreeToken";
import mixpanel from "mixpanel-browser";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import ReactGA from "react-ga";
import PayDialog from "../components/PayDialog";
import TimerIcon from "@material-ui/icons/Timer";
import postDataDBFull from "../functions/postDataDBFull";
import getCountDB from "../functions/getCountDB";
import getTagInfo from "../functions/getTagInfo";
import getTopScore from "../functions/getTopScore";
import getWorstScore from "../functions/getWorstScore";
import getTimeoutData from "../functions/getTimeoutData";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  root1: {
    margin: theme.spacing(2),
    display: "flex-centered",
    textAlign: "center",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "transparent",
  },
  image: {
    position: "relative",
    height: 100,
    [theme.breakpoints.down("sm")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
      fontSize: 12,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundPosition: "center 40%",
  },

  imageBackdrop: {
    position: "absolute",
    color: "#ffffff",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${
      theme.spacing(1) + 6
    }px`,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "flex",
    color: "#ad2007",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 25,
    [theme.breakpoints.down("sm")]: {
      fontSize: 8,
    },
  },
  typography: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  iconButton: {
    padding: 10,
    color: "#ad2007",
  },
  divider: {
    height: 28,
    margin: 4,
    backgroundColor: "#FFFFFF",
  },
  button: {
    background: "#ad2007",
    color: "white",
    fontFamily: "Orbitron",
    [theme.breakpoints.down("sm")]: {
      fontSize: 8,
    },
  },
  verticalBar: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
      // fontSize: 8,
    },
  },
  hideMainVide: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
      // fontSize: 8,
    },
  },
}));

const BloodHound = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [data, setData] = useState(false);
  const [api, setAPI] = useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [scanCount, setScanCount] = React.useState(false);
  const [countAlert, setCountAlert] = useState(false);
  const [progress, setProgress] = useState(false);
  const [topScore, setTopScore] = useState(false);
  const [tagInfo, setTagInfo] = useState(false);
  const [worstScore, setWorstScore] = useState(false);
  const [hostname, setHostname] = useState({
    value: "",
    error: true,
    errorMsg: "Invalid Name",
  });
  const [charts, setCharts] = useState(false);
  const [ScoreGet, setScoreGet] = useState(false);
  const validator = {
    url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
    //url: /^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
  };
  const scanData = [
    { name: "isp", title: "ISP", picture: { ISP } },
    { name: "cloudver", title: "CLOUD" },
    { name: "cmsver", title: "CMS" },
    { name: "runningon", title: "RUNNINGON" },
    { name: "cdnver", title: "CDN" },
    { name: "webserver", title: "WEBSERVER" },
    { name: "poweredby", title: "POWEREDBY" },
    { name: "protectedby", title: "PROTECTEDBY" },
  ];
  const [openReportPopUp, setopenReportPopUp] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [tagpop, setTagpop] = React.useState(false);

  const { isAuthenticated, user, getTokenSilently } = useAuth0();

  const handleUrl = (e) => {
    if (!e.target.value.match(validator.url)) {
      setHostname({
        value: e.target.value,
        error: true,
        errorMsg: "Invalid Url",
      });
    } else
      setHostname({
        value: e.target.value,
        error: false,
        errorMsg: "",
      });
  };
  const keyPressed = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      callApi();
    }
  };
  const images = [
    {
      //url:`${dog}`,
      title: "FREE FULL REPORT",
      width: "30%",
      titlelogin: "FREE FULL REPORT",
    },
  ]; ///Url Check
  function getHostName(hostname) {
    var match = hostname.value.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
    if (
      match != null &&
      match.length > 2 &&
      typeof match[2] === "string" &&
      match[2].length > 0
    ) {
      return match[2];
    } else {
      return null;
    }
  } //End UrlCheck

  const getScoreBoardData = async () => {
    const token = await getTokenSilently();
    const topscore = await getTopScore(token);
    setTopScore(topscore);
    const worstscore = await getWorstScore(token);
    setWorstScore(worstscore);
  };

  const getTokenData = async () => {
    await setScoreGet(false);
    var email = "quickScan";
    const token = await getFreeToken();
    console.log(
      "THIS IS Returned Token FROM post" + JSON.stringify(token.accessToken)
    );
    // const UrlHostname = getHostName(hostname);
    // console.log(UrlHostname)
    const Url = getHostName(hostname);
    console.log(Url);
    if (Url) {
      if (user) {
        email = user.email;
      }

      let postScanData = await postDataDB(email, Url, token);
      if (!postScanData && postScanData.HOSTEXISTS !== "0") {
        console.log("Something went wrong");
        //Timeout trigger
        const interval = setInterval(async () => {
          //postScanData = true;
          console.log("This will run every 10 second!");
          postScanData = await getTimeoutData(
            token,
            localStorage.getItem("scanref")
          );
          console.log(postScanData);
          if (postScanData && postScanData.scanreference) {
            console.log("clear interval");
            clearInterval(interval);
            setData(postScanData);
            storeLocal(
              postScanData.scanreference,
              postScanData.priorscanref,
              postScanData.priorscanrefdomains
            );
            setCharts(true);
            window.scrollTo({ top: 600, behavior: "smooth" });
            setProgress(false);
            console.log("direct hostname");
          }
        }, 4000);

        //End Timeout trigger
      }

      if (postScanData.HOSTEXISTS === "0" && postScanData.BLOCKEDURL === "0") {
        console.log("Host Does Not Exist!");
        setOpenAlert(true);
        setProgress(false);
        hostname.value = "";
      } else if (
        postScanData.HOSTEXISTS === "0" &&
        postScanData.BLOCKEDURL === "1"
      ) {
        console.log("Host Is Blocked!");
        setOpenAlertBlockList(true);
        setProgress(false);
        hostname.value = "";
      } else if (postScanData && postScanData.scanreference) {
        setData(postScanData);
        storeLocal(
          postScanData.scanreference,
          postScanData.priorscanref,
          postScanData.priorscanrefdomains
        );
        setCharts(true);
        setProgress(false);
        console.log("direct hostname");
      }
    } else {
      if (user) {
        email = user.email;
      }
      let postScanData = await postDataDB(email, hostname.value, token);

      console.log(postScanData);
      if (!postScanData && !postScanData.scanreference) {
        console.log("Something went wrong");
        //Timeout trigger
        const interval = setInterval(async () => {
          //postScanData = true;
          console.log("This will run every 10 second!");
          console.log(localStorage.getItem("scanref"));
          postScanData = await getTimeoutData(
            token,
            localStorage.getItem("scanref")
          );
          console.log(postScanData);
          if (postScanData && postScanData.scanreference) {
            console.log("clear interval");
            clearInterval(interval);
            setData(postScanData);
            storeLocal(
              postScanData.scanreference,
              postScanData.priorscanref,
              postScanData.priorscanrefdomains
            );
            setCharts(true);
            window.scrollTo({ top: 600, behavior: "smooth" });
            setProgress(false);
            console.log("direct hostname");
          }
        }, 10000);

        //End Timeout trigger
      }

      if (postScanData.HOSTEXISTS === "0" && postScanData.BLOCKEDURL === "0") {
        console.log("Host Does Not Exist!");
        setOpenAlert(true);
        setProgress(false);
        hostname.value = "";
      } else if (
        postScanData.HOSTEXISTS === "0" &&
        postScanData.BLOCKEDURL === "1"
      ) {
        console.log("Host Is Blocked!");
        setOpenAlertBlockList(true);
        setProgress(false);
        hostname.value = "";
      } else if (postScanData && postScanData.scanreference) {
        setData(postScanData);
        storeLocal(
          postScanData.scanreference,
          postScanData.priorscanref,
          postScanData.priorscanrefdomains
        );
        setCharts(true);
        window.scrollTo({ top: 600, behavior: "smooth" });
        setProgress(false);
        console.log("direct hostname");
      }
    }
  }; //For Full Scan

  const getTokenDataFull = async () => {
    await setScoreGet(false);
    var email = "deepScan";
    const token = await getTokenSilently();
    console.log("THIS IS Returned Token FROM post" + JSON.stringify(token));
    // const UrlHostname = getHostName(hostname);
    // console.log(UrlHostname)
    const Url = getHostName(hostname);
    console.log(Url);
    if (Url) {
      if (user) {
        email = user.email;
      }
      const count = await getCountDB(email, token);

      const resTagInfo = await getTagInfo(user.email, token, Url);
      console.log(resTagInfo);
      if (resTagInfo && resTagInfo != 0 && resTagInfo.murl === Url) {
        setTagpop(true);
      } else {
        setTagpop(true);
      }

      if (typeof count == "undefined" || count.count) {
        if (typeof count != "undefined") {
          setScanCount(count.count - 1);
        }
        console.log("Count is ==>" + count);
        let postScanData = await postDataDBFull(email, Url, token);

        console.log(postScanData);
        if (!postScanData && !postScanData.scanreference) {
          console.log("Something went wrong");
          //Timeout trigger
          const interval = setInterval(async () => {
            //postScanData = true;
            console.log("This will run every 10 second!");
            postScanData = await getTimeoutData(
              token,
              localStorage.getItem("scanref")
            );
            console.log(postScanData);
            if (postScanData && postScanData.scanreference) {
              console.log("clear interval");
              clearInterval(interval);
              setData(postScanData);
              storeLocal(
                postScanData.scanreference,
                postScanData.priorscanref,
                postScanData.priorscanrefdomains
              );
              setCharts(true);
              window.scrollTo({ top: 600, behavior: "smooth" });
              setProgress(false);
              console.log("direct hostname");
            }
          }, 10000);

          //End Timeout trigger
        } else if (
          postScanData.HOSTEXISTS === "0" &&
          postScanData.BLOCKEDURL === "0"
        ) {
          console.log("Host Does Not Exist!");
          setOpenAlert(true);
          setProgress(false);
          hostname.value = "";
        } else if (
          postScanData.HOSTEXISTS === "0" &&
          postScanData.BLOCKEDURL === "1"
        ) {
          console.log("Host Is Blocked!");
          setOpenAlertBlockList(true);
          setProgress(false);
          hostname.value = "";
        } else {
          setData(postScanData);
          storeLocal(
            postScanData.scanreference,
            postScanData.priorscanref,
            postScanData.priorscanrefdomains
          );
          setCharts(true);
          setProgress(false);
          console.log("direct hostname");
        }
      } else {
        setCountAlert(true);
      }
    } else {
      if (user) {
        email = user.email;
      }
      const resTagInfo = await getTagInfo(user.email, token, hostname.value);
      console.log(resTagInfo);
      if (resTagInfo && resTagInfo != 0 && resTagInfo.murl === hostname.value) {
        setTagpop(false);
      } else {
        setTagpop(true);
      }

      const count = await getCountDB(email, token);
      console.log(count);
      if (typeof count == "undefined" || count.count) {
        if (typeof count != "undefined") {
          setScanCount(count.count - 1);
        }
        console.log("count is =>" + count);
        let postScanData = await postDataDBFull(email, hostname.value, token);

        console.log(postScanData);
        if (!postScanData && !postScanData.scanreference) {
          console.log("Something went wrong");

          //Timeout interval

          const interval = setInterval(async () => {
            //postScanData = true;
            console.log("This will run every 10 second!");
            postScanData = await getTimeoutData(
              token,
              localStorage.getItem("scanref")
            );
            console.log(postScanData);
            if (postScanData && postScanData.scanreference) {
              console.log("clear interval");
              clearInterval(interval);
              setData(postScanData);
              storeLocal(
                postScanData.scanreference,
                postScanData.priorscanref,
                postScanData.priorscanrefdomains
              );
              setCharts(true);
              window.scrollTo({ top: 600, behavior: "smooth" });
              setProgress(false);
              console.log("direct hostname");
            }
          }, 10000);

          //End Timout interval
        } else if (
          postScanData &&
          postScanData.HOSTEXISTS === "0" &&
          postScanData.BLOCKEDURL === "0"
        ) {
          console.log("Host Does Not Exist!");
          setOpenAlert(true);
          setProgress(false);
          hostname.value = "";
        } else if (
          postScanData.HOSTEXISTS === "0" &&
          postScanData.BLOCKEDURL === "1"
        ) {
          console.log("Host Is Blocked!");
          setOpenAlertBlockList(true);
          setProgress(false);
          hostname.value = "";
        } else {
          setData(postScanData);
          storeLocal(
            postScanData.scanreference,
            postScanData.priorscanref,
            postScanData.priorscanrefdomains
          );
          setCharts(true);
          window.scrollTo({ top: 600, behavior: "smooth" });
          setProgress(false);
          console.log("direct hostname");
        }
      } else {
        setCountAlert(true);
      }
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen1(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const callApi = () => {
    setProgress(true);
    setAPI(!api);
    handleClickOpen();
    localStorage.removeItem("priorscanref");
    localStorage.removeItem("priorscanrefdomains");
    getTokenData();
  };
  const callApiFull = () => {
    setProgress(true);
    setAPI(!api);
    handleClickOpen();
    localStorage.removeItem("priorscanref");
    localStorage.removeItem("priorscanrefdomains");
    getTokenDataFull();
    setOpen1(true);
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const handleOpenHowTo = () => {
    setOpenHow(true);
  };
  const [openHow, setOpenHow] = React.useState(false);
  const handleCloseHowTo = () => {
    setOpenHow(false);
  }; ///End Main

  const storeLocal = (scanref, priorscanref, priorscanrefdomains) => {
    console.log("Current Scanref is ==>" + scanref);
    console.log("PriorScanref is ==>" + priorscanref);
    console.log("priorscanrefdomains is ==>" + priorscanrefdomains);

    localStorage.setItem("scanref", scanref);
    localStorage.setItem("priorscanrefdomains", priorscanrefdomains);
    localStorage.setItem("priorscanref", priorscanref);
  };

  const defaultProps = {
    bgcolor: "background.paper",
    m: 1,
    border: 1,
  };
  const test = () => {
    setInterval(test, 10000);
    console.log("Testing");
  }; //End Login redirect  //Alert Message
  const [openAlert, setOpenAlert] = React.useState(false);
  const [openAlertBlockList, setOpenAlertBlockList] = React.useState(false);
  //End Alert

  useEffect(() => {
    if (props.match && props.match.params.paramHost) {
      hostname.value = `${props.match.params.paramHost}`;
      setProgress(true);
      handleClickOpen();
      getTokenData();
      props.match.params.paramHost = "";
    } else if (props.match && props.match.params.deepParamHost) {
      hostname.value = `${props.match.params.deepParamHost}`;

      // handleClickOpen();
      callApiFull();
      props.match.params.deepParamHost = "";
    }

    if (!topScore && !worstScore && isAuthenticated) {
      getScoreBoardData();
    }
  });
  const getpushdata = (tagInfo) => {
    setTagpop(false);
    console.log(tagInfo);
  };
  return (
    <React.Fragment>
      <Paper elevation={0}>
        <Container maxWidth="md">
          <Grid container>
            <Grid item xs>
              {countAlert && <PayDialog data={true} />}

              <Collapse in={openAlert}>
                <br />
                <br />
                {console.log(process.env.REACT_APP_API_BASE_URL)}

                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpenAlert(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  <Typography>
                    Sorry, {hostname.value} Does not Exist!!
                  </Typography>
                </Alert>
              </Collapse>
              <Collapse in={openAlertBlockList}>
                <br />
                <br />
                {console.log(process.env.REACT_APP_API_BASE_URL)}

                <Alert
                  severity="warning"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpenAlertBlockList(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  <Typography>
                    Sorry, but the ability to scan {hostname.value} is available for
                    paid subscriptions only.
                  </Typography>
                  <Typography>
                    Email us at support@securestack.com for more details.
                  </Typography>
                </Alert>
              </Collapse>
            </Grid>
          </Grid>
          {/* {isAuthenticated &&
      user["http://localhost:3000/is_new"].toString() === "true" &&
      localStorage.getItem("scanref") ? (
        <div>
          <PostFreeUser pushData={getTokenDataNewUser} />
        </div>
      ) : (
        <></>
      )} */}

          <ResponsiveImg />

          <Grid container>
            <Grid item xs={2}>
              <Paper elevation={0}></Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper component="form" className={classes.root}>
                <Zoom
                  direction="left"
                  in={true}
                  style={{ transitionDelay: "500ms" }}
                >
                  <InputBase
                    className={classes.input}
                    autoFocus
                    value={hostname.value}
                    onKeyPress={keyPressed}
                    onChange={handleUrl}
                    id="standard-error"
                    label="Error"
                    type="text"
                    placeholder="Scan URL (eg:website.com)"
                  />
                </Zoom>

                <Tooltip title="Quickscan">
                  <span>
                    <Button
                      onClick={callApi}
                      // className={classes.iconButton}
                      // aria-label="search"
                      variant="contained"
                      className={classes.button}
                      disabled={hostname.error}
                    >
                      Quickscan
                      {/* <AiOutlineSecurityScan style={{ fontSize: 40 }} /> */}
                    </Button>
                  </span>
                </Tooltip>
                <Divider className={classes.divider} orientation="vertical" />
                {isAuthenticated && (
                  <React.Fragment>
                    <Tooltip title="Deepscan">
                      <span>
                        <IconButton
                          onClick={callApiFull}
                          className={classes.iconButton}
                          aria-label="search"
                          disabled={hostname.error}
                        >
                          <StyledBadge
                            badgeContent={scanCount}
                            color="secondary"
                          >
                            <BsFillCollectionFill style={{ fontSize: 40 }} />
                          </StyledBadge>
                        </IconButton>
                      </span>
                    </Tooltip>
                    {scanCount && (
                      <Snackbar
                        open={open1}
                        autoHideDuration={6000}
                        onClose={handleClose}
                      >
                        <Alert onClose={handleClose} severity="success">
                          <Typography>
                            you have {scanCount} deep scans remaining
                          </Typography>
                        </Alert>
                      </Snackbar>
                    )}

                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                    />
                  </React.Fragment>
                )}
                {/* <IconButton
                  onClick={handleOpenHowTo}
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <Tooltip title="Our How-To video">
                    <SlideshowIcon style={{ fontSize: 40 }} />
                  </Tooltip>
                </IconButton> */}

                <Dialog
                  maxWidth="lg"
                  fullScreen={fullScreen}
                  allowfullscreen
                  open={openHow}
                  onClose={handleCloseHowTo}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Bloodhound HOW-TO "}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/n8f7Vy53QXs"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                        webkitallowfullscreen
                        mozallowfullscreen
                        oallowfullscreen
                        msallowfullscreen
                      ></iframe>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      autoFocus
                      onClick={handleCloseHowTo}
                      color="primary"
                    >
                      <CloseIcon style={{ color: "#ad2007" }} />
                    </Button>
                  </DialogActions>
                </Dialog>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper elevation={0}></Paper>
            </Grid>
          </Grid>
          <br />
          <br />
          {tagpop && <Tagging murl={hostname.value} pushData={getpushdata} />}
          {progress && (
            <>
              <ProgressBarc
                style={{
                  height: 10,
                  marginLeft: "25%",
                  marginRight: "25%",
                  position: "relative",
                  width: "50%",
                  backgroundColor: "#ad2007",
                }}
                color="#61ed68"
              />
            </>
          )}

          {/* {!isAuthenticated && <Timeline />} */}
        </Container>
        {/* {api && <PostFreeUser pushData={getTokenData} />} */}
        {charts === true && data ? (
          <Paper elevation={15}>
            <div>
              <br />
              <br />
              <br /> <Score securityscore={data.securityscore} />
              <br />
              <Grid container spacing={3}>
                <Grid style={{ textAlign: "center" }} item xs={12}>
                  <Typography
                    className={classes.typography}
                    variant="h4"
                    component="h4"
                  >
                    <strong>Elapsed Time:</strong> <br />
                    <TimerIcon style={{ fontSize: 40 }} />
                    {data.elapsedtime}
                  </Typography>
                </Grid>
              </Grid>
              <br />
              <br />
              {/* <Map data={data} /> */}
              <br />
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={3}
              >
                {Object.keys(data)
                  .filter(
                    (key) =>
                      scanData.filter((i) => i.name === key).length > 0 &&
                      data[key] !== null &&
                      data[key] !== ""
                  )
                  .map((key) => {
                    return (
                      <ServicesComp
                        scanData={data[key]}
                        title={
                          scanData.filter((obj) => obj.name === key)[0].title
                        }
                      />
                    );
                  })}
              </Grid>
              <br />
              <br />
              <br />
              <br />
              <div className={classes.root1}>
                <Grid container spacing={3}>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={8}>
                    <Paper elevation={0} className={classes.paper}>
                      <Typography
                        className={classes.typography}
                        variant="h4"
                        component="h4"
                      >
                        <strong>
                          <Box
                            boxShadow={7}
                            borderColor="secondary.main"
                            {...defaultProps}
                          >
                            PROBLEMS FOUND:
                          </Box>
                        </strong>
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
                <ProblemFound data={data} />
              </div>
              <br />
              {ScoreGet ? (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Paper elevation={0} className={classes.paper}>
                        <Typography
                          className={classes.typography}
                          style={{ color: "#2D383A", textAlign: "center" }}
                          variant="h4"
                          component="h4"
                        >
                          <strong>
                            <Box
                              boxShadow={7}
                              borderColor="secondary.main"
                              {...defaultProps}
                            >
                              YOUR SECURITY SCORECARD
                            </Box>
                          </strong>
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                </>
              ) : (
                <Container>
                  <ScoreCardMobile data={data} />
                  <ScoreCardFile data={data} />
                </Container>
              )}
              <br />
              <br />
              {isAuthenticated === true ? (
                <div className={classes.root1}>
                  {/* <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Paper elevation={0} className={classes.paper}>
                        <Typography
                          className={classes.typography}
                          variant="h4"
                          component="h4"
                        >
                          <strong>
                            <Box
                              boxShadow={7}
                              borderColor="secondary.main"
                              {...defaultProps}
                            >
                              PROBLEMS FOUND:
                            </Box>
                          </strong>
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                  <ProblemFound data={data} /> */}
                  {images.map((image) => (
                    <ButtonBase
                      href="/bloodhound"
                      onClick={() => {
                        mixpanel.track("Free User Clicked on Get Full Report");
                        ReactGA.event({
                          category: "FreeUser",
                          // action: 'Opened a Full report'
                          action: `Free User Clicked on Get Full Report ${props.description}`,
                        });
                      }}
                      focusRipple
                      key={image.titlelogin}
                      className={classes.image}
                      focusVisibleClassName={classes.focusVisible}
                      style={{
                        width: image.width,
                      }}
                    >
                      <span
                        className={classes.imageSrc}
                        style={{
                          // backgroundImage: `url(${image.url})`,
                          backgroundColor: "#008000",
                        }}
                      />
                      <span className={classes.imageBackdrop} />
                      <span className={classes.imageButton}>
                        <Typography
                          className={classes.typography}
                          component="span"
                          variant="subtitle1"
                          color="inherit"
                          className={classes.imageTitle}
                        >
                          {image.title}
                          <span className={classes.imageMarked} />
                        </Typography>
                      </span>
                    </ButtonBase>
                  ))}
                </div>
              ) : (
                <div className={classes.root1}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Paper elevation={0} className={classes.paper}>
                        <Typography
                          className={classes.typography}
                          style={{ textAlign: "center" }}
                          variant="h4"
                          component="h4"
                        >
                          <strong>
                            <Box
                              boxShadow={7}
                              borderColor="secondary.main"
                              {...defaultProps}
                            >
                              PROBLEMS FOUND:
                            </Box>
                          </strong>
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                  <ProblemFound data={data} />{" "}
                  {images.map((image) => (
                    <ButtonBase
                      href="/bloodhound"
                      onClick={() => {
                        mixpanel.track("Free User Clicked on Get Full Report");
                        ReactGA.event({
                          category: "FreeUser",
                          // action: 'Opened a Full report'
                          action: `Free User Clicked on Get Full Report${props.description}`,
                        });
                      }}
                      focusRipple
                      key={image.title}
                      className={classes.image}
                      focusVisibleClassName={classes.focusVisible}
                      style={{
                        width: image.width,
                      }}
                    >
                      <span
                        className={classes.imageSrc}
                        style={{
                          // backgroundImage: `url(${image.url})`,
                          backgroundColor: "#008000",
                        }}
                      />
                      <span className={classes.imageBackdrop} />
                      <span className={classes.imageButton}>
                        <Typography
                          component="span"
                          variant="subtitle1"
                          color="inherit"
                          className={classes.imageTitle}
                        >
                          {image.title}
                          <span className={classes.imageMarked} />
                        </Typography>
                      </span>
                    </ButtonBase>
                  ))}
                </div>
              )}
            </div>
            <br />
          </Paper>
        ) : (
          <></>
        )}
      </Paper>
      <br />
      <br />

      {isAuthenticated && (
        <LeaderBoard data={topScore} worstdata={worstScore} />
      )}
    </React.Fragment>
  );
};
export default BloodHound;
