import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import ArrowRightOutlinedIcon from "@material-ui/icons/ArrowRightOutlined";

import {
  Typography,
  TextField,
  IconButton,
  Grid,
  Paper,
  Tooltip,
  Container,
} from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SelectStackPopUp(props) {
  console.log(props);
  const data = props.data;
  const [open, setOpen] = React.useState(true);
  const [murl, setMurl] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const selectStack = (stack) => {
    props.pushData(stack);
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        maxWidth="lg"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Select a the Stack to Compare"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {Object.values(data).map((item) => (
              <Grid container>
                <Grid item xs={2}>
                  <Typography
                    style={{ fontFamily: "Orbitron" }}
                    variant="p"
                    component="p"
                  >
                    {item.stackName}
                  </Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={6}>
                  <Typography>
                    {item.stack.split(",").map((list) => (
                      <Typography style={{ display: "inline-block" }}>
                        {list.trim()}
                      </Typography>
                    ))}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    onClick={() => {
                      selectStack(item.stack);
                    }}
                  >
                    <ArrowRightOutlinedIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}
