const updateDataDB = async (email, scanRef, token) => {
  console.log("THIS IS EMAIL: " + email);
  console.log("THIS IS scanRef: " + scanRef);
  console.log("THIS IS token: " + token);

  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/updatebyscanref`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        email: `${email}`,
        scanref: `${scanRef}`,
      }),
    }
  );

  const responseData = await response.json();
  return responseData;
};
export default updateDataDB;
