import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { AiOutlineSecurityScan } from "react-icons/ai";
import { MdReportProblem } from "react-icons/md";
import {
  Grid,
  Tooltip,
  Paper,
  Typography,
  Link,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Box,
  IconButton,
  ButtonBase,
  Container,
  Collapse,
  Divider,
  InputBase,
} from "@material-ui/core";
import { useAuth0 } from "../react-auth0-spa";
import ServicesComp from "../components/ServicesComp";
import Score from "../components/Score";
import NEWSCORE from "../components/newScore";
import ProblemFound from "../components/ProblemFound";
import Title from "../components/Title";
import ProgressBarc from "../components/ProgressBarc";
import ResponsiveImg from "../components/ResponsiveImg";
import ScoreCardFile from "../components/ScoreCardFile";
import postDataDB from "../functions/postDataDB";
import getFreeToken from "../functions/getFreeToken";
import mixpanel from "mixpanel-browser";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import ReactGA from "react-ga";
import TimerIcon from "@material-ui/icons/Timer";
import SearchIcon from "@material-ui/icons/Search";

// let theme = createMuiTheme();
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import SlideshowIcon from "@material-ui/icons/Slideshow";
import dog from ".././images/dog.png";
import ResponsiveDog from "../components/ResponsiveDog";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    display: "flex-centered",
    textAlign: "center",
    height: "100vh",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "transparent",
  },
  image: {
    position: "relative",
    height: 100,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,

    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    position: "absolute",
    color: "#ffffff",

    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${
      theme.spacing(1) + 6
    }px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "flex",

    color: "#ad2007",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,

    color: "red",
    fontSize: 25,
  },
  iconButton: {
    padding: 10,
    color: "red",
  },
  divider: {
    height: 28,
    margin: 4,
    backgroundColor: "#FFFFFF",
  },
}));

const BloodHoundDark = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [data, setData] = useState(false);
  const [api, setAPI] = useState(false);
  const [progress, setProgress] = useState(false);
  const [hostname, setHostname] = useState({
    value: "",
    error: true,
    errorMsg: "Invalid Name",
  });
  const [charts, setCharts] = useState(false);
  const [ScoreGet, setScoreGet] = useState(false);
  const validator = {
    url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
    //url: /^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
  };
  const [openReportPopUp, setopenReportPopUp] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleUrl = (e) => {
    if (!e.target.value.match(validator.url)) {
      setHostname({
        value: e.target.value,
        error: true,
        errorMsg: "Invalid Url",
      });
    } else
      setHostname({
        value: e.target.value,
        error: false,
        errorMsg: "",
      });
  };
  const keyPressed = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      callApi();
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const images = [
    {
      //url:`${dog}`,
      title: "FREE FULL REPORT",
      width: "30%",
      titlelogin: "FREE FULL REPORT",
      fontFamily: "Orbitron",
    },
  ];

  ///Url Check
  function getHostName(hostname) {
    var match = hostname.value.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
    if (
      match != null &&
      match.length > 2 &&
      typeof match[2] === "string" &&
      match[2].length > 0
    ) {
      return match[2];
    } else {
      return null;
    }
  }

  //End UrlCheck
  const getTokenData = async () => {
    await setScoreGet(false);
    var email = "email";
    const token = await getFreeToken();
    console.log(
      "THIS IS Returned Token FROM post" + JSON.stringify(token.accessToken)
    );
    // const UrlHostname = getHostName(hostname);
    // console.log(UrlHostname)
    const Url = getHostName(hostname);
    console.log(Url);
    if (Url) {
      if (user) {
        email = user.email;
      }
      const postScanData = await postDataDB(email, Url, token);

      if (postScanData.HOSTEXISTS === "0") {
        console.log("Host Does Not Exist!");
        setOpenAlert(true);
        setProgress(false);
        hostname.value = "";
      } else {
        setData(postScanData);
        storeLocal(postScanData.scanreference);
        setCharts(true);

        setProgress(false);
        console.log("direct hostname");
      }
    } else {
      if (user) {
        email = user.email;
      }
      const postScanData = await postDataDB(email, hostname.value, token);

      if (postScanData.HOSTEXISTS === "0") {
        console.log("Host Does Not Exist!");
        setOpenAlert(true);
        setProgress(false);
        hostname.value = "";
      } else {
        setData(postScanData);
        storeLocal(postScanData.scanreference);
        setCharts(true);
        window.scrollTo({ top: 600, behavior: "smooth" });
        setProgress(false);
        console.log("direct hostname");
      }
    }
  };
  const [openHow, setOpenHow] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleOpenHowTo = () => {
    setOpenHow(true);
  };

  const handleCloseHowTo = () => {
    setOpenHow(false);
  };

  const callApi = () => {
    setProgress(true);
    setAPI(!api);
    handleClickOpen();
    getTokenData();
  };

  ///End Main
  //open TagUrl Dialogue Box and send report popup box

  const getOpen = (open) => {
    setOpen(open);
  };
  const handleClicksendReportPopUP = () => {
    setopenReportPopUp(true);
  };
  const getOpenReport = () => {
    setopenReportPopUp(false);
  };
  //End TagUrl Dialogue Box and report popup Box
  const storeLocal = (scanref) => {
    localStorage.setItem("scanref", scanref);
  };
  const readValue = () => {
    const item = localStorage.getItem("scanref");
    console.log(localStorage.getItem("scanref"));
  };
  //Login Auth0 Redirect
  const defaultProps = {
    bgcolor: "background.paper",
    m: 1,
    border: 1,
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  //End Login redirect
  const { isAuthenticated, loginWithRedirect, user } = useAuth0();
  //Alert Message
  const [openAlert, setOpenAlert] = React.useState(false);

  //End Alert
  const { paramHost } = props.match.params;

  useEffect(() => {
    if (props.match.params.paramHost) {
      hostname.value = `${props.match.params.paramHost}`;
      setProgress(true);
      handleClickOpen();
      getTokenData();
      props.match.params.paramHost = "";
    }
  });

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12}>
            <Collapse in={openAlert}>
              <br />
              <br />
              {console.log(process.env.REACT_APP_API_BASE_URL)}
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Sorry, {hostname.value} Does not Exist!!
              </Alert>
            </Collapse>
          </Grid>
        </Grid>
        {/* {isAuthenticated &&
      user["http://localhost:3000/is_new"].toString() === "true" &&
      localStorage.getItem("scanref") ? (
        <div>
          <PostFreeUser pushData={getTokenDataNewUser} />
        </div>
      ) : (
        <></>
      )} */}
        <Grid container>
          <Grid item xs={12}>
            <Paper elevation={0} className={classes.paper}>
              <Title />
            </Paper>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={2}>
            <Paper elevation={0}></Paper>
          </Grid>

          <Grid item xs={8}>
            <Paper component="form" className={classes.root}>
              <InputBase
                className={classes.input}
                autoFocus
                value={hostname.value}
                onKeyPress={keyPressed}
                onChange={handleUrl}
                id="standard-error"
                label="Error"
                type="text"
                placeholder="Scan URL (eg:website.com)"
              />
              <IconButton
                onClick={callApi}
                className={classes.iconButton}
                aria-label="search"
                disabled={hostname.error}
              >
                <AiOutlineSecurityScan style={{ fontSize: 40 }} />
              </IconButton>
              <Divider className={classes.divider} orientation="vertical" />
              <IconButton
                onClick={handleOpenHowTo}
                className={classes.iconButton}
                aria-label="search"
              >
                <Tooltip title="Our How-To video">
                  <SlideshowIcon style={{ fontSize: 40 }} />
                </Tooltip>
              </IconButton>

              <Dialog
                maxWidth="lg"
                fullScreen={fullScreen}
                open={openHow}
                onClose={handleCloseHowTo}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title">
                  {"Bloodhound HOW-TO "}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    <Grid container>
                      <Grid item xs={12}>
                        <CardMedia
                          style={{ width: 350 }}
                          component="iframe"
                          alt=""
                          height="240"
                          src="https://www.youtube.com/embed/n8f7Vy53QXs"
                          title="Bloodhound How to"
                        />
                      </Grid>
                    </Grid>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={handleCloseHowTo} color="primary">
                    <CloseIcon style={{ color: "#ad2007" }} />
                  </Button>
                </DialogActions>
              </Dialog>
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper elevation={0}></Paper>
          </Grid>
        </Grid>

        {progress && (
          <div>
            <Grid container>
              <Grid item xs={12}>
                <Paper elevation={0} style={{ backgroundColor: "transparent" }}>
                  <ResponsiveDog />
                  <Typography
                    style={{
                      marginLeft: "25%",
                      fontFamily: "Courier New",
                      color: "#FFFFFF",
                    }}
                  >
                    Sniffing out security and scalability issues in your web
                    application
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6}></Grid>
            </Grid>
            <ProgressBarc />
          </div>
        )}
        {charts === true && data ? (
          <Paper className={classes.paper} elevation={15}>
            <div>
              <br />
              <br />
              <br />

              <NEWSCORE securityscore={data.securityscore} />
              <br />
              <Grid container spacing={3}>
                <Grid style={{ textAlign: "center" }} item xs={12}>
                  <Typography
                    style={{ color: "#FFFFFF" }}
                    variant="h4"
                    component="h4"
                  >
                    <strong>Elapsed Time:</strong> <br />
                  </Typography>
                  <Typography
                    variant="h4"
                    component="h4"
                    style={{ fontFamily: "Orbitron", color: "#FFFFFF" }}
                  >
                    <TimerIcon style={{ fontSize: 40 }} />
                    {data.elapsedtime}
                  </Typography>
                </Grid>
              </Grid>
              <br />
              <br />
              <br />
              <ServicesComp data={data} />
              <br />
              <br />
              <br />
              <br />
              {ScoreGet ? (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Paper elevation={0} className={classes.paper}>
                        <Typography
                          style={{
                            color: "#2D383A",
                            textAlign: "center",
                          }}
                          variant="h4"
                          component="h4"
                        >
                          <strong>
                            <Box
                              boxShadow={7}
                              borderColor="secondary.main"
                              {...defaultProps}
                            >
                              YOUR SECURITY SCORECARD
                            </Box>
                          </strong>
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                </>
              ) : (
                <ScoreCardFile data={data} />
              )}
              <br />
              <br />
              {isAuthenticated === true ? (
                <div className={classes.root1}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Paper elevation={0} className={classes.paper}>
                        <Typography
                          style={{ fontFamily: "Orbitron" }}
                          variant="h4"
                          component="h4"
                        >
                          <strong>
                            <Box
                              boxShadow={7}
                              borderColor="secondary.main"
                              {...defaultProps}
                            >
                              PROBLEMS FOUND:
                            </Box>
                          </strong>
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                  <ProblemFound data={data} />
                  {images.map((image) => (
                    <ButtonBase
                      href="/bloodhound"
                      onClick={() => {
                        mixpanel.track("Free User Clicked on Get Full Report");
                        ReactGA.event({
                          category: "FreeUser",
                          // action: 'Opened a Full report'
                          action: `Free User Clicked on Get Full Report ${props.description}`,
                        });
                      }}
                      focusRipple
                      key={image.titlelogin}
                      className={classes.image}
                      focusVisibleClassName={classes.focusVisible}
                      style={{
                        width: image.width,
                      }}
                    >
                      <span
                        className={classes.imageSrc}
                        style={{
                          // backgroundImage: `url(${image.url})`,
                          backgroundColor: "#008000",
                        }}
                      />
                      <span className={classes.imageBackdrop} />
                      <span className={classes.imageButton}>
                        <Typography
                          component="span"
                          variant="subtitle1"
                          color="inherit"
                          className={classes.imageTitle}
                        >
                          {image.title}
                          <span className={classes.imageMarked} />
                        </Typography>
                      </span>
                    </ButtonBase>
                  ))}
                </div>
              ) : (
                <div className={classes.root1}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Paper elevation={0} className={classes.paper}>
                        <Typography
                          style={{ textAlign: "center" }}
                          variant="h4"
                          component="h4"
                        >
                          <strong>
                            <Box
                              boxShadow={7}
                              borderColor="secondary.main"
                              {...defaultProps}
                            >
                              PROBLEMS FOUND: <MdReportProblem />
                            </Box>
                          </strong>
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                  <ProblemFound data={data} />

                  {images.map((image) => (
                    <ButtonBase
                      href="/bloodhound"
                      onClick={() => {
                        mixpanel.track("Free User Clicked on Get Full Report");
                        ReactGA.event({
                          category: "FreeUser",
                          // action: 'Opened a Full report'
                          action: `Free User Clicked on Get Full Report${props.description}`,
                        });
                      }}
                      focusRipple
                      key={image.title}
                      className={classes.image}
                      focusVisibleClassName={classes.focusVisible}
                      style={{
                        width: image.width,
                      }}
                    >
                      <span
                        className={classes.imageSrc}
                        style={{
                          // backgroundImage: `url(${image.url})`,
                          backgroundColor: "#008000",
                        }}
                      />
                      <span className={classes.imageBackdrop} />
                      <span className={classes.imageButton}>
                        <Typography
                          component="span"
                          variant="subtitle1"
                          color="inherit"
                          className={classes.imageTitle}
                        >
                          {image.title}
                          <span className={classes.imageMarked} />
                        </Typography>
                      </span>
                    </ButtonBase>
                  ))}
                </div>
              )}
            </div>
            <br />
          </Paper>
        ) : (
          <></>
        )}
        <br />
        <br />
      </Container>
    </div>
  );
};
export default BloodHoundDark;
