const updateCountDB = async (email, subscription, token) => {
  console.log("THIS IS EMAIL: " + email);
  console.log("THIS IS subscription: " + subscription);
  console.log("THIS IS token: " + token);

  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/updatebyemail`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        email: `${email}`,
        subscription: `${subscription}`,
      }),
    }
  );

  const responseData = await response.json();
  return responseData;
};
export default updateCountDB;
