import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Alert from "@material-ui/lab/Alert";
import { Paper, Typography, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

// function createData(port, state, service, description) {
//   return { port, state, service, description };
// }

// const rows = [

//   createData('21/TCP', "Open", "ftp", "Pure-FTPd"),
//   createData('21/TCP', "Open", "ftp", "Pure-FTPd"),
//   createData('21/TCP', "Open", "ftp", "Pure-FTPd"),
//   createData('21/TCP', "Open", "ftp", "Pure-FTPd"),
//   createData('21/TCP', "Open", "ftp", "Pure-FTPd"),

// ];

export default function FirewallReport(props) {
  const classes = useStyles();
  {
    console.log(props.data);
  }
  {
    console.log(props.dnsrec);
  }
  return (
    <Paper evelation={0}>
      {props.data === false ? (
        <Paper className={classes.paper}>
          <Typography
            style={{
              height: 300,
              textAlign: "center",
              fontSize: "20px",
              fontFamily: "courier",
            }}
          >
            <CircularProgress style={{ color: "#ad2007" }} />
            <Alert severity="error">
              Please allow up to 15 minutes for this scan to take effect
            </Alert>
          </Typography>
        </Paper>
      ) : (
        <div>
          {
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h4" component="h4">
                        <strong> Port</strong>
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="h4" component="h4">
                        <strong> State</strong>
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="h4" component="h4">
                        <strong> Target</strong>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        <Typography
                          style={{ fontFamily: "courier" }}
                          variant="h5"
                          component="h5"
                        >
                          {row.firewallrule}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          style={{ color: "red", fontFamily: "courier" }}
                          variant="h5"
                          component="h5"
                        >
                          {row.firewallrule != null ? (
                            <Typography
                              style={{ color: "red", fontFamily: "courier" }}
                              variant="h5"
                              component="h5"
                            >
                              Open
                            </Typography>
                          ) : (
                            <Typography
                              style={{ color: "red", fontFamily: "courier" }}
                              variant="h5"
                              component="h5"
                            >
                              Closed
                            </Typography>
                          )}
                        </Typography>
                      </TableCell>

                      <TableCell align="right">
                        <Typography
                          style={{ fontFamily: "courier" }}
                          variant="h5"
                          component="h5"
                        >
                          {row.murl}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </div>
      )}

      {props.dnsrec === false ? (
        <div></div>
      ) : (
        <div>
          {
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h4" component="h4">
                        <strong> DNS Record </strong>
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="h4" component="h4">
                        <strong> Type </strong>
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="h4" component="h4">
                        <strong> Target </strong>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.dnsrec.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        <Typography
                          style={{ fontFamily: "courier" }}
                          variant="h5"
                          component="h5"
                        >
                          {row.record}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          style={{ fontFamily: "courier" }}
                          variant="h5"
                          component="h5"
                        >
                          {row.type}
                        </Typography>
                      </TableCell>

                      <TableCell align="right">
                        <Typography
                          style={{ fontFamily: "courier" }}
                          variant="h5"
                          component="h5"
                        >
                          {row.rooturl}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </div>
      )}
    </Paper>
  );
}
