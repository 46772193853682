import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Paper,
  Button,
  List,
  ListItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import getChangesHistory from "../functions/getChangesHistory";
import { useAuth0 } from "../react-auth0-spa";
import getChangesHistoryTargets from "../functions/getChangesHistoryTarget";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import oldChanges from "../functions/getDataDB";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import ReactGA from "react-ga";
import mixpanel from "mixpanel-browser";
import getPriorscan from "../functions/getPriorscan";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(25),
    flexBasis: "33.33%",
    flexShrink: 0,
    fontFamily: "Orbitron",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  childheading: {
    color: "#ad2007",
    fontFamily: "Orbitron",
  },
}));
function ChangesHistory(props) {
  const classes = useStyles();
  const [data, setData] = useState(false);
  const [change, setChange] = useState(false);
  const { isAuthenticated, user, getTokenSilently } = useAuth0();
  const [expanded, setExpanded] = React.useState(false);
  const [expandedChild, setExpandedChild] = React.useState(false);
  const [newChange, setNewChange] = useState(false);
  const [oldChange, setOldChange] = useState(false);

  useEffect(() => {
    getTargetsAll();
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      getChanges(panel);
    }
    console.log(panel);
  };
  const handleChangeChild = (panel) => (event, isExpanded) => {
    setExpandedChild(isExpanded ? panel : false);
    if (isExpanded) {
      newChangesFunc(panel);
    }
    console.log(panel);
  };
  const getDomainsRef = async (item) => {
    localStorage.removeItem("priorscanref");
    localStorage.removeItem("priorscanrefdomains");
    console.log("scanref from function ==>"+item)
    localStorage.setItem("scanref", item);
    const token = await getTokenSilently();
    const priorscanrefdomains = await getPriorscan(item, token);
    if(priorscanrefdomains && priorscanrefdomains.priorscanref){
    console.log(priorscanrefdomains.priorscanref);

    localStorage.setItem(
      "priorscanrefdomains",
      priorscanrefdomains.priorscanref
    );
    mixpanel.track("ChangesScan history report selected");
    ReactGA.event({
      category: "History",
      action: "Opened a changes history report",
    });
  }
  };

  function newChangesFunc(scanref) {
    Object.values(change).map((row) => {
      if (row.scanreference === scanref) {
        console.log("Its a Match");
        console.log(JSON.parse(row.NewChange));
        setNewChange(JSON.parse(row.NewChange));
        if (row.priorscanref && row.priorscanref != null) {
          getPrioChanges(row.priorscanref);
        } else {
          setOldChange(false);
        }
      }
    });
  }
  async function getPrioChanges(priorscanref) {
    const token = await getTokenSilently();
    const oldscan = await oldChanges(user.email, priorscanref, token);
    setOldChange(oldscan);
  }

  async function getChanges(murl) {
    const token = await getTokenSilently();
    const resChanges = await getChangesHistory(user.email, token, murl);
    console.log(resChanges);
    setChange(resChanges);
    // if (resChanges) {
    //    setNewChange(JSON.parse(resChanges[0].NewChange));
    // }
  }
  const getTargetsAll = async () => {
    const token = await getTokenSilently();
    const getTarget = await getChangesHistoryTargets(user.email, token);
    setData(getTarget);
  };
  return (
    <div className={classes.root}>
      {Object.values(data).map((row) => (
        <Accordion
          expanded={expanded === `${row.murl}`}
          onChange={handleChange(`${row.murl}`)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>{row.murl}</Typography>
            <Typography className={classes.secondaryHeading}>
              RECENT CHANGES
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              {Object.values(change).map((row) => (
                <React.Fragment>
                  <br />
                  <Grid xs={2}></Grid>
                  <Grid xs={8}>
                    <Accordion
                      expandedChild={expandedChild === `${row.scanreference}`}
                      onChange={handleChangeChild(`${row.scanreference}`)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Button></Button>
                        <Typography
                          className={classes.childheading}
                          style={{ paddingRight: "15px" }}
                        >
                          Date :
                          {row.scannedtime.substring(
                            0,
                            row.scannedtime.length - 14
                          )}
                        </Typography>

                        <Typography className={classes.childheading}>
                          Time: {row.scannedtime.substring(16, 11)}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {newChange ? (
                          <Grid container>
                            {Object.keys(newChange).map((key) =>
                              key === "email" ||
                              key === "scanreference" ||
                              key === "elapsedtime" ? (
                                <React.Fragment></React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <Grid item xs={1}></Grid>
                                  <Grid item xs={2}>
                                    <Typography
                                      style={{
                                        fontFamily: "Orbitron",
                                      }}
                                    >
                                      {key}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={4}>
                                    {oldChange[key]}
                                  </Grid>
                                  <Grid item xs={1}>
                                    <ArrowRightAltIcon
                                      style={{ fontSize: 40, color: "green" }}
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    {newChange[key]}
                                  </Grid>
                                </React.Fragment>
                              )
                            )}
                            <Grid container>
                              {/* 
                              <Grid item xs={4}>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  style={{ background: "#ad2007" }}
                                  size="small"
                                  href="/bloodhound"
                                  onClick={() => {
                                    localStorage.setItem(
                                      "scanref",
                                      oldChange["scanreference"]
                                    );
                                    // mixpanel.track(
                                    //   "Scan history report selected"
                                    // );
                                    // ReactGA.event({
                                    //   category: "History",
                                    //   action: "Opened a history report",
                                    // });
                                  }}
                                >
                                  Get Report
                                </Button>
                              </Grid> */}
                              <Grid item xs={6}></Grid>

                              <Grid item xs={3}>
                                <Button
                                  fullwidth
                                  variant="contained"
                                  color="secondary"
                                  style={{
                                    background: "#ad2007",
                                    fontFamily: "Orbitron",
                                  }}
                                  size="small"
                                  href="/bloodhound"
                                  onClick={() => {
                                    
                                    getDomainsRef(newChange["scanreference"])
                                    // localStorage.setItem(
                                    //   "scanref",
                                    //   newChange["scanreference"]
                                    // );
                                    // mixpanel.track(
                                    //   "Scan history report selected"
                                    // );
                                    // ReactGA.event({
                                    //   category: "History",
                                    //   action: "Opened a history report",
                                    // });
                                  }}

                                >
                                  Get Report
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : (
                          <Typography style={{ fontSize: 20 }}>
                            No Recent Changes...
                          </Typography>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid xs={2}></Grid>
                </React.Fragment>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
export default ChangesHistory;
