import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Grid, Typography } from "@material-ui/core";
import securetack from "../images/securestack_word_bloodred.png";
import HubspotForm from "../HubSpotMsg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,

    position: "fixed",
    left: 0,
    bottom: 0,
  },
  img: {
    left: 0,
    display: "block",
    maxWidth: "25%",
    maxHeight: "25%",
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography style={{ color: "#2D383A" }} variant="h6" component="h6">
        <img className={classes.img} src={securetack} />
        <HubspotForm />
      </Typography>
    </div>
  );
}
