const getPriorscan = async (scanRef, token) => {
  console.log("THIS IS scanRef: " + scanRef);
  console.log("THIS IS token: " + token);

  //Prod IP : 54.66.181.5
  //Prod Domain for node : https://bloodhoundapi.securestack.com/
  //Dev IP : 3.104.105.30
  //Local env : localhost:3001
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/getpriorscan/${scanRef}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    }
  );
  const responseData = await response.json();
  return responseData[0];
};

export default getPriorscan;
