import React, { useEffect, useState } from "react";
import { useAuth0 } from "../react-auth0-spa";
import getTargets from "../functions/getTargets";

import {
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  Divider,
  Tooltip,
  IconButton,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import { makeStyles } from "@material-ui/core/styles";
import ResponsiveImg from "../components/ResponsiveImg";
import SlideshowIcon from "@material-ui/icons/Slideshow";
import { AiOutlineSecurityScan, AiFillWindows } from "react-icons/ai";
import { FcOvertime } from "react-icons/fc";
import securetack from "../images/securestack_word_bloodred.png";
import secure from "../images/newsecurestack.png";
import SchedulePop from "../components/Target/SchedulePop";
import Fab from "@material-ui/core/Fab";
import { BsFillCollectionFill } from "react-icons/bs";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "flex",
    color: "#ad2007",
  },

  iconButton: {
    padding: 10,
    color: "#ad2007",
  },
  scheduleButton: {
    padding: 10,
    color: "blue",
  },

  divider: {
    height: 28,
    margin: 4,
    backgroundColor: "#FFFFFF",
  },
  img: {
    left: 0,
    display: "block",
    maxWidth: "50%",
    maxHeight: "50%",
  },
  button: {
    color: "#ad2007",
  },
});

export default function Targets(props) {
  const [data, setData] = useState(false);
  const [schePop, setSchePop] = useState(false);
  const { isAuthenticated, getTokenSilently, user } = useAuth0();
  const [schInfo, setSchInfo] = useState(false);

  const classes = useStyles();

  const getT = async () => {
    localStorage.removeItem("priorscanref");
    const token = await getTokenSilently();
    const getTarget = await getTargets(user.email, token);
    setData(getTarget);
  };
  useEffect(() => {
    if (!data) {
      getT();
    }
  });
  const getpushdata = (schInfo) => {
    setSchePop(false);
    console.log(schInfo);
    window.location.reload(true);
  };
  return (
    <Paper elevation={15}>
      <TableContainer style={{ marginTop: "1%" }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h5" component="h5">
                  <strong> Targets</strong>
                </Typography>
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell align="center">
                <Typography variant="h5" component="h5">
                  <strong> Scan</strong>
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  variant="h5"
                  component="h5"
                  style={{ textAlign: "center" }}
                >
                  <strong> Schedule</strong>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(data).map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  <Typography
                    style={{ fontFamily: "courier" }}
                    variant="h5"
                    component="h5"
                  >
                    {row.target}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography
                    style={{ fontFamily: "courier" }}
                    variant="h5"
                    component="h5"
                  ></Typography>
                </TableCell>

                <TableCell></TableCell>
                <TableCell align="center">
                  <Tooltip title={`Scan ${row.target}`}>
                    <IconButton
                      href={`/bloodhoundscan/${row.target}`}
                      className={classes.iconButton}
                    >
                      <AiOutlineSecurityScan style={{ fontSize: 50 }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    href={`/bloodhoundscandeep/${row.target}`}
                    title={`Deep Scan ${row.target}`}
                  >
                    <IconButton className={classes.iconButton}>
                      <BsFillCollectionFill style={{ fontSize: 40 }} />
                    </IconButton>
                  </Tooltip>
                </TableCell>

                <TableCell align="center">
                  <Tooltip title={`Click to Schedule scan for ${row.target}`}>
                    <Fab
                      variant="extended"
                      size="small"
                      color="#ad2007"
                      aria-label="add"
                      className={classes.margin}
                      onClick={() => {
                        setSchePop({
                          murl: row.target,
                        });
                      }}
                    >
                      {row.daily ? (
                        <Typography
                          variant="p"
                          component="p"
                          style={{ fontFamily: "orbitron", color: "#ad2007" }}
                        >
                          Daily
                        </Typography>
                      ) : row.weekly ? (
                        <Typography
                          variant="p"
                          component="p"
                          style={{ fontFamily: "orbitron", color: "#ad2007" }}
                        >
                          Weekly
                        </Typography>
                      ) : row.monthly ? (
                        <Typography
                          variant="p"
                          component="p"
                          style={{ fontFamily: "orbitron", color: "#ad2007" }}
                        >
                          Monthly
                        </Typography>
                      ) : (
                        <Typography
                          variant="p"
                          component="p"
                          style={{ fontFamily: "orbitron", color: "black" }}
                        >
                          Not Scheduled
                        </Typography>
                      )}
                    </Fab>
                  </Tooltip>
                </TableCell>
                {/* <TableCell align="center">
                  <Tooltip title="Schedule scan through Securestack">
                    <IconButton
                      onClick={() => {
                        setSchePop({
                          murl: row.target,
                        });
                      }}
                      // href={`https://demolab.securestack.com/SignUpBloodhound/${user.email}`}
                      className={classes.iconButton}
                      aria-label="search"
                    >
                      <FcOvertime style={{ fontSize: 50 }} />
                    </IconButton>
                  </Tooltip>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {schePop && <SchedulePop murl={schePop.murl} pushData={getpushdata} />}
    </Paper>
  );
}
