const getChangesHistory = async (email, token, murl) => {
  console.log("THIS IS EMAIL: " + email);
  console.log("THIS IS token: " + token);
  console.log("THIS IS MURL: " + murl);

  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/logsDBchangeshistory/${email}/${murl}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    }
  );
  const responseData = await response.json();
  console.log(responseData);
  return responseData;
};

export default getChangesHistory;
