import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import {
  Avatar,
  Paper,
  Link,
  AppBar,
  Grid,
  Typography,
  Tab,
  Switch,
  Container,
  Tooltip,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import blood from "../../images/bloodhound.png";
import Toolbar from "@material-ui/core/Toolbar";
import { useAuth0 } from "../../react-auth0-spa";
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import SlideshowIcon from "@material-ui/icons/Slideshow";
import { getThemeProps } from "@material-ui/styles";
import ButtonAppBarCollapse from "./ButtonAppBarCollapse";
import AppBarMenuCollapse from "./AppBarMenuCollapse";
const useStyles = makeStyles((theme) => ({
  button: {
    position: "absolute",
    right: 0,
  },

  title: {
    flexGrow: 1,
  },
}));

const theme = createMuiTheme();

theme.typography.h6 = {
  fontSize: "0.8rem",
  "@media (min-width:600px)": {
    fontSize: "0.8rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.5rem",
  },
};

export default function Nav(props) {
  const { isAuthenticated, user, logout } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [count, setCount] = React.useState(1);
  const [alert, setAlert] = useState(true);
  const [invisible, setInvisible] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(true);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
    setAlert(false);
  };
  const open = Boolean(anchorEl1);

  const handleBadgeVisibility = () => {
    setInvisible(!invisible);
  };
  const handleMenu = (event) => {
    setAnchorEl1(event.currentTarget);
    setOpenAlert(true);
  };

  const classes = useStyles();

  return (
    <div>
      {!isAuthenticated && (
        <>
          <ThemeProvider theme={theme}>
            <AppBar
              position="fixed"
              elevation={0}
              style={{ backgroundColor: "transparent", top: 0 }}
            >
              <Toolbar>
                <IconButton href="/">
                  <img src={blood} />
                </IconButton>

                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <React.Fragment>
                      <Button
                        style={{ color: "#ad2007" }}
                        {...bindTrigger(popupState)}
                      >
                        <Typography
                          style={{ color: "#ad2007" }}
                          variant="h6"
                          component="h6"
                        >
                          <strong>Bloodhound</strong>
                        </Typography>
                      </Button>

                      <IconButton>
                        <Switch
                          checked={props.theme}
                          onChange={() => {
                            console.log(props.theme);
                            if (localStorage.getItem("Mode") == "dark") {
                              localStorage.setItem("Mode", "light");
                            } else {
                              localStorage.setItem("Mode", "dark");
                            }
                            props.pushData(!props.theme);
                          }}
                        />
                      </IconButton>

                      <Button className={classes.button} href="/bloodhound">
                        <Typography
                          style={{ color: "#ad2007" }}
                          variant="h6"
                          component="h6"
                        >
                          <strong>Log in</strong>
                        </Typography>
                      </Button>

                      <Menu {...bindMenu(popupState)}>
                        <MenuItem onClick={popupState.close}>
                          <Button href="/about" style={{ color: "#ad2007" }}>
                            <Typography
                              style={{ color: "#ad2007" }}
                              variant="h7"
                              component="h7"
                            >
                              <strong>About</strong>
                            </Typography>
                          </Button>
                        </MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </Toolbar>
            </AppBar>
          </ThemeProvider>
        </>
      )}

      {isAuthenticated && (
        <ThemeProvider theme={theme}>
          <>
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button href="/">
                    <img style={{ marginLeft: "50%" }} src={blood} />
                  </Button>
                  <Button
                    style={{ color: "#ad2007" }}
                    {...bindTrigger(popupState)}
                  >
                    <Typography
                      style={{ color: "#ad2007" }}
                      variant="h6"
                      component="h6"
                    >
                      <strong>Bloodhound</strong>
                    </Typography>
                  </Button>
                  <Tooltip
                    title={
                      localStorage.getItem("Mode") == "dark"
                        ? "Switch to Light Mode"
                        : "Switch to Dark Mode"
                    }
                  >
                    <Switch
                      checked={props.theme}
                      onChange={() => {
                        console.log(props.theme);
                        if (localStorage.getItem("Mode") == "dark") {
                          localStorage.setItem("Mode", "light");
                        } else {
                          localStorage.setItem("Mode", "dark");
                        }
                        props.pushData(!props.theme);
                      }}
                    />
                  </Tooltip>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={popupState.close}>
                      <Button style={{ color: "#ad2007" }} href="/">
                        <Typography
                          style={{ color: "#ad2007" }}
                          variant="h7"
                          component="h7"
                        >
                          <strong>Scan New</strong>
                        </Typography>
                      </Button>
                    </MenuItem>
                    {/* <MenuItem onClick={popupState.close}>
                      <Button style={{ color: "#ad2007" }} href="/bloodhound">
                        <Typography
                          style={{ color: "#ad2007" }}
                          variant="h7"
                          component="h7"
                        >
                          <strong>Overview</strong>
                        </Typography>
                      </Button>
                    </MenuItem> */}

                    <MenuItem onClick={popupState.close}>
                      <Button style={{ color: "#ad2007" }} href="/targets">
                        <Typography
                          style={{ color: "#ad2007" }}
                          variant="h7"
                          component="h7"
                        >
                          <strong>Targets</strong>
                        </Typography>
                      </Button>
                    </MenuItem>
                    <MenuItem onClick={popupState.close}>
                      <Button style={{ color: "#ad2007" }} href="/support">
                        <Typography
                          style={{ color: "#ad2007" }}
                          variant="h7"
                          component="h7"
                        >
                          <strong>Support</strong>
                        </Typography>
                      </Button>
                    </MenuItem>
                    <MenuItem onClick={popupState.close}>
                      <Button style={{ color: "#ad2007" }} href="/history">
                        <Typography
                          style={{ color: "#ad2007" }}
                          variant="h7"
                          component="h7"
                        >
                          <strong>History</strong>
                        </Typography>
                      </Button>
                    </MenuItem>

                    <MenuItem onClick={popupState.close}>
                      <Button style={{ color: "#ad2007" }} href="/about">
                        <Typography
                          style={{ color: "#ad2007" }}
                          variant="h7"
                          component="h7"
                        >
                          <strong>About</strong>
                        </Typography>
                      </Button>
                    </MenuItem>
                  </Menu>

                  {isAuthenticated && (
                    <>
                      <IconButton
                        style={{
                          marginRight: "auto",
                          marginTop: "auto",
                          position: "absolute",
                          right: 70,
                        }}
                        color="inherit"
                        onClick={handleBadgeVisibility}
                      >
                        {/* <Badge
                          color="secondary"
                          badgeContent={count}
                          invisible={invisible}
                        >
                          <Tooltip title={count}>
                            <NotificationsIcon
                              onClick={handleMenu}
                              style={{ fontSize: 40 }}
                            />
                          </Tooltip>
                        </Badge> */}
                      </IconButton>
                      <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl1}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={open}
                        onClose={handleClose1}
                      >
                        <Alert open={openAlert} severity="error">
                          {count === 1 ? (
                            <Typography>
                              You have {count} remaining deep scan
                            </Typography>
                          ) : (
                            <Typography>
                              You have {count} remaining deep scans
                            </Typography>
                          )}
                        </Alert>
                      </Menu>

                      <Button
                        className={classes.button}
                        style={{
                          marginRight: "auto",
                          marginTop: "auto",
                          position: "absolute",
                          right: 0,
                        }}
                        onClick={handleClick}
                      >
                        <Avatar
                          alt={user.name}
                          src={"/static/images/avatar/1.jpg"}
                        />
                      </Button>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem onClick={handleClose}>
                          <Link href="/profile" color="inherit">
                            Profile
                          </Link>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                          <Link
                            href={`https://demolab.securestack.com`}
                            color="inherit"
                          >
                            CloudBuilder
                          </Link>
                        </MenuItem>
                        <MenuItem onClick={() => logout()}>Logout</MenuItem>
                      </Menu>
                    </>
                  )}
                </React.Fragment>
              )}
            </PopupState>
          </>
        </ThemeProvider>
      )}
    </div>
  );
}
{
  /* 
                  {text === "Targets" && (
                    <Tooltip title="Targets">
                      <span>
                        <FiTarget href="/targets" style={{ fontSize: 30 }} />
                      </span>
                    </Tooltip>
                  )}
                  {text === "History" && (
                    <Tooltip title="History">
                      <span>
                        <AiOutlineHistory
                          href="/history"
                          style={{ fontSize: 30 }}
                        />
                      </span>
                    </Tooltip>
                  )}
                  {text === "About" && (
                    <Tooltip title="About">
                      <InfoOutlinedIcon
                        href="/newabout"
                        style={{ fontSize: 30 }}
                      />
                    </Tooltip>
                  )}
                  {text === "Support" && (
                    <Tooltip title="Support">
                      <HelpOutlineOutlinedIcon
                        href="/support"
                        style={{ fontSize: 30 }}
                      />
                    </Tooltip>
                  )}
                  {text === "Logout" && (
                    <Tooltip title="Logout">
                      <span>
                        <RiAccountCircleLine style={{ fontSize: 30 }} />
                      </span>
                    </Tooltip>
                  )}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            )
          )} */
}
