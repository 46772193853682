import React from "react";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { Button, Paper } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import MobileStepper from "@material-ui/core/MobileStepper";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    maxWidth: 400,
    flexGrow: 1,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {/* {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null} */}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function LegalPopUp() {
  const [open, setOpen] = React.useState(true);
  const [activeStep, setActiveStep] = React.useState(0);

  const classes = useStyles();
  const theme = useTheme();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleClickOpen = () => {
  //   setOpen(props.open);
  // };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <Dialog
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Disclaimer
        </DialogTitle>
        <DialogContent dividers>
          {activeStep === 0 && (
            <React.Fragment>
              <Typography gutterBottom>
                This free report cannot and does not protect against personal or
                business loss as the result of use of the applications or
                systems described. In addition, this report is provided as is
                and SecureStack offers no warranties, representations or legal
                certifications concerning the applications or systems it tests.
              </Typography>
              <Typography gutterBottom>
                All software includes defects: nothing in this web delivered
                report is intended to represent or warrant that security testing
                was complete and without error, nor does this document represent
                or warrant that the application tested is suitable to task, free
                of other defects than reported, fully compliant with any
                industry standards, or fully compatible with any operating
                system, hardware, or other application.
              </Typography>
              <Typography gutterBottom>
                By using this information you agree that SecureStack shall be
                held harmless in any event
              </Typography>
            </React.Fragment>
          )}
          {activeStep === 1 && (
            <React.Fragment>
              <Typography gutterBottom>
                Bloodhound has two types of scans: a fast quickscan that
                potentially uses cached results from our database. Those results
                are no older than 14 days. The other type of scan is our
                real-time deepscan. Deepscans never use cached data and run in
                real-time, so usually take anywhere from 2 to 10 minutes. If you
                are integrating Bloodhound into your CI/CD pipeline, you will
                probably want to be using our deepscans so that you find out the
                state of your application right now.
              </Typography>
            </React.Fragment>
          )}
          <Paper>
            <MobileStepper
              variant="dots"
              steps={2}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === 1}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                </Button>
              }
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="secondary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
