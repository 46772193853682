import React from "react";
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";
import { Typography, Grid, Container, Paper, Button } from "@material-ui/core/";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import BuildIcon from "@material-ui/icons/Build";
import ProblemTestData from "./utils/ProblemTestData.json";
import NewGauge from "./Gauge/NewGauge";
import WarningIcon from "@material-ui/icons/Warning";
const theme = createMuiTheme();

theme.typography.h3 = {
  fontSize: "2.2rem",
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "7rem",
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  typography: {
    paddingTop: 5,
    color: theme.palette.text.primary,
    textAlign: "center",
    fontFamily: "Orbitron",
    fontSize: 20,
  },

  propStyle: {
    textAlign: "left",
    fontFamily: "Orbitron",
    padding: theme.spacing(1),
    color: "black",
  },

  fixButton: {
    color: "#ad2007",
    right: 0,
  },
}));

export default function Problems() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {ProblemTestData.map((data) => (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography className={classes.propStyle}>
              Supports Encryption
              {data.encryption ? (
                <CheckCircleIcon />
              ) : (
                <WarningIcon style={{ color: "#ad2007", fontSize: 40 }} />
              )}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            {/* <Button
              fullWidth
              className={classes.fixButton}
              variant="contained"
              endIcon={<BuildIcon />}
            >
              Fix
            </Button> */}
          </Grid>
        </Grid>
      ))}
    </div>
  );
}
