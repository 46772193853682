import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import { Button } from "@material-ui/core";
import DownloadCode from "../functions/DownloadCode";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  }
});

const data = [
  {
    title: "Download File-1",
    description: "Advanced bi-directional alliance",
    image: "Download File-1.json",
    price: "$74.96"
  },

  {
    title: "Download File-2",
    description: "Automated demand-driven function",
    image: "Download File-2.json",
    price: "$92.80"
  },

  {
    title: "Download File-3",
    description: "Inverse contextually-based groupware",
    image: "Download File-3.json",
    price: "$11.03"
  },

  {
    title: "Download File-4",
    description: "Digitized web-enabled hierarchy",
    image: "Download File-4.json",
    price: "$42.72"
  },

  {
    title: "Download File-5",
    description: "Multi-tiered executive Graphic Interface",
    image: "Download File-5.json",
    price: "$35.49"
  }
];

class SimpleTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      values: data
    };
  }

  handleClick = e => {
    e.preventDefault();

    console.log("The link was clicked.");
    console.log(this.state.values);

    // alert('The link was clicked.');

    // axios.get('/DwonlodedDataExport').then(response => {

    // console.log(response)

    // })
    DownloadCode();

    // axios({
    //   url: "https://jsonplaceholder.typicode.com/todos",
    //   method: "GET",
    //   responseType: "stream" // important
    // }).then(response => {
    //   console.log(JSON.stringify(response.data));
    //   const url = window.URL.createObjectURL(
    //     new Blob([JSON.stringify(response.data)], { type: "text" })
    //   );

    //   const link = document.createElement("a");
    //   link.href = url;
    //   link.setAttribute("download", "JSonfile");
    //   document.body.appendChild(link);
    //   link.click();
    // });
  };

  handleClick1 = e => {
    e.preventDefault();

    console.log("The link was clicked.");

    // alert('The link was clicked.');

    // axios.get('/DwonlodedDataExport').then(response => {

    // console.log(response)

    // })

    axios({
      url: "/downLoadZIPFile",
      method: "GET",
      responseType: "blob" // important
    }).then(response => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/octet-stream",
          responseType: "arraybuffer"
        })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "JSONSearchResultsData.zip");
      document.body.appendChild(link);
      link.click();
    });
  };

  dataDisplay() {
    if (this.state.values.length !== 0) {
      return (
        <div>
          <div>
            <Paper className>
              <Table className>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">VIN</TableCell>

                    <TableCell align="center">File Nmae</TableCell>

                    <TableCell align="center">Price</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.state.values.map(row => (
                    <TableRow key={row.id}>
                      <TableCell align="center">{row.title}</TableCell>

                      <TableCell align="center">
                        <a href="" onClick={this.handleClick}>
                          {row.image}
                        </a>
                      </TableCell>

                      <TableCell align="center">{row.price}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </div>

          <div>
            <Button
              variant="contained"
              color="primary"
              alignitems="center"
              onClick={this.handleClick1}
              size="large"
              style={{ margin: 20 }}
            >
              Download All Files
            </Button>
          </div>
        </div>
      );

      // console.log(this.state.values.length)
    }
  }

  render() {
    //const { classes,values } = this.props;

    return <div>{this.dataDisplay()}</div>;
  }
}

SimpleTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SimpleTable);
