import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Typography,
  Button,
  List,
  ListItem,
} from "@material-ui/core";
import Gauge from "../../Charts/Gauge/Gauge";
import Map from "../../map/Map";
import CircularProgress from "@material-ui/core/CircularProgress";
import FinalScoreGauge from "../../Charts/Gauge/FinalScoreGauge";
import Alert from "@material-ui/lab/Alert";
import Skeleton from "@material-ui/lab/Skeleton";
import GradeChangePopUp from "../../GradeChangePopUp";
import gradesCompJson from "../../../functions/gradesCompaJson";
import RecentChanges from "../../../functions/getRecentChanges";
import { useAuth0 } from "../../../react-auth0-spa";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 100,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  paperrecent: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: 320,
    overflow: "auto",
  },
  listcss: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 200,
  },
  childheading: {
    color: "#ad2007",
    fontFamily: "Orbitron",
    fontSize: 20,
  },
}));

export default function OverviewScore(props) {
  const classes = useStyles();
  const [init, setInit] = useState(false);
  const [change, setChange] = useState(false);
  const [newChange, setNewChange] = useState(false);
  const { isAuthenticated, user, getTokenSilently } = useAuth0();

  if (
    !init &&
    props.grades.finalscore &&
    props.grades.lastscannedfinalscore &&
    props.grades.finalscore !== props.grades.lastscannedfinalscore
  ) {
    console.log("Inserting The changes to database!");
    console.log(props.grades);
    console.log(props.grades.finalscore);

    getChanges();
    getRecent();
    setInit(true);
  }
  async function getChanges() {
    const token = await getTokenSilently();
    const responseCompPrior = await gradesCompJson(
      localStorage.getItem("scanref"),
      props.grades.priorscanref,
      user.email,
      props.data.murl,
      token
    );

    await getRecent();
  }

  async function getRecent() {
    const token = await getTokenSilently();

    const recentchanges = await RecentChanges(
      token,
      props.grades.scanreference
    );

    setChange(recentchanges);
    if (recentchanges) {
      setNewChange(JSON.parse(recentchanges.NewChange));
    }
  }

  return (
    <div>
      <br />

      <Grid container>
        <Grid item xs={12}>
          <Typography
            style={{ textAlign: "center" }}
            variant="h4"
            component="h4"
          >
            <strong>REPORT FOR </strong>
          </Typography>
          <Typography
            variant="h4"
            component="h4"
            style={{
              fontFamily: "Orbitron",
              textAlign: "center",
            }}
          >
            {props.data.murl}
          </Typography>
        </Grid>
      </Grid>

      <br />
      <br />
      <Grid container spacing={1}>
        <Grid item xs={3}>
          {!props.data.securityscore ? (
            <div>
              <Paper className={classes.paper}>
                <Typography
                  style={{
                    height: 100,
                    textAlign: "center",
                    fontSize: "20px",
                    fontFamily: "courier",
                  }}
                >
                  <Skeleton variant="circle" width={150} height={150} />
                </Typography>
              </Paper>
            </div>
          ) : (
            <Paper className={classes.paper}>
              <Gauge data={props.data.securityscore} />
              <Typography
                style={{ fontFamily: "Orbitron" }}
                variant="h6"
                component="h6"
              >
                <strong> Security Score </strong>
              </Typography>
            </Paper>
          )}
        </Grid>
        <Grid item xs={3}>
          {!props.data.scalabilityscore ? (
            <div>
              <Paper className={classes.paper}>
                <Typography
                  style={{
                    height: 100,
                    textAlign: "center",
                    fontSize: "20px",
                    fontFamily: "courier",
                  }}
                >
                  <Skeleton variant="circle" width={150} height={150} />
                </Typography>
              </Paper>
            </div>
          ) : (
            <Paper className={classes.paper}>
              <Gauge data={props.data.scalabilityscore} />
              <Typography
                style={{ fontFamily: "Orbitron" }}
                variant="h6"
                component="h6"
              >
                <strong> Scalability Score </strong>
              </Typography>
            </Paper>
          )}
        </Grid>
        <Grid item xs={3}>
          {!props.grades.finalscore ? (
            <div>
              <Paper style={{ height: 300 }} className={classes.paper}>
                <Typography
                  style={{
                    height: 100,
                    textAlign: "center",
                    fontSize: "20px",
                    fontFamily: "courier",
                  }}
                >
                  <Skeleton
                    variant="circle"
                    style={{ marginLeft: "25%", marginRight: "25%" }}
                    width={150}
                    height={150}
                  />
                  <br />
                  <Alert severity="error">
                    Please allow up to 15 minutes for this scan to take effect
                  </Alert>
                </Typography>
              </Paper>
            </div>
          ) : (
            <Paper className={classes.paper}>
              {props.grades.lastscannedfinalscore &&
                props.grades.lastscannedfinalscore <
                  props.grades.finalscore && (
                  <GradeChangePopUp
                    priorscore={props.grades.lastscannedfinalscore}
                    finalscore={props.grades.finalscore}
                    priorscanref={props.grades.priorscanref}
                  />
                )}
              <Gauge data={props.grades.finalscore} />
              <Typography
                style={{ fontFamily: "Orbitron" }}
                variant="h6"
                component="h6"
              >
                <strong> Final Score </strong>
              </Typography>
            </Paper>
          )}
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paperrecent}>
            <Typography
              style={{ fontFamily: "Orbitron", color: "green" }}
              variant="h5"
              component="h5"
            >
              <strong>Most recent Changes </strong>
              <br />
              <br />
            </Typography>

            {newChange ? (
              Object.keys(newChange).map((key, i) =>
                key === "email" ||
                key === "scanreference" ||
                key === "elapsedtime" ? (
                  <React.Fragment></React.Fragment>
                ) : (
                  <React.Fragment>
                    <Typography className={classes.childheading}>
                      {key}:
                    </Typography>
                    <Typography>
                      <strong> {newChange[key]}</strong>
                    </Typography>
                  </React.Fragment>
                )
              )
            ) : (
              <Typography style={{ fontSize: 20 }}>
                <strong> No Recent Changes...</strong>
              </Typography>
            )}
            {/* {newChange && (
                <Button style={{ fontFamily: "Orbitron" }}> More Info </Button>
              )} */}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
