import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Paper,
  Container,
  Button,
  List,
  ListItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import getChangesHistory from "../../../functions/getChangesHistory";
import { useAuth0 } from "../../../react-auth0-spa";
import RecentChanges from "../../../functions/getRecentChanges";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import oldChanges from "../../../functions/getDataDB";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(25),
    flexBasis: "33.33%",
    flexShrink: 0,
    fontFamily: "Orbitron",
  },
  table: {
    minWidth: 650,
  },
  paper: {
    textAlign: "center",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  childheading: {
    color: "#ad2007",
    fontFamily: "Orbitron",
  },
}));
function StateChange(props) {
  const murl = props.data.murl;
  const classes = useStyles();
  const [data, setData] = useState(false);
  const [change, setChange] = useState(false);
  const { isAuthenticated, user, getTokenSilently } = useAuth0();
  const [expanded, setExpanded] = React.useState(false);
  const [expandedChild, setExpandedChild] = React.useState(false);
  const [newChange, setNewChange] = useState(false);
  const [oldChange, setOldChange] = useState(false);

  useEffect(() => {
    getChanges();
    getPrioChanges();
  }, []);
  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  //   if (isExpanded) {
  //     getChanges(panel);
  //   }
  //   console.log(panel);
  // };
  // const handleChangeChild = (panel) => (event, isExpanded) => {
  //   setExpandedChild(isExpanded ? panel : false);
  //   if (isExpanded) {
  //     newChangesFunc(panel);
  //   }
  //   console.log(panel);
  // };

  // function newChangesFunc(scanref) {
  //   Object.values(change).map((row) => {
  //     if (row.scanreference === scanref) {
  //       console.log("Its a Match");
  //       console.log(JSON.parse(row.NewChange));
  //       setNewChange(JSON.parse(row.NewChange));
  //     }
  //   });oldChanges
  // }

  async function getPrioChanges() {
    const token = await getTokenSilently();
    const oldscan = await oldChanges(
      user.email,
      props.grades.priorscanref,
      token
    );
    setOldChange(oldscan);
  }

  async function getChanges() {
    const token = await getTokenSilently();
    const resChanges = await RecentChanges(token, props.grades.scanreference);

    setChange(resChanges);
    if (resChanges) {
      setNewChange(JSON.parse(resChanges.NewChange));
    }
  }
  // const getTargetsAll = async () => {
  //   const token = await getTokenSilently();
  //   const getTarget = await RecentChanges(user.email, token);
  //   setData(getTarget);
  // };
  return (
    <Container>
      {/* <Grid container>
        <Grid item xs={12}>
          <Paper elevation={0} className={classes.paper}>
            <Typography className={classes.heading}>Changes</Typography>
          </Paper>
        </Grid>
      </Grid> */}
      <br />
      <Grid container>
        {Object.keys(newChange).map((key) =>
          key === "email" ||
          key === "scanreference" ||
          key === "elapsedtime" ? (
            <React.Fragment></React.Fragment>
          ) : (
            <React.Fragment>
              <Grid item xs={2}>
                <Typography className={classes.childheading}>{key} </Typography>
              </Grid>
              <Grid item xs={5}>
                {oldChange[key]}
              </Grid>
              <Grid item xs={1}>
                <ArrowRightAltIcon style={{ fontSize: 40, color: "green" }} />
              </Grid>
              <Grid item xs={4}>
                {newChange[key]}
              </Grid>
            </React.Fragment>
          )
        )}
      </Grid>
      <br />
      {!newChange ? (
        <Paper className={classes.paper}>
          <Typography
            style={{ fontFamily: "Orbitron", color: "#ad2007", fontSize: 30 }}
          >
            No changes...
          </Typography>
        </Paper>
      ) : (
        <></>
      )}
    </Container>
  );
}
export default StateChange;
