import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Typography, Button } from "@material-ui/core";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import Map from "../../map/Map";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "justify",
    color: theme.palette.text.secondary,
  },
  dot: {
    backgroundColor: "white",

    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.2)",
    },
  },
}));

export default function OSInfo(props) {
  const classes = useStyles();
  const [listip, setlistip] = React.useState(false);
  let availableData = props.data.iplist;
  if (!listip) {
    replaceCommaLine(availableData);

    function replaceCommaLine(data) {
      //convert string to array and remove whitespace
      let dataToArray = data.split(",").map((item) => item.trim());
      console.log(dataToArray);
      //convert array to string replacing comma with new line
      //setlistip(dataToArray);
      setlistip(dataToArray);
      return dataToArray;
    }
  }

  function GenrealDetails(props) {
    return (
      <React.Fragment>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper elevation={0} className={classes.paper}>
              {props.data.runningon && (
                <Typography
                  style={{ fontFamily: "courier" }}
                  variant="h6"
                  component="h6"
                >
                  OS: <strong>{props.data.runningon}</strong>
                </Typography>
              )}
              {props.data.cloudver && (
                <Typography
                  style={{ fontFamily: "courier" }}
                  variant="h6"
                  component="h6"
                >
                  HOSTING: <strong>{props.data.cloudver} </strong>
                </Typography>
              )}
              {props.data.isp && (
                <Typography
                  style={{ fontFamily: "courier" }}
                  variant="h6"
                  component="h6"
                >
                  HOSTING: <strong>{props.data.isp}</strong>
                </Typography>
              )}
              {props.data.countryhost && (
                <Typography
                  style={{ fontFamily: "courier" }}
                  variant="h6"
                  component="h6"
                >
                  DATA-LOCATION: <strong>{props.data.countryhost}</strong>
                </Typography>
              )}
              {props.data.latitude && (
                <Typography
                  style={{ fontFamily: "courier" }}
                  variant="h6"
                  component="h6"
                >
                  LATITUDE: <strong>{props.data.latitude}</strong>
                </Typography>
              )}
              {props.data.longitude && (
                <Typography
                  style={{ fontFamily: "courier" }}
                  variant="h6"
                  component="h6"
                >
                  LONGITUDE:<strong>{props.data.longitude}</strong>
                </Typography>
              )}
              {props.data.waflocation === "os" && (
                <Typography
                  style={{ fontFamily: "courier" }}
                  variant="h6"
                  component="h6"
                >
                  Protected By:<strong>{props.data.wafoutput}</strong>
                </Typography>
              )}
            </Paper>
          </Grid>
          {/* <Grid item xs={12}>
            {" "}
            {props.data.latitude && props.data.longitude && (
              <Map lat={props.data.latitude} long={props.data.longitude} />
            )}{" "}
          </Grid> */}
        </Grid>
      </React.Fragment>
    );
  }
  function FirewallDetails() {
    return (
      <React.Fragment>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper elevation={0} className={classes.paper}>
              <Typography variant="h5" component="h5">
                Firewall Rules
              </Typography>
              <br />
              {props.firewallInfo.map((row, index) => (
                <div>
                  <Typography
                    style={{ fontFamily: "orbitron" }}
                    variant="h6"
                    component="h6"
                  >
                    {row.firewallrule}

                    <Typography
                      align="right"
                      style={{
                        textAlign: "right",
                        display: "inline-block",
                        color: "red",
                        fontFamily: "courier",
                      }}
                      variant="h5"
                      component="h5"
                    >
                      &nbsp;Open
                    </Typography>
                  </Typography>
                </div>
              ))}
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
  function IpDetails() {
    return (
      <React.Fragment>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper elevation={0} className={classes.paper}>
              <Typography variant="h5" component="h5">
                IP Addresses
              </Typography>

              <br />
              {listip &&
                listip.map((row) => (
                  <Typography
                    style={{ fontFamily: "orbitron" }}
                    variant="h6"
                    component="h6"
                  >
                    {row}
                  </Typography>
                ))}
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <Timeline align="alternate">
            <TimelineItem>
              <TimelineDot variant="outlined" className={classes.dot}>
                <img
                  src={`https://bloodhound.securestack.com/images/architecture/${props.data.runningon}.png`}
                />
              </TimelineDot>
            </TimelineItem>
          </Timeline>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid container item xs={4} spacing={2}>
          <GenrealDetails data={props.data} />
        </Grid>
        <Grid container item xs={1} spacing={2}></Grid>
        <Grid container item xs={4} spacing={2}>
          <FirewallDetails firewallInfo={props.firewallInfo} />
        </Grid>

        <Grid container item xs={3} spacing={2}>
          <IpDetails />
        </Grid>
      </Grid>
    </div>
  );
}
