import React from "react";
import {
  Grid,
  Paper,
  Snackbar,
  Typography,
  Link,
  Card,
  CardActionArea,
  DialogTitle,
  CardActions,
  CardContent,
  InputBase,
  Tooltip,
  DialogActions,
  DialogContent,
  DialogContentText,
  CardMedia,
  Box,
  IconButton,
  ButtonBase,
  Container,
  Collapse,
  Dialog,
  Divider,
  CardHeader,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Avatar from "@material-ui/core/Avatar";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "100%", // 16:9+
    borderRadius: 16,
    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.2)",
    },
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

function CardComponent(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        // avatar={
        //   <Avatar aria-label="recipe" className={classes.avatar}>
        //     R
        //   </Avatar>
        // }
        // action={
        //   <IconButton aria-label="settings">
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        //title={props.name}

        subheader={props.heading}
      />
      <CardMedia
        className={classes.media}
        image={props.imageUrl}
        title={props.heading}
      />
      <CardContent>
        <Typography
          style={{ fontFamily: "orbitron" }}
          variant="body2"
          component="p"
        >
          {props.name}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        {/* <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton> */}
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>Description: Coming Soon...</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default CardComponent;
