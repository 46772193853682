const getLogCompMurl = async (scanref, email, token) => {
  console.log("THIS IS EMAIL: " + email);
  console.log("THIS IS scanref: " + scanref);
  console.log("THIS IS token: " + token);

  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/getLogCompMurl/${email}/${scanref}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    }
  );
  const responseData = await response.json();
  console.log(responseData);
  return responseData[0];
};

export default getLogCompMurl;
