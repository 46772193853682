const getTargets = async (email, token) => {
  console.log("This is Email ==> " + email);
  console.log("This is Token ==> " + token);

  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/logsDBTargets/${email}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    }
  );
  const responseData = await response.json();
  console.log(responseData);
  return responseData;
};

export default getTargets;
