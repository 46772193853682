import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useAuth0 } from "../react-auth0-spa";
import getChangesTimeline from "../functions/getChangesTimeline";
import Test from "./SecTimeline";

import { Button, Grid, Paper, Container } from "@material-ui/core/";
function anime() {
  //   function generateDayWiseTimeSeries(baseval, coun, yrange) {
  //     var i = 0;
  //     var series = [];
  //     while (i < coun) {
  //       var x = baseval;
  //       var y =
  //         Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

  // useEffect(() => {
  //   getChangesAll();
  // }, []);

  // async function getChangesAll() {
  //   const token = await getTokenSilently();
  //   const getChangesRes = await getChangesTimeline(
  //     "bTiR9dz5WQdEolOFBMVt1y5dl8Cpi5nR",
  //     token
  //   );
  //   setData(getChangesRes);
  // }

  const state = {
    series: [
      {
        name: "CSP",
        data: [1, 1, 0, 1, 0, 1, 1, 1, 0, 0, 1, 0, 1, 0, 0, 0],
      },
      {
        name: "HTTP REDIRECT ",
        data: [2, 0, 0, 0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 0, 0, 0],
      },
      {
        name: "HSTS",
        data: [3, 3, 3, 0, 0, 3, 3, 0, 3, 3, 0, 3, 3, 0, 3, 3],
      },
      {
        name: "WAF",
        data: [4, 0, 4, 4, 4, 4, 4, 4, 0, 0, 4, 0, 0, 0, 0, 0],
      },
      {
        name: "Firewall",
        data: [5, 5, 5, 5, 5, 0, 0, 0, 0, 0, 5, 0, 0, 5, 5, 5],
      },
      {
        name: "Scalability",
        data: [6, 0, 0, 0, 0, 6, 6, 6, 6, 6, 6, 6, 0, 0, 0, 6],
      },
      {
        name: "Policy",
        data: [7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 7, 7, 7, 7, 7],
      },
    ],
    options: {
      chart: {
        height: 1000,

        type: "line",
        zoom: {
          enabled: true,
        },
        animations: {
          enabled: true,
        },
      },
      stroke: {
        width: [3, 3, 3, 3, 3, 3, 3],
        curve: "straight",
      },
      labels: {},
      dataLabels: {
        enabled: false,
      },
      labels: [
        "15-Feb-2020",
        "16-Feb-2020",
        "17-Feb-2020",
        "18-Feb-2020",
        "19-Feb-2020",
        "20-Feb-2020",
        "21-Feb-2020",
        "22-Feb-2020",
        "23-Feb-2020",
        "24-Feb-2020",
        "25-Feb-2020",
        "26-Feb-2020",
        "27-Feb-2020",
        "28-Feb-2020",
        "29-Feb-2020",
        "1-Mar-2020",
      ],
      title: {
        text: "Comparision of Scans",
      },
      xaxis: {},
      yaxis: {
        tickAmount: 7,
        labels: {
          maxWidth: 500,

          offsetY: 2,
          offsetX: 18,
          style: {
            fontSize: 10,
            colors: [],
          },
          formatter: function (val, index) {
            console.log(val);
            if (val === 1) {
              return "CSP";
            }
            if (val === 2) {
              return "HTTP";
            }
            if (val === 3) {
              return "HSTS";
            }
            if (val === 4) {
              return "WAF";
            }
            if (val === 5) {
              return "Firewall";
            }
            if (val === 6) {
              return "Scalability";
            }
            if (val === 7) {
              return "Policy";
            } else {
              if (val === 0) {
                return "OFF";
              }
            }
          },
          offsetX: 20,
        },
      },
    },
  };

  return (
    <div>
      <div id="card">
        <div id="chart">
          <Grid
            container
            style={{ backgroundColor: "#FFFFFF", color: "black" }}
          >
            <Grid item xs={12}>
              <Chart
                options={state.options}
                series={state.series}
                type="line"
                height={750}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
export default anime;
