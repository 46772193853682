import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import LOGO from "../../images/secure-logo.png";
import TimelineDot from "@material-ui/lab/TimelineDot";
import dog from "../../images/Bloodhound_only_90x45.png";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { AiOutlineSecurityScan } from "react-icons/ai";
import { Zoom, Slide } from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "none",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  //   active: {
  //     backgroundImage:
  //       "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  //     boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  //   },
  //   completed: {
  //     backgroundImage:
  //       "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  //   },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <AiOutlineSecurityScan style={{ fontSize: 50 }} />,
    2: <FiberManualRecordIcon color="primary" style={{ fontSize: 30 }} />,
    4: <FiberManualRecordIcon color="primary" style={{ fontSize: 30 }} />,
    3: <AccountCircleIcon style={{ fontSize: 50, color: "#ad2007" }} />,
    5: <img src={dog} />,

    6: <img src={LOGO} />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "50%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function CustomizedSteppers() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(6);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <div className={classes.root}>
      <Stepper
        nonLinear
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        <Step>
          <StepLabel StepIconComponent={ColorlibStepIcon}>
            <Zoom in={true} style={{ transitionDelay: "700ms" }}>
              <Typography style={{ fontFamily: "Orbitron" }}>
                Scan your webapp
              </Typography>
            </Zoom>
          </StepLabel>
        </Step>

        <Step>
          <StepLabel StepIconComponent={ColorlibStepIcon}>
            <Zoom in={true} style={{ transitionDelay: "900ms" }}>
              <Typography style={{ fontFamily: "Orbitron" }}>
                Review our quick scan
              </Typography>
            </Zoom>
          </StepLabel>
        </Step>

        <Step>
          <StepLabel StepIconComponent={ColorlibStepIcon}>
            <Zoom in={true} style={{ transitionDelay: "1100ms" }}>
              <Typography style={{ fontFamily: "Orbitron" }}>
                Create an account
              </Typography>
            </Zoom>
          </StepLabel>
        </Step>

        <Step>
          <StepLabel StepIconComponent={ColorlibStepIcon}>
            <Zoom in={true} style={{ transitionDelay: "1400ms" }}>
              <Typography style={{ fontFamily: "Orbitron" }}>
                Review our deepscan of your app
              </Typography>
            </Zoom>
          </StepLabel>
        </Step>

        <Step>
          <StepLabel StepIconComponent={ColorlibStepIcon}>
            <Zoom in={true} style={{ transitionDelay: "1600ms" }}>
              <Typography style={{ fontFamily: "Orbitron" }}>
                Find vulnerabilities through bloodhound
              </Typography>
            </Zoom>
          </StepLabel>
        </Step>

        <Step>
          <StepLabel StepIconComponent={ColorlibStepIcon}>
            <Zoom in={true} style={{ transitionDelay: "2000ms" }}>
              <Typography style={{ fontFamily: "Orbitron" }}>
                Fix vulnerabilities through securestack
              </Typography>
            </Zoom>
          </StepLabel>
        </Step>
      </Stepper>
    </div>
  );
}
