import React from "react";
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";
import { Typography, Grid, Container, Paper } from "@material-ui/core/";

const theme = createMuiTheme();

theme.typography.h3 = {
  fontSize: "2.2rem",
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "7rem",
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    fontFamily: "Orbitron",
    fontSize: 20,
  },
  propStyle: {
    color: theme.palette.text.primary,
    fontFamily: "Orbitron",
    fontSize: 20,
  },
}));

export default function ScanTotal() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography className={classes.paper}>
            Apps Scanned: <br />
            <Typography className={classes.propStyle}>9</Typography>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.paper}> 9% of total</Typography>
        </Grid>
      </Grid>
    </div>
  );
}
