import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logo from "../images/dog.png";
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
  
  }));

export default function ResponsiveImg() {
  const classes = useStyles();

  return (
      <div>
    <Grid container spacing={3}>
    

    <Grid item xs={12}>
  
    <ButtonBase className={classes.image}>

          <img className={classes.img} alt="complex" src={logo} />
         
        </ButtonBase>
     
    </Grid>
    </Grid>
    </div>
  );
}