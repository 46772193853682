import React, { Component } from "react";
import { Map, GoogleApiWrapper } from "google-maps-react";
import { Grid } from "@material-ui/core";
const mapStyles = {
  width: "25%",
  height: "25%",
};

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    {
      console.log(this.props.lat);
    }
  }
  render() {
    return (
      <Grid container>
        <Grid item xs={12}>
          {" "}
          <Map
            google={this.props.google}
            zoom={6}
            // style={mapStyles}
            initialCenter={{
              lat: `${parseInt(this.props.lat)}`,
              lng: `${parseInt(this.props.long)}`,
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDCN7yq-z2Z47URwXy4ky-XDOXzfdNYALw",
})(MapContainer);
