import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { Typography, Button, Grid, Paper } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import getDataDBemail from "../functions/getDataDBemail";
import { useAuth0 } from "../react-auth0-spa";
import Score from "../components/Score"
import ServicesComp from "../components/ServicesComp"

import ProgressBarc from "../components/ProgressBarc";
import ReactGA from 'react-ga';
import mixpanel from 'mixpanel-browser';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '80%',
    marginTop: 50,
    marginLeft: "10%"


  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function ScanHistory() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [init, setInit] = React.useState(true);


  const [data, setData] = useState(false);
  const [progress, setProgress] = useState(true);
  const { isAuthenticated,getTokenSilently, user } = useAuth0();


  const getScanHistory = async () => {
    setProgress(true)
    const token = await getTokenSilently()
    console.log("THIS IS returned Token FROM Get" + JSON.stringify(token))
    const resGetDB = await getDataDBemail(user.email, token);
    console.log(resGetDB);
    setData(resGetDB)
    setProgress(false);
    setInit(false)

  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // const handleChange1 = (panel) => async (event, isExpanded1) => {

  //   setExpanded1(isExpanded1 ? panel : false);

  // };
  useEffect(() => {

    {
    isAuthenticated && init && getScanHistory()
      { console.log(data) }
    }
  })
  // function handleOnclick(data) {
  //   localStorage.setItem("scanref", data);
  //   console.log("setting data" + localStorage.setItem("scanref", data))
  // }
  return (
    <div className={classes.root}>

<br />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={0} className={classes.paper}>
            <Typography  style ={{color:"#ad2007" }} variant="h6"  component="h6"> <strong>SCAN HISTORY</strong>
            </Typography>

          </Paper>
        </Grid>
      </Grid>

  {progress && (
        <div>
        <ProgressBarc
          style={{
            height: 10,
            marginLeft: "25%",
            marginRight: "25%",
            position: "relative",
            width: "50%",
            backgroundColor: "#ad2007",
          }}
          color="#61ed68"
        />
       </div>
      )}

      {
        Object.values(data).map((item) =>
          <ExpansionPanel expanded={expanded === item.ScanID} onChange={handleChange(item.ScanID)}>

            <ExpansionPanelSummary

              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
            <Typography style={{ color: "#696969" }} variant="h6" component="h6" ><strong>{item.murl}</strong></Typography> <br />
            </ExpansionPanelSummary>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper elevation={0} className={classes.paper}>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ background: "#ad2007" }}
                    size="large"
                    href="/bloodhound"
                    onClick={() => {
                      localStorage.setItem("scanref", item.scanreference)
                      mixpanel.track("Scan history report selected");
                      ReactGA.event({
                        category: 'History',
                        action: 'Opened a history report'
                      });
                    }}
                  >
                    Get Report
            </Button>
           
                </Paper>
              </Grid>
            </Grid>
            <ExpansionPanelDetails>


              <Grid container justify="center" spacing={3}>
                <Grid item xs={3}>

                  <Score securityscore={item.securityscore} />
                </Grid>
                <Grid item lg>
                  <br />

                  <ServicesComp data={item} />

                </Grid>
                <Grid item lg>
            
                </Grid>
              </Grid>

            </ExpansionPanelDetails>
          </ExpansionPanel>
        )
      }
    </div>
  );
}





// return (
//   <div className={classes.root}>

// <br />
//     <Grid container spacing={3}>
//       <Grid item xs={12}>
//         <Paper elevation={0} className={classes.paper}>
//           <Typography  style ={{color:"#ad2007" }} variant="h6"  component="h6"> <strong>SCAN HISTORY</strong>
//           </Typography>

//         </Paper>
//       </Grid>
//     </Grid>

// {progress && (
//       <div>
//       <ProgressBarc
//         style={{
//           height: 10,
//           marginLeft: "25%",
//           marginRight: "25%",
//           position: "relative",
//           width: "50%",
//           backgroundColor: "#ad2007",
//         }}
//         color="#61ed68"
//       />
//      </div>
//     )}

//     {
//       Object.values(data).map((item) =>
//         <ExpansionPanel expanded={expanded === item.ScanID} onChange={handleChange(item.ScanID)}>

//           <ExpansionPanelSummary

//             expandIcon={<ExpandMoreIcon />}
//             aria-controls="panel1bh-content"
//             id="panel1bh-header"
//           >
//           <Typography style={{ color: "#696969" }} variant="h6" component="h6" ><strong>{item.murl}</strong></Typography> <br />
//           </ExpansionPanelSummary>
//           <Grid container spacing={3}>
//             <Grid item xs={12}>
//               <Paper elevation={0} className={classes.paper}>
//                 <Button
//                   variant="contained"
//                   color="secondary"
//                   style={{ background: "#ad2007" }}
//                   size="large"
//                   href="/bloodhound"
//                   onClick={() => {
//                     localStorage.setItem("scanref", item.scanreference)
//                     mixpanel.track("Scan history report selected");
//                     ReactGA.event({
//                       category: 'History',
//                       action: 'Opened a history report'
//                     });
//                   }}
//                 >
//                   Get Report
//           </Button>
         
//               </Paper>
//             </Grid>
//           </Grid>
//           <ExpansionPanelDetails>


//             <Grid container justify="center" spacing={3}>
//               <Grid item xs={3}>

//                 <Score securityscore={item.securityscore} />
//               </Grid>
//               <Grid item lg>
//                 <br />

//                 <ServicesComp data={item} />

//               </Grid>
//               <Grid item lg>
//                 <ScoreCard data={item} />
//               </Grid>
//             </Grid>

//           </ExpansionPanelDetails>
//         </ExpansionPanel>

//       )


//     }
//   </div>
// );
