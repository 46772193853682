import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Bloodhoundmain from "../views/Bloodhoundmain";
import {
  Grid,
  Typography,
  Container,
  Box,
  Button,
  Paper,
} from "@material-ui/core";
import BuildIcon from "@material-ui/icons/Build";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  media: {
    height: 140,
  },
  typography: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
}));

const defaultProps = {
  bgcolor: "white",
  m: 1,
  border: 1,
};
const defaultPropsp = {
  bgcolor: "#ad2007",
  m: 1,
  border: 1,
};
export default function ProblemFound(props) {
  console.log(JSON.stringify(props));
  const classes = useStyles();

  const [solutionClick, setSolutionClick] = useState(4);

  return (
    <Container maxWidth="lg ">
      <div className={classes.root}>
        <br />

        <br />

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          alignContent="center"
          spacing={3}
        >
          {props.data.problem1 && (
            <Grid item xs={12} sm={4}>
              <Typography
                className={classes.typography}
                style={{ fontFamily: "Orbitron", textAlign: "center" }}
                variant="h6"
              >
                <Box
                  color=" white "
                  borderRadius="borderRadius"
                  borderColor="secondary.main"
                  {...defaultPropsp}
                >
                  {props.data.problem1}
                </Box>
              </Typography>
            </Grid>
          )}

          {props.data.problem2 && (
            <Grid item xs={12} sm={4}>
              <Typography
                className={classes.typography}
                style={{ textAlign: "center", fontFamily: "Orbitron" }}
                variant="h6"
              >
                <Box
                  color=" white "
                  borderRadius="borderRadius"
                  borderColor="secondary.main"
                  {...defaultPropsp}
                >
                  {props.data.problem2}
                </Box>
              </Typography>
            </Grid>
          )}
          {props.data.problem3 && (
            <Grid item xs={12} sm={4}>
              <Typography
                className={classes.typography}
                style={{ textAlign: "center", fontFamily: "Orbitron" }}
                variant="h6"
              >
                <Box
                  color=" white "
                  borderRadius="borderRadius"
                  borderColor="secondary.main"
                  {...defaultPropsp}
                >
                  {props.data.problem3}
                </Box>
              </Typography>
            </Grid>
          )}
        </Grid>

        {!props.data.problem1 && !props.data.problem2 && !props.data.problem3 && (
          <Grid container>
            <Grid item xs={12}>
              <Typography
                className={classes.typography}
                style={{
                  textAlign: "center",
                  fontFamily: "Orbitron",
                  fontSize: 30,
                }}
              >
                No problems found!
              </Typography>
            </Grid>
          </Grid>
        )}
        <br />
      </div>
    </Container>
  );
}
