import React from "react";
import { makeStyles } from "@material-ui/core/styles";
//import image from "../images/bloodhoundnew1.png";
import image from "../images/bloodhound_banner_blood_words_beta.png";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import dog from "../images/dog.png";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),

    color: theme.palette.text.secondary,
  },
  img: {
    left: 0,
    display: "block",
    maxWidth: "25%",
    maxHeight: "25%",
  },
}));

export default function ResponsiveImg() {
  const classes = useStyles();

  return (
    <div>
      <img className={classes.img} alt="complex" src={dog} />
    </div>
  );
}
