const postSchedule = async (email, target, occurence, token) => {
  console.log("THIS IS EMAIL: " + email);
  console.log("THIS IS hostname: " + target);
  console.log("THIS IS occurance: " + occurence);
  console.log("THIS IS Token: " + token);

  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/schedule`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        email: `${email}`,
        target: `${target}`,
        occurance: `${occurence}`,
      }),
    }
  );

  const responseData = await response.json();
  console.log(responseData);
  return responseData;
};
export default postSchedule;
