import React from "react";
import { Button } from "@material-ui/core";
import mixpanel from "mixpanel-browser";
import { useAuth0 } from "../../../react-auth0-spa";
import ReactGA from "react-ga";

export default function IgnoreButton(props) {
  const { isAuthenticated, user } = useAuth0();

  return (
    <Button
      variant="outlined"
      size="small"
      fullWidth
      color="secondary"
      onClick={() => {
        mixpanel.track(
          `${user.email} Clicked on Ignore for ${props.description}`
        );
        ReactGA.event({
          category: `Solutions`,
          action: `Clicked on IGNORE for ${props.description}`,
        });
      }}
      style={{
        color: "#ad2007",
      }}
    >
      {props.ignore}
    </Button>
  );
}
