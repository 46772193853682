import React, {Component} from 'react';


export default class HubspotForm extends Component {
    componentDidMount() {
        const script = document.createElement('script')
        script.id = 'hs-script-loader'
        script.src = '//js.hs-scripts.com/4074824.js'
        document.body.appendChild(script);
    }

    render() {
        return (
            <div>

            </div>
      );
    }
}
