import React from "react";
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";
import { Typography, Grid, Container, Paper, Button } from "@material-ui/core/";
import CheckIcon from "@material-ui/icons/Check";
import RecentTestData from "./utils/RecentTestData.json";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  propStyle: {
    textAlign: "left",

    padding: theme.spacing(1),
    color: "black",
    display: "inline-block",
  },
  murlStyle: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontFamily: "Courier New",
    fontSize: 15,
    color: "#ad2007",
    textAlign: "left",
    display: "inline-block",
  },
  typography: {
    fontFamily: "Orbitron",
    paddingTop: 5,
    color: theme.palette.text.primary,
    fontSize: 20,
    textAlign: "left",
  },
  paperTitle: {
    padding: theme.spacing(1),
    textAlign: "left",

    color: theme.palette.text.primary,
  },
}));

export default function RecentChanges() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper className={classes.paperTitle}>
        <Typography className={classes.typography}>
          RECENT CHANGES TO APPLICATIONS
        </Typography>
        <br />
        {RecentTestData.map((data) => (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography className={classes.propStyle}>
                {data.problem}
                <Typography className={classes.murlStyle}>
                  ({data.murl})
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        ))}
        <Typography style={{ textAlign: "center" }}>
          <Button
            style={{ color: "#ad2007", backgroundColor: "none" }}
            endIcon={<ArrowRightAltIcon style={{ fontSize: 40 }} />}
          >
            SEE ALL CHANGES
          </Button>
        </Typography>
      </Paper>
    </div>
  );
}
