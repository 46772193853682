import { gql } from "@apollo/client";

const FETCH_SCAN = gql`
  {
    fetchScan(scanreference: "0005077") {
      email
      scanreference
      rooturl
      ProblemScanreference
      Problem {
        problem1
        problem2
      }
      Grade {
        scalabilityscore
        securityscore
      }
      Change {
        NewChange
        murl
        scanreference
      }
      Firewalls {
        firewallrule
        lastscanned
      }
      Domains {
        rooturl
        lastscanned
        subdomains
      }
      Otherdomains {
        rooturl
        lastscanned
        domains
      }
      Dnsrecords {
        rooturl
        lastscanned
        type
        record
      }
      Techstacks {
        murl
        component
        lastscanned
      }
    }
  }
`;
export { FETCH_SCAN };
