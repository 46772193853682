const gradesCompaJson = async (
  scanreference,
  priorscanref,
  email,
  murl,
  token
) => {
  console.log("THIS IS scanref: " + scanreference);
  console.log("THIS IS priorscanref: " + priorscanref);
  console.log("THIS IS email: " + email);
  console.log("THIS IS murl: " + murl);

  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/gradescompjson`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        email: `${email}`,
        scanref: `${scanreference}`,
        priorscanref: `${priorscanref}`,
        murl: `${murl}`,
      }),
    }
  );

  const responseData = await response.json();

  return responseData;
};
export default gradesCompaJson;
