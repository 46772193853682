import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import {
  Typography,
  TextField,
  IconButton,
  Grid,
  Paper,
  Tooltip,
  Container,
} from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function StackPopUp(props) {
  const [open, setOpen] = React.useState(true);
  const [murl, setMurl] = React.useState(false);
  const [hostname, setHostname] = useState({
    value: "",
    error: true,
    errorMsg: "Invalid Name",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.pushData({
      data: false,
      response: "cancel",
    });
    setOpen(false);
  };
  const handleCloseOK = () => {
    props.pushData({
      name: hostname.value,
      data: props.murl,
      response: "ok",
    });
    setOpen(false);
  };
  const handleUrl = (e) => {
    setHostname({
      value: e.target.value,
    });
  };
  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Slide in alert dialog
      </Button> */}

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Added the following murls to the Stack"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <TextField
              required
              style={{ paddingBottom: "25px" }}
              size="small"
              label="Name the Stack "
              id="Name the Stack"
              value={hostname.value}
              onChange={handleUrl}
            />

            {Object.values(props.murl).map((item) => (
              <Typography
                variant="p"
                component="p"
                style={{ fontFamily: "Orbitron" }}
              >
                <strong>{item}</strong>
              </Typography>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOK} color="primary">
            OK
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
