import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import getChangesTimeline from "../functions/getChangesTimeline";

export default function animeCompare() {
  const state = {
    series: [
      {
        data: [0, 0, 1, 1, 1, 1, 0],
      },
      {
        data: [1, 0, 1, 0, 1, 1, 0],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 430,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: -6,
        style: {
          fontSize: "12px",
          colors: ["#fff"],
        },
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: [
          "CSP",
          "HSTS",
          "REDIRECT",
          "FIREWALL",
          "WAP",
          "POLICY",
          "CSP FULL",
        ],
      },
    },
  };

  return (
    <div>
      <div id="chart">
        <Chart
          options={state.options}
          series={state.series}
          type="bar"
          height={430}
        />
      </div>
    </div>
  );
}
