import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, Typography } from "@material-ui/core";
import "react-circular-progressbar/dist/styles.css";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  chartStyle: {
    height: 20,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "transparent",
  },
  paper1: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: 150,
  },

  score: {
    width: 150,
  },
}));

export default function ScoreCard(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={0}>
            <Typography
              style={{ color: "#FFFFFF" }}
              variant="h4"
              component="h4"
            >
              <strong> YOUR QUICK SCAN SCORE: </strong>
            </Typography>

            {/* {props.securityscore < 40 ? (
              <Typography
                style={{
                  color: "red",
                  fontFamily: "Orbitron",
                  display: "inline-block",
                  textAlign: "center",
                }}
                variant="h2"
                component="h2"
              >
                {props.securityscore}
              </Typography>
            ) : (
              <Typography
                style={{
                  color: "#42f542",
                  fontFamily: "Orbitron",
                  display: "inline-block",
                }}
                variant="h2"
                component="h2"
              >
                {props.securityscore}
              </Typography>
            )} */}
            <Typography
              style={{
                color: "#2D383A",
                fontFamily: "Orbitron",
                display: "inline-block",
              }}
              variant="h2"
              component="h2"
            >
              {props.securityscore}
            </Typography>

            <Typography
              style={{
                color: "#2D383A",
                fontFamily: "Orbitron",
                display: "inline-block",
              }}
              variant="h2"
              component="h2"
            >
              /
            </Typography>
            <Typography
              style={{
                color: "#42f542",
                fontFamily: "Orbitron",
                display: "inline-block",
              }}
              variant="h2"
              component="h2"
            >
              100
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
