import React from "react";
import {
  Container,
  Typography,
  Grid,
  Paper,
  AppBar,
  Tabs,
  Tab,
  Box,
  Button,
  Grow,
  Toolbar,
  Popover,
  FormControlLabel,
} from "@material-ui/core";

function AwsRaw() {
  return (
    <Grid container maxWidth="md">
      <Grid item xs={12}>
        <Paper elevation={0}>
          <code>{"<h1> Test </h1> "}</code>
          <br />
          <code>{"  <div className={classes.container}> "}</code> <br />
          <code>{"<Paper elevation={4} className={classes.paper}>"}</code>
          <br />
          <code>{"<Paper elevation={4} className={classes.paper}>"}</code>
          <br />
          <code>{"<Paper elevation={4} className={classes.paper}>"}</code>
          <br />
        </Paper>
      </Grid>
    </Grid>
  );
}

export default AwsRaw;
