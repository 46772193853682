import React from "react";
import { Grid, Paper, Typography, Tooltip } from "@material-ui/core";
import IgnoreButton from "../Solution/IgnoreButton";
import SolutionButton from "../Solution/SolutionButton";
import { makeStyles } from "@material-ui/core/styles";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),

    borderRadius: 5,
    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.02)",
    },
  },
  actionArea: {
    borderRadius: 16,
    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.02)",
    },
  },
}));

export default function GridSolution(props) {
  const classes = useStyles();

  return (
    <div>
      {props.description && (
        <div>
          <Paper elevation={1} className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item md={1} align="left">
                <Tooltip title={`Severity is ${props.severity}`}>
                  <ErrorOutlineOutlinedIcon style={{ color: props.shade }} />
                </Tooltip>
              </Grid>
              <Grid item md={7} align="center">
                <Typography
                  style={{ fontFamily: "courier" }}
                  variant="p"
                  component="p"
                >
                  {props.description}
                </Typography>
              </Grid>

              <Grid item md={2} align="right">
                <SolutionButton
                  fix={props.fix}
                  description={props.description}
                />
              </Grid>
              <Grid item md={2} align="right">
                <IgnoreButton
                  ignore={props.ignore}
                  description={props.description}
                />
              </Grid>
            </Grid>
          </Paper>
          <br />
        </div>
      )}
    </div>
  );
}
