import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import image from "../images/bloodhound_banner_blood_words_beta.png";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  img: {
    maxWidth: "100%",
    maxHeight: "100%",
    marginTop: 0,
  },
}));

export default function ResponsiveImg() {
  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ButtonBase className={classes.image}>
            <img className={classes.img} alt="complex" src={image} />
          </ButtonBase>
        </Grid>
      </Grid>
    </div>
  );
}
