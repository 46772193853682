import React, { useEffect, useState } from "react";
import { Typography, TextField } from "@material-ui/core";
import Chart from "react-apexcharts";
import getChangesTimeline from "../functions/getChangesTimeline";
import { useAuth0 } from "../react-auth0-spa";
import getTargets from "../functions/getTargets";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SecLine from "../components/Charts/Line/SecLine";

function SecTimeline() {
  const { isAuthenticated, user, getTokenSilently } = useAuth0();
  const [display, setDisplay] = useState(false);
  const [chart, setChart] = useState(false);
  const [murl, setMurl] = useState(false);
  const [chartData, setChartData] = useState({
    csp: [],
    hsts: [],
    redirect: [],
    scalability: [],
    firewall: [],
    waf: [],
    time: [],
    policy: [],
  });
  const [targets, setTargets] = useState(false);

  useEffect(() => {
    getTargetsApi();
  }, []);

  async function getChangesAll(murl) {
    setChart(false);
    setChartData(false);
    const token = await getTokenSilently();
    const getChangesRes = await getChangesTimeline(
      murl,
      `${user.email}`,
      token
    );
    console.log(getChangesRes);
    CreateChartData(getChangesRes);
  }
  async function getTargetsApi() {
    console.log("Hello from targets");
    const token = await getTokenSilently();
    const getTargetsRes = await getTargets(user.email, token);
    setTargets(getTargetsRes);
    console.log(getTargetsRes);
  }
  function CreateChartData(data) {
    setChartData(false);
    setChartData({
      csp: [],
      hsts: [],
      redirect: [],
      scalability: [],
      firewall: [],
      waf: [],
      time: [],
      policy: [],
    });
    console.log(data);
    let csp = [];
    let hsts = [];
    let redirect = [];
    let scalability = [];
    let firewall = [];
    let waf = [];
    let time = [];
    let policy = [];
    Object.values(data).map((item) => {
      hsts.push(item.hsts);

      if (item.cspdatafull !== "0") {
        csp.push(2);
      } else {
        csp.push(0);
      }

      if (item.https_redirect) {
        redirect.push(3);
      } else {
        redirect.push(0);
      }

      if (item.loadbalanced) {
        scalability.push(4);
      } else {
        scalability.push(0);
      }

      if (item.waf) {
        waf.push(5);
      } else {
        waf.push(0);
      }

      if (!item.port22 && !item.port3389 && !item.port3306) {
        firewall.push(6);
      } else {
        firewall.push(0);
      }
      if (item.referrerpolicy) {
        policy.push(7);
      } else {
        policy.push(0);
      }

      time.push(item.scannedtime.substring(0, item.scannedtime.length - 14));
      console.log(item);
    });
    setChartData({
      csp: csp,
      hsts: hsts,
      redirect: redirect,
      scalability: scalability,
      waf: waf,
      policy: policy,
      firewall: firewall,
      time: time,
    });

    setChart(true);
  }

  const handleTargetValue = (event, value, reason) => {
    setChart(false);
    setChartData(false);
    setMurl(value);

    getChangesAll(value);
  };
  return (
    <div>
      <Autocomplete
        freeSolo
        onChange={handleTargetValue}
        options={
          targets && Object.values(targets).map((option) => option.target)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select or Search"
            margin="normal"
            helperText="Please select a Target"
          />
        )}
      />

      {/* <TextField
        fullWidth
        select
        label="Target"
        onChange={handleTargetValue}
        value={targetValue}
      >
        {targets &&
          Object.values(targets).map((option) => (
            <option key={option.murl} value={option.murl}>
              {option.murl}
            </option>
          ))}
      </TextField> */}
      <br />
      <br />
      {/* <Typography>{JSON.stringify(chartData.csp)}</Typography>
      <Typography>{JSON.stringify(chartData.hsts)}</Typography>
      <Typography>{JSON.stringify(chartData.redirect)}</Typography>
      <Typography>{JSON.stringify(chartData.scalability)}</Typography>
      <Typography>{JSON.stringify(chartData.waf)}</Typography>
      <Typography>{JSON.stringify(chartData.time)}</Typography> */}
      <br />
      <br />
      {/* {JSON.stringify(chartData)} */}
      {chartData ? (
        <SecLine chartData={chartData} murl={murl} />
      ) : (
        <SecLine chartData="" murl="" />
      )}
      {/* {chart ? (
        <Chart
          options={state.options}
          series={state.series}
          type="line"
          height={750}
        />
      ) : (
        <Chart options={{}} series={[]} type="bar" height={750} />
      )} */}
    </div>
  );
}

export default SecTimeline;
