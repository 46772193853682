import React from "react";
import { Paper, Grid, Typography, Button, Container } from "@material-ui/core/";
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";

import ResponsiveDog from "../components/reponsivedog1";
import ResponsiveImg from "../components/ResponsiveImg";

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: "center",
  },
  container: {},

  title: {
    flexGrow: 1,
  },
}));
function NewAbout() {
  const classes = useStyles();
  return (
    <Container className={classes.container} maxWidth="lg">
      <Grid container>
        <Grid item xs={12}>
          <ResponsiveImg />
        </Grid>
      </Grid>

      <div className={classes.root}>
        <Grid
          container
          spacing={1}
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h6" component="h6">
                  <strong>Identify WAF test</strong>
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h6" component="h6">
                  <strong> Identify CMS test</strong>
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h6" component="h6">
                  <strong> Location check</strong>
                </Typography>
              </Paper>
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h6" component="h6">
                  <strong> HTTPS redirection test</strong>
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h6" component="h6">
                  <strong> Firewall test</strong>
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h6" component="h6">
                  <strong> Load balancer test</strong>
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h6" component="h6">
                  <strong>CDN test</strong>
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h6" component="h6">
                  <strong> Tarpit test</strong>
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h6" component="h6">
                  <strong> Cloud & ISP Indentification</strong>
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h6" component="h6">
                  <strong>Identify operating system</strong>
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h6" component="h6">
                  <strong> Identify webserver</strong>
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h6" component="h6">
                  <strong> Identify CMS</strong>
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h6" component="h6">
                  <strong>Identify application server</strong>
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h6" component="h6">
                  <strong>Identify reverse proxy</strong>
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h6" component="h6">
                  <strong> Identify cache technology</strong>
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h6" component="h6">
                  <strong> SSL basic test</strong>
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h6" component="h6">
                  <strong> HSTS check</strong>
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h6" component="h6">
                  <strong> CSP check</strong>
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h6" component="h6">
                  <strong> Referrer policy check</strong>
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h6" component="h6">
                  <strong> Check for insecure content or pages</strong>
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

export default NewAbout;
