// src/components/Profile.js

import React, { Fragment, useState } from "react";
import { useAuth0 } from "../react-auth0-spa";
import { Button, Paper, Typography, Grid, ButtonBase } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import PersonIcon from "@material-ui/icons/Person";
import EmailIcon from "@material-ui/icons/Email";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 500,
  },
  input: {
    display: "none",
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const Profile = () => {
  const [selectedFile, setSelectedFile] = useState(false);
  const classes = useStyles();
  const [userData, setUserData] = useState();

  const { loading, user, logout } = useAuth0();

  if (loading || !user) {
    return <div>Loading...</div>;
  }
  const callApiFull = (user) => {
    setUserData(user);
    console.log(userData);
  };

  const handleUploadClick = (event) => {
    const file = event.target.files[0];
    const source = URL.createObjectURL(file);
    console.log(source);
    setSelectedFile(source);
  };

  const verified = JSON.stringify(user.email_verified);

  return (
    <Fragment>
      <div className={classes.root}>
        <br />
        <br />

        <input
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          multiple
          type="file"
          onChange={handleUploadClick}
        />
        <br />
        <br />

        <br />
        <Paper elevation={4} className={classes.paper}>
          <Typography
            style={{ color: "black", textAlign: "center" }}
            variant="h4"
            component="h4"
          >
            <strong>Profile</strong>
          </Typography>
          <br />
          <br />

          <Grid container spacing={2}>
            <Grid item>
              <Avatar
                alt="Profile"
                src={user.picture}
                className={classes.large}
              />
              {/* <img className ={classes.img} src={user.picture}   alt="Profile" /> */}

              {/* <label htmlFor="contained-button-file">
  
        <Button 
        size ="medium" 
        style={{marginLeft: 2.5}}
        variant="contained"
        color="default"
        component="span"
        startIcon={<PhotoCamera />}
       
      >
        Upload
      </Button>
      </label>
       */}

              <br />
            </Grid>

            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1">
                    <PersonIcon /> {user.name}
                  </Typography>
                  <Typography gutterBottom variant="subtitle1">
                    <EmailIcon /> {user.email}
                  </Typography>
                  <Typography gutterBottom variant="subtitle1">
                    <strong> Verified: </strong>
                    {verified === "true" ? (
                      <CheckIcon style={{ color: "#00FF00", fontSize: 30 }} />
                    ) : (
                      <ClearIcon style={{ color: "#FF1A1A", fontSize: 30 }} />
                    )}
                  </Typography>
                </Grid>

                <Grid item xs>
                  <Button
                    onClick={() => logout()}
                    variant="body2"
                    style={{ alignItems: "right", cursor: "pointer" }}
                  >
                    Logout
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </div>

      <br />
      {/*
          <Grid container spacing={3}>
        <Grid item xs={6}>
       
       
        </Grid>
       
       

        
 
          <br /><br />
    <Button onClick={callApiFull}> PRESS ME</Button>
     
      <h2>{user.name}</h2>
      <p>{user.email}</p>
      <br />
    <p>{user['http://localhost:3000/is_new'].toString()}</p>
      <p>{user.logins_count} aaa </p>
      <p>{userData}</p>
      

     <code>{JSON.stringify(user,null,2)}</code>
     </Grid>
          */}
      <br />
      <br />
      <br />
      <br />
    </Fragment>
  );
};

export default Profile;
