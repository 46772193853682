import React, { useEffect, useState } from "react";
import { Typography, TextField, Button, Grid, Paper } from "@material-ui/core";

import {
  makeStyles,
  withStyles,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";

const theme = createMuiTheme();

theme.table = {
  maxWidth: 1000,
  "@media (min-width:600px)": {
    maxWidth: 500,
  },
  [theme.breakpoints.up("md")]: {
    maxWidth: 1000,
  },
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common,
  },
  body: {},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  typography: {
    color: "#ad2007",
    fontFamily: "Orbitron",
  },
  score: {
    fontSize: 25,

    alignContent: "center",
    fontFamily: "Orbitron",
  },
  table: {
    minWidth: 300,
  },
  target: {
    fontFamily: "Orbitron",
    fontSize: 25,
  },
}));

const data = [
  {
    label: "HSTS",
  },
  {
    label: "CSP",
  },
  {
    label: "REDIRECT",
  },
  {
    label: "SCALABILITY",
  },
  {
    label: "WAF",
  },
  {
    label: "FIREWALL",
  },
  {
    label: "POLICY",
  },
  {
    label: "SOVEREIGNITY",
  },
  {
    label: "SSLISSUE",
  },
];

function GridCompare(props) {
  const classes = useStyles();
  const theme = createMuiTheme();
  return (
    <div>
      <TableContainer>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Typography className={classes.typography} variant="h7">
                  Target
                </Typography>
              </StyledTableCell>
              {data.map((item) => (
                <StyledTableCell align="center">
                  <Typography className={classes.typography} variant="h7">
                    {item.label}
                  </Typography>
                </StyledTableCell>
              ))}

              <StyledTableCell align="center">
                <Typography className={classes.typography} variant="h7">
                  SECURITY SCORE
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="center" className={classes.typography}>
                SCALABILITY SCORE
              </StyledTableCell>
              <StyledTableCell align="center" className={classes.typography}>
                <Typography>FINAL SCORE</Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(props.data).map((option) => (
              <StyledTableRow key={option.name}>
                <StyledTableCell component="th" scope="row">
                  <Typography className={classes.target} variant="h7">
                    {option.name}
                  </Typography>
                </StyledTableCell>

                {Object.values(option.data).map((item) => (
                  <>
                    <StyledTableCell align="center">
                      {item ? (
                        <CheckCircleTwoToneIcon
                          style={{ color: "#00FF00", fontSize: 40 }}
                        />
                      ) : (
                        <CancelTwoToneIcon
                          style={{ color: "#FF1A1A", fontSize: 40 }}
                        />
                      )}
                    </StyledTableCell>
                  </>
                ))}
                <StyledTableCell align="center">
                  <Typography className={classes.score}>
                    {option.securityscore}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography className={classes.score}>
                    {option.scalabilityscore}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography className={classes.score}>
                    {option.finalscore}
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default GridCompare;
