import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, Typography } from "@material-ui/core";
import Gauge from "./Gauge/DashboardGauge";
import NewGauge from "./Gauge/NewGauge";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import ProblemTestData from "./utils/ProblemTestData.json";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "transparent",
    fontFamily: "Orbitron",
    fontSize: 20,
  },
  paperTitle: {
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.primary,
  },
  typography: {
    fontFamily: "Orbitron",
    fontSize: 20,
  },
}));

export const DashScore = ({ grades }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paperTitle}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.typography}>SCORE</Typography>
          </Grid>
          {ProblemTestData.map((data) => (
            <Grid item xs={6}>
              <Gauge score={data.score} name={data.name} />
            </Grid>
          ))}
        </Grid>
      </Paper>
    </div>
  );
};
