import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Gauge from "../../src/components/Charts/Gauge/Gauge";
import {
  DialogActions,
  TextField,
  Grid,
  Select,
  Typography,
} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import postTaggingDB from "../functions/postTaggingDB";
import { useAuth0 } from "../react-auth0-spa";
import gradesCompJson from "../functions/gradesCompaJson";

export default function GradeChangePopUp(props) {
  const { isAuthenticated, user, getTokenSilently } = useAuth0();
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: "25ch",
      },
    },
  }));
  const handleSubmit = async () => {
    // const token = await getTokenSilently();
    // const responseCompPrior = await gradesCompJson(props.priorscanref, token);
    // console.log(responseCompPrior);
    // const responseComp = await gradesCompJson(
    //   localStorage.getItem("scanref"),
    //   token
    // );
    // console.log(responseComp);
    // const difference = jsonEqual(responseCompPrior, responseComp);
    // console.log(difference);
    // function jsonEqual(obj1, obj2) {
    //   const result = {};
    //   if (Object.is(obj1, obj2)) {
    //     return undefined;
    //   }
    //   if (!obj2 || typeof obj2 !== "object") {
    //     return obj2;
    //   }
    //   Object.keys(obj1 || {})
    //     .concat(Object.keys(obj2 || {}))
    //     .forEach((key) => {
    //       if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
    //         result[key] = obj2[key];
    //       }
    //       if (typeof obj2[key] === "object" && typeof obj1[key] === "object") {
    //         const value = jsonEqual(obj1[key], obj2[key]);
    //         if (value !== undefined) {
    //           result[key] = value;
    //         }
    //       }
    //     });
    //   return result;
    // }
  };

  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography style={{ fontSize: 30, textAlign: "center" }}>
            It look's like your score has improved
          </Typography>
          <br />
          <Grid container>
            <Grid item xs={6}>
              <Typography
                style={{
                  color: "red",
                  fontFamily: "Orbitron",
                  textAlign: "center",
                  fontSize: 35,
                }}
              >
                Old Score
                <Gauge data={props.priorscore} />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{
                  color: "green",
                  textAlign: "center",
                  fontFamily: "Orbitron",
                  fontSize: 35,
                }}
              >
                New Score
                <Gauge data={props.finalscore} />
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: "inherit" }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
