import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, Typography, Button, Container } from "@material-ui/core/";
import Logoimg from "../components/logoimg";

import blood from "../images/bloodhound.png";
import securestack from "../images/securestack.png";
import ResponsiveImg from "../components/ResponsiveImg";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    width: "99.91%",
    display: "flex-centered",
    marginTop: 50,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function About() {
  const classes = useStyles();

  function FormRow() {
    return (
      <React.Fragment>
        <Grid item xs={4}>
          <Paper className={classes.paper}>item</Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>item</Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>item</Paper>
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <Container className={classes.container} maxWidth="lg">
      <Paper elevation={0} className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <ResponsiveImg />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper elevation={0} className={classes.paper}>
              <Typography
                style={{ color: "Black" }}
                variant="h4"
                component="h4"
              >
                <br />
                <strong>
                  Our quick scan implements the following scans and checks
                </strong>
              </Typography>
            </Paper>
          </Grid>
        </Grid>
        <br />

        <div className={classes.root}>
          <Grid
            container
            spacing={1}
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={4}>
                <Paper elevation={0} className={classes.paper}>
                  <Typography variant="h6" component="h6">
                    <strong>Identify WAF test</strong>
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper elevation={0} className={classes.paper}>
                  <Typography variant="h6" component="h6">
                    <strong> Identify CMS test</strong>
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper elevation={0} className={classes.paper}>
                  <Typography variant="h6" component="h6">
                    <strong> Location check</strong>
                  </Typography>
                </Paper>
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={3}>
              <Grid item xs={4}>
                <Paper elevation={0} className={classes.paper}>
                  <Typography variant="h6" component="h6">
                    <strong> HTTPS redirection test</strong>
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper elevation={0} className={classes.paper}>
                  <Typography variant="h6" component="h6">
                    <strong> Firewall test</strong>
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper elevation={0} className={classes.paper}>
                  <Typography variant="h6" component="h6">
                    <strong> Load balancer test</strong>
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={4}>
                <Paper elevation={0} className={classes.paper}>
                  <Typography variant="h6" component="h6">
                    <strong>CDN test</strong>
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper elevation={0} className={classes.paper}>
                  <Typography variant="h6" component="h6">
                    <strong> Tarpit test</strong>
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper elevation={0} className={classes.paper}>
                  <Typography variant="h6" component="h6">
                    <strong> Cloud & ISP Indentification</strong>
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={4}>
                <Paper elevation={0} className={classes.paper}>
                  <Typography variant="h6" component="h6">
                    <strong>Identify operating system</strong>
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper elevation={0} className={classes.paper}>
                  <Typography variant="h6" component="h6">
                    <strong> Identify webserver</strong>
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper elevation={0} className={classes.paper}>
                  <Typography variant="h6" component="h6">
                    <strong> Identify CMS</strong>
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={4}>
                <Paper elevation={0} className={classes.paper}>
                  <Typography variant="h6" component="h6">
                    <strong>Identify application server</strong>
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper elevation={0} className={classes.paper}>
                  <Typography variant="h6" component="h6">
                    <strong>Identify reverse proxy</strong>
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper elevation={0} className={classes.paper}>
                  <Typography variant="h6" component="h6">
                    <strong> Identify cache technology</strong>
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={4}>
                <Paper elevation={0} className={classes.paper}>
                  <Typography variant="h6" component="h6">
                    <strong> SSL basic test</strong>
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper elevation={0} className={classes.paper}>
                  <Typography variant="h6" component="h6">
                    <strong> HSTS check</strong>
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper elevation={0} className={classes.paper}>
                  <Typography variant="h6" component="h6">
                    <strong> CSP check</strong>
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={4}>
                <Paper elevation={0} className={classes.paper}>
                  <Typography variant="h6" component="h6">
                    <strong> Referrer policy check</strong>
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper elevation={0} className={classes.paper}>
                  <Typography variant="h6" component="h6">
                    <strong> Check for insecure content or pages</strong>
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Container>
  );
}
//   <div className={classes.root}>
//       <Grid container spacing={1}>
//         <Grid container item xs={12} spacing={3}>
//           <FormRow />
//         </Grid>
//         <Grid container item xs={12} spacing={3}>
//           <FormRow />
//         </Grid>
//         <Grid container item xs={12} spacing={3}>
//           <FormRow />
//         </Grid>
//       </Grid>
