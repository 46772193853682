const getTopScore = async (token) => {
  console.log("THIS IS token: " + token);
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/topscore`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    }
  );
  const responseData = await response.json();
  responseData.sort(function (a, b) {
    return b.securityscore - a.securityscore;
  });
  return responseData;
};
export default getTopScore;
