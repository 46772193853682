import React, {Component} from 'react';
import PropTypes from 'prop-types';

import SwaggerUi, {presets} from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css';
import { Grid } from "@material-ui/core";


class SwaggerUI extends Component {

    componentDidMount() {
        SwaggerUi({
            dom_id: '#swaggerContainer',
            url: this.props.url,
            spec: this.props.spec,
            presets: [presets.apis]
        });
    }

    render() {
        return (
            <Grid container style={{ backgroundColor: "#FFFFFF", padding: 15 }}>
                <Grid item xs={12}>
                    <div id="swaggerContainer" />
                </Grid>
            </Grid>
        );
    }
}

SwaggerUI.propTypes = {
    url: PropTypes.string,
    spec: PropTypes.object
};

SwaggerUI.defaultProps = {
    url: `https://swagger-api-bloodhound.s3-ap-southeast-2.amazonaws.com/bloodhound-api.json`
};

export default SwaggerUI;