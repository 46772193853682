import React from "react";
import { Typography, Grid, Container, Paper, Button } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),

    borderRadius: 5,
    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.02)",
    },
  },
  actionArea: {
    borderRadius: 16,
    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.02)",
    },
  },
}));

export default function Improvement() {
  const classes = useStyles();
  return (
    <div>
      <Paper elevation={1} className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item md={1} align="left">
            <ErrorOutlineOutlinedIcon style={{ color: "red" }} />
          </Grid>
          <Grid item md={7} align="center">
            <Typography
              style={{ fontFamily: "courier" }}
              variant="p"
              component="p"
            >
              Does not automatically send your customers to the secure version
              of your APP
            </Typography>
          </Grid>

          <Grid item md={2} align="right">
            <Button variant="outlined" style={{ color: "blue" }}>
              Solutions
            </Button>
          </Grid>
          <Grid item md={2} align="right">
            <Button variant="outlined" style={{ color: "red" }}>
              {" "}
              Fix
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={1} className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item md={1} align="left">
            <ErrorOutlineOutlinedIcon style={{ color: "blue" }} />
          </Grid>
          <Grid item md={7} align="center">
            <Typography
              style={{ fontFamily: "courier" }}
              variant="p"
              component="p"
            >
              Does not implement security headers into web server environment
              for CSP
            </Typography>
          </Grid>

          <Grid item md={2} align="right">
            <Button variant="outlined" style={{ color: "blue" }}>
              {" "}
              Solutions
            </Button>
          </Grid>
          <Grid item md={2} align="right">
            <Button variant="outlined" style={{ color: "red" }}>
              Fix
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={1} className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item md={1} align="left">
            <ErrorOutlineOutlinedIcon style={{ color: "orange" }} />
          </Grid>
          <Grid item md={7} align="center">
            <Typography
              style={{ fontFamily: "courier" }}
              variant="p"
              component="p"
            >
              Does not implement security headers into web server environment
              for HSTS
            </Typography>
          </Grid>

          <Grid item md={2} align="right">
            <Button variant="outlined" style={{ color: "blue" }}>
              {" "}
              Solutions
            </Button>
          </Grid>
          <Grid item md={2} align="right">
            <Button variant="outlined" style={{ color: "red" }}>
              {" "}
              Fix
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
