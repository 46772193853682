import React from "react";
import { Button, Grid, Typography, Paper, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { DialogContent, ButtonBase } from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { AiOutlineSecurityScan } from "react-icons/ai";
import updateCountDB from "../functions/updateCountDB";
import { useAuth0 } from "../react-auth0-spa";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  typo: {
    fontFamily: "Orbitron",
  },
  button: {
    backgroundColor: "#ad2007",
    textTransform: "none",
    textAlign: "center",
  },
}));

export default function PayDialog(props) {
  const [open, setOpen] = React.useState(true);
  const { isAuthenticated, user, getTokenSilently } = useAuth0();
  const [success, setSuccess] = React.useState(false);

  const handleClick = () => {
    setSuccess(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(function () {
      window.location.reload(true);
    }, 900);
  };

  async function countClick10() {
    const token = await getTokenSilently();
    const updateResponse = await updateCountDB(user.email, "$10", token);
    console.log(updateResponse);
    setSuccess(true);
  }
  async function countClick25() {
    const token = await getTokenSilently();
    const updateResponse = await updateCountDB(user.email, "$25", token);
    console.log(updateResponse);
    setSuccess(true);
  }
  async function countClick50() {
    const token = await getTokenSilently();
    const updateResponse = await updateCountDB(user.email, "$50", token);
    console.log(updateResponse);
    setSuccess(true);
  }
  const handleSucessClose = () => {
    setSuccess(false);
  };
  const classes = useStyles();
  return (
    <div>
      <Snackbar open={success} autoHideDuration={500} onClose={handleClose}>
        <Alert onClose={handleSucessClose} severity="success">
          Sucessfuly added scans!
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {
            "During our BETA all real-time deepscans are free, but in the future we will be selling buckets of scans for a monthly subscription.  So, we would like to ask you, the user, what you think that should cost:"
          }
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <Button
                  style={{ backgroundColor: "#ad2007", textTransform: "none" }}
                  onClick={countClick10}
                  variant="contained"
                >
                  <Typography className={classes.typo}>
                    25 deepscans <br /> $10pm
                  </Typography>
                </Button>
              </Paper>
            </Grid>

            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <Typography>
                  <Button
                    style={{
                      backgroundColor: "#FFA500",
                      textTransform: "none",
                    }}
                    onClick={countClick25}
                    variant="contained"
                  >
                    <Typography className={classes.typo}>
                      100 deepscans <br /> $25pm
                    </Typography>
                  </Button>
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}>
                <Button
                  style={{ backgroundColor: "green", textTransform: "none" }}
                  onClick={countClick50}
                  variant="contained"
                >
                  <Typography className={classes.typo}>
                    Unlimited deepscans <br /> $50pm
                  </Typography>
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}
