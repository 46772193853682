import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, Typography, Box } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { ProgressBar } from "react-bootstrap";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: "center",
  },
  typo: {
    color: "black",
  },
  typo1: {
    color: "grey",
  },
}));

export default function ScoreCard(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography style={{ textAlign: "center" }} variant="h4" component="h4">
        <strong> SCORECARD FOR {props.data.murl}</strong>
      </Typography>
      <br />
      <br />
      <Grid container spacing={3}>
        <Grid item xs>
          <Paper elevation={0} className={classes.paper}>
            <Typography variant="h5" component="h5">
              <strong> WHAT WAS TESTED:</strong>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper elevation={0} className={classes.paper}>
            <Typography variant="h5" component="h5">
              <strong> ARE YOU PROTECTED?</strong>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper elevation={0} className={classes.paper}>
            <Typography variant="h5" component="h5">
              <strong> % OF SIMILAR COMPANIES USING THIS CONTROL</strong>
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Tooltip title="Target supports SSL/TLS and redirects insecure traffic to secure site">
            <Paper elevation={0} className={classes.paper}>
              <Typography
                style={{ fontFamily: "Orbitron" }}
                variant="h6"
                component="h6"
              >
                <strong>Supports Encryption</strong>
              </Typography>
            </Paper>
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <Paper elevation={0} className={classes.paper}>
            {props.data.https_redirect ? (
              <CheckIcon style={{ color: "#00FF00", fontSize: 50 }} />
            ) : (
              <ClearIcon style={{ color: "#FF1A1A", fontSize: 50 }} />
            )}
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper elevation={0} className={classes.paper}>
            <Tooltip
              title="76% of similar companies  automatically send
          customers to the secure version of the APP"
            >
              <ProgressBar
                style={{ height: 25 }}
                variant="success"
                animated
                now={76}
                label="76% "
              />
            </Tooltip>
          </Paper>
        </Grid>

        <Grid item xs={4}>
          <Tooltip title="Supports HSTS and there are no insecure images or content in the target">
            <Paper elevation={0} className={classes.paper}>
              <Typography
                style={{ fontFamily: "Orbitron" }}
                variant="h6"
                component="h6"
              >
                <strong> All Traffic and Content is Encrypted</strong>
              </Typography>
            </Paper>
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <Paper elevation={0} className={classes.paper}>
            {props.data.hsts ? (
              <CheckIcon style={{ color: "#00FF00", fontSize: 50 }} />
            ) : (
              <ClearIcon style={{ color: "#FF1A1A", fontSize: 50 }} />
            )}
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Tooltip
            title={`14% of companies similar to ${props.data.murl} use HSTS`}
          >
            <Paper elevation={0} className={classes.paper}>
              <ProgressBar
                style={{ height: 25 }}
                variant="info"
                label="28% "
                animated
                now={14}
              />
            </Paper>
          </Tooltip>
        </Grid>

        <Grid item xs={4}>
          <Tooltip title="Target supports elasticity, caching, load balancing and/or CDN">
            <Paper elevation={0} className={classes.paper}>
              <Typography
                style={{ fontFamily: "Orbitron" }}
                variant="h6"
                component="h6"
              >
                <strong> Overall Scalability</strong>
              </Typography>
            </Paper>
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <Paper elevation={0} className={classes.paper}>
            {props.data.loadbalanced ? (
              <CheckIcon style={{ color: "#00FF00", fontSize: 50 }} />
            ) : (
              <ClearIcon style={{ color: "#FF1A1A", fontSize: 50 }} />
            )}
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Tooltip
            title={`18% of similar companies to ${props.data.murl} use load balancing, elasticity, and CDN`}
          >
            <Paper elevation={0} className={classes.paper}>
              <ProgressBar
                style={{ height: 25 }}
                variant="warning"
                label="18% "
                animated
                now={18}
              />
            </Paper>
          </Tooltip>
        </Grid>

        <Grid item xs={4}>
          <Tooltip title="WAF protects your target from attacks and hidden vulnerabilities in your code">
            <Paper elevation={0} className={classes.paper}>
              <Typography
                style={{ fontFamily: "Orbitron" }}
                variant="h6"
                component="h6"
              >
                <strong>Web Application Firewall</strong>
              </Typography>
            </Paper>
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <Paper elevation={0} className={classes.paper}>
            {props.data.waf ? (
              <CheckIcon style={{ color: "#00FF00", fontSize: 50 }} />
            ) : (
              <ClearIcon style={{ color: "#FF1A1A", fontSize: 50 }} />
            )}
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Tooltip
            title={`51% of companies similar to ${props.data.murl} use WAF technologies`}
          >
            <Paper elevation={0} className={classes.paper}>
              <ProgressBar
                style={{ height: 25 }}
                variant="danger"
                label="51% "
                animated
                now={51}
              />
            </Paper>
          </Tooltip>
        </Grid>

        <Grid item xs={4}>
          <Tooltip title="SSH, RDP or Database ports are open">
            <Paper elevation={0} className={classes.paper}>
              <Typography
                style={{ fontFamily: "Orbitron" }}
                variant="h6"
                component="h6"
              >
                <strong>Public Attack Surface</strong>
              </Typography>
            </Paper>
          </Tooltip>
        </Grid>

        <Grid item xs={4}>
          <Paper elevation={0} className={classes.paper}>
            {!props.data.port22 &&
            !props.data.port3389 &&
            !props.data.port3306 ? (
              <CheckIcon style={{ color: "#00FF00", fontSize: 50 }} />
            ) : (
              <ClearIcon style={{ color: "#FF1A1A", fontSize: 50 }} />
            )}
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Tooltip
            title={`65% of companies similar to ${props.data.murl} block SSH,RDP and database ports`}
          >
            <Paper elevation={0} className={classes.paper}>
              <ProgressBar
                style={{ height: 25 }}
                variant="secondary"
                label="65% "
                animated
                now={65}
              />
            </Paper>
          </Tooltip>
        </Grid>

        <Grid item xs={4}>
          <Tooltip title="Target uses security headers to add additional layers of security">
            <Paper elevation={0} className={classes.paper}>
              <Typography
                style={{ fontFamily: "Orbitron" }}
                variant="h6"
                component="h6"
              >
                <strong> Webserver Optimization</strong>
              </Typography>
            </Paper>
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <Paper elevation={0} className={classes.paper}>
            {props.data.cspdatafull === "0" ? (
              <ClearIcon style={{ color: "#FF1A1A", fontSize: 50 }} />
            ) : (
              <CheckIcon style={{ color: "#00FF00", fontSize: 50 }} />
            )}
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Tooltip
            title={`9% of similar companies to ${props.data.murl} implement CSP`}
          >
            <Paper elevation={0} className={classes.paper}>
              <ProgressBar
                style={{ height: 25 }}
                variant="dark"
                label="9% "
                animated
                now={9}
              />
            </Paper>
          </Tooltip>
        </Grid>

        <Grid item xs={4}>
          <Tooltip title="Target has a referrer and a privacy policy">
            <Paper elevation={0} className={classes.paper}>
              <Typography
                style={{ fontFamily: "Orbitron" }}
                variant="h6"
                component="h6"
              >
                <strong> Enforces Privacy</strong>
              </Typography>
            </Paper>
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <Paper elevation={0} className={classes.paper}>
            {console.log(props.data.referrerpolicy)}
            {props.data.referrerpolicy === 0 ? (
              <ClearIcon style={{ color: "#FF1A1A", fontSize: 50 }} />
            ) : (
              <CheckIcon style={{ color: "#00FF00", fontSize: 50 }} />
            )}
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Tooltip
            title={`7% of companies similar to ${props.data.murl} use a referrer policy`}
          >
            <Paper elevation={0} className={classes.paper}>
              <ProgressBar
                style={{ height: 25 }}
                variant="primary"
                label="7% "
                animated
                now={7}
              />
            </Paper>
          </Tooltip>
        </Grid>

        <Grid item xs={4}>
          <Tooltip title="Target enforces data sovereignty">
            <Paper elevation={0} className={classes.paper}>
              <Typography
                style={{ fontFamily: "Orbitron" }}
                variant="h6"
                component="h6"
              >
                <strong>Sovereignty</strong>
              </Typography>
            </Paper>
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <Paper elevation={0} className={classes.paper}>
            {props.data.sovereignissue === 1 &&
            props.data.nocdnsovereignissue === 1 ? (
              <ClearIcon style={{ color: "#FF1A1A", fontSize: 50 }} />
            ) : (
              <CheckIcon style={{ color: "#00FF00", fontSize: 50 }} />
            )}
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Tooltip
            title={`57% of companies similar to ${props.data.murl} address data sovereignty`}
          >
            <Paper elevation={0} className={classes.paper}>
              <ProgressBar
                style={{ height: 25 }}
                variant="success"
                label="57% "
                animated
                now={57}
              />
            </Paper>
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <Tooltip title="Target has unencrypted pages">
            <Paper elevation={0} className={classes.paper}>
              <Typography
                style={{ fontFamily: "Orbitron" }}
                variant="h6"
                component="h6"
              >
                <strong> Insecure Dependencies</strong>
              </Typography>
            </Paper>
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <Paper elevation={0} className={classes.paper}>
            {props.data.numberunencryptedpages === 0 ? (
              <CheckIcon style={{ color: "#00FF00", fontSize: 50 }} />
            ) : (
              <ClearIcon style={{ color: "#FF1A1A", fontSize: 50 }} />
            )}
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper elevation={0} className={classes.paper}>
            <Tooltip
              title={`54% of companies similar to ${props.data.murl} enforce secure dependencies`}
            >
              <ProgressBar
                style={{ height: 25 }}
                variant="info"
                animated
                now={54}
                label="54% "
              />
            </Tooltip>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
