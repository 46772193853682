import React, { useState, useEffect } from "react";
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import { Typography, Grid, Container, Paper, Button } from "@material-ui/core/";
import BuildIcon from "@material-ui/icons/Build";
import Scantotal from "./ScanTotal";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import AssetsnEndpoints from "./AssetsnEndpoints";
import { DashScore } from "./DashScore";
import Summary from "./Summary";
import OverviewProblems from "./OverviewProblems";
import FormSelection from "./FormSelection";
import RecentChanges from "./RecentChanges";
import Architecture from "./Architecture";
import { FETCH_SCAN } from "../../graphql/queries/FetchScanQuery";
import ScoreCard from "./ScoreCard";
import { useQuery, gql } from "@apollo/client";
import SingleMurlGauge from "./Gauge/SingleMurlGauge";
import Improvement from "./Improvement";
const theme = createMuiTheme();

theme.typography.h3 = {
  fontSize: "2.2rem",
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "7rem",
  },
};
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  paperSecondGrid: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    maxHeight: 150,
    borderRadius: 15,
  },
  paperTopGrid: {
    padding: theme.spacing(1),
    textAlign: "right",
    color: theme.palette.text.secondary,
  },
  paperThirdGrid: {
    borderRadius: 15,
  },
  root: {
    paddingTop: 10,
  },
  fixButton: {
    backgroundColor: "#ad2007",
    fontSize: 15,
    padding: "2px 2px 2px 2px",
    color: "white",
  },
  typography: {
    fontFamily: "Orbitron",
    fontSize: 30,
  },
  typographyTitle: {
    fontFamily: "Orbitron",
    fontSize: 20,
    textAlign: "left",
    padding: theme.spacing(1),
    color: theme.palette.text.primary,
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const [problems, setProblems] = useState([]);

  const { loading, error, data } = useQuery(FETCH_SCAN);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="100">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {/* {!loading &&
              console.log(data.fetchScan) &&
              Object.values(data.fetchScan.Problem).map((item) => (
                <h1>{item}</h1>
              ))} */}

            <Summary />
          </Grid>
          <Grid item xs={6}>
            {!loading && console.log(data.fetchScan.Firewalls)}
            {!loading && console.log(data.fetchScan.Problem)}

            <DashScore />
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Architecture />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper className={classes.paper} elevation={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography className={classes.typographyTitle}>
                        SCORE CARD
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography style={{ textAlign: "right" }}>
                        <Button
                          style={{ color: "#ad2007", backgroundColor: "none" }}
                          endIcon={
                            <ArrowRightAltIcon style={{ fontSize: 40 }} />
                          }
                        >
                          SEE SCORE CARD
                        </Button>
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <ScoreCard />
                    </Grid>
                    <Grid item xs={6}>
                      <SingleMurlGauge />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        className={classes.fixButton}
                        variant="contained"
                        endIcon={<BuildIcon />}
                      >
                        Fix
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <RecentChanges />
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography className={classes.typographyTitle}>
                IMPROVEMENTS &nbsp; (test.securestack.com)
              </Typography>
              <Typography style={{ textAlign: "right" }}>
                <Button
                  style={{ color: "#ad2007", backgroundColor: "none" }}
                  endIcon={<ArrowRightAltIcon style={{ fontSize: 40 }} />}
                >
                  SEE ALL IMPROVEMENTS
                </Button>
              </Typography>
              <Improvement />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}

{
  /* <Grid container spacing={2}>
<Grid item xs={6}>
  <Typography className={classes.typography}>Overview</Typography>
</Grid>

<Grid item xs={6}>
  <Paper elevation={0} className={classes.paperTopGrid}>
    <FormSelection />
  </Paper>
</Grid>
<Grid item xs={4}>
  <Paper elevation={4} className={classes.paperSecondGrid}>
    <Scantotal />
  </Paper>
</Grid>

<Grid item xs={4}>
  <Paper elevation={4} className={classes.paperSecondGrid}>
    <AssetsnEndpoints />
  </Paper>
</Grid>
<Grid item xs={4}>
  <Paper elevation={4} className={classes.paperSecondGrid}>
    <DashScore />
  </Paper>
</Grid>
</Grid>
<Grid container spacing={2}>
<Grid item xs={6}>
  <Paper elevation={4} className={classes.paperThirdGrid}>
    <RecentChanges />
  </Paper>
</Grid>
<Grid item xs={6}>
  <Paper elevation={4} className={classes.paperThirdGrid}>
    <OverviewProblems />
  </Paper>
</Grid>
</Grid> */
}
