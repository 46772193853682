const solutionInfoData = {
  CSP: {
    name: "CSP",
    problem: `The web application you've scanned does not include any Content Security Policy (CSP). CSP is an added layer of security that helps to detect and mitigate certain types of attacks, including Cross Site Scripting (XSS) and data injection attacks. These attacks are used for everything from data theft to site defacement to distribution of malware.`,
    info: `Your application is running on a Ec2 instance in AWS using the NGINX web server. Bloodhound will customize a solution for you based on the signature of your application. You can choose to deploy that fix yourself, or have Bloodhound automatically deploy it for you.`,
    videoUrl: `https://www.youtube.com/embed/JbfNWg6JS4U`,
    downloadUrl: `https://jsonplaceholder.typicode.com/todos`,
    code: `add_header Content-Security-Policy "default-src self;`,
  },
  HSTS: {
    name: "HSTS",
    problem: `The web application you've scanned does not use HTTP Strict Transport Security (HSTS). HSTS is a web security policy mechanism that helps to protect websites against man-in-the-middle attacks such as protocol downgrade attacks and cookie hijacking. It allows web servers to declare that web browsers (or other complying user agents) should automatically interact with it using only HTTPS connections, which provide Transport Layer Security (TLS/SSL), unlike the insecure HTTP used alone.`,
    info: `Your application is running on a Ec2 instance in AWS using the NGINX web server. Bloodhound will customize a solution for you based on the signature of your application. You can choose to deploy that fix yourself, or have Bloodhound automatically deploy it for you.`,
    videoUrl: `https://www.youtube.com/embed/kYhMnw4aJTw`,
    downloadUrl: `https://jsonplaceholder.typicode.com/todos`,
    code: `add_header Strict-Transport-Security: max-age=31536000`,
  },
  UNENCRYPTEDPAGES: {
    name: "UNENCRYPTED CONTENT",
    problem: `The web application you've scanned uses unencrypted content links (HTTP)`,
    info: `Your application is running on a Ec2 instance in AWS using the NGINX web server. Bloodhound will customize a solution for you based on the signature of your application. You can choose to deploy that fix yourself, or have Bloodhound automatically deploy it for you.`,
    videoUrl: `https://www.youtube.com/embed/kYhMnw4aJTw`,
    downloadUrl: `https://jsonplaceholder.typicode.com/todos`,
    code: ``,
  },
  REFERRERPOLICY: {
    name: "REFERRER POLICY",
    problem: `The web application you've scanned does not implement a referrer policy. Referrer-Policy is a security header that can (and should) be included on communication from your website's server to a client. The Referrer-Policy tells the web-browser how to handle referrer information that is sent to websites when a user clicks a link that leads to another page or website.`,
    info: `Your application is running on a Ec2 instance in AWS using the NGINX web server. Bloodhound will customize a solution for you based on the signature of your application. You can choose to deploy that fix yourself, or have Bloodhound automatically deploy it for you.`,
    videoUrl: `https://www.youtube.com/embed/naERzIZW_D0`,
    downloadUrl: `https://jsonplaceholder.typicode.com/todos`,
    code: `add_header 'Referrer-Policy' 'origin';`,
  },
  HTTPSREDIRECT: {
    name: "HTTPS REDIRECT",
    problem: `The web application you've scanned does not use HTTP Strict Transport Security (HSTS). HSTS is a web security policy mechanism that helps to protect websites against man-in-the-middle attacks such as protocol downgrade attacks and cookie hijacking. It allows web servers to declare that web browsers (or other complying user agents) should automatically interact with it using only HTTPS connections, which provide Transport Layer Security (TLS/SSL), unlike the insecure HTTP used alone.`,
    info: `Your application is running on a Ec2 instance in AWS using the NGINX web server. Bloodhound will customize a solution for you based on the signature of your application. You can choose to deploy that fix yourself, or have Bloodhound automatically deploy it for you.`,
    videoUrl: `https://www.youtube.com/embed/kYhMnw4aJTw`,
    downloadUrl: `https://jsonplaceholder.typicode.com/todos`,
    code: ``,
  },
  LOADBALANCER: {
    name: "LOAD BALANCER",
    problem: `The web application you've scanned does not use Load Balancing. Load balancing is the process of distributing a workload evenly across multiple servers. A load balancer sends requests to servers that can efficiently handle them to maximize speed and performance and prevent downtime.`,
    info: `Your application is running on a Ec2 instance in AWS using the NGINX web server. Bloodhound will customize a solution for you based on the signature of your application. You can choose to deploy that fix yourself, or have Bloodhound automatically deploy it for you.`,
    videoUrl: `https://www.youtube.com/embed/17AFseaBDgk`,
    downloadUrl: `https://jsonplaceholder.typicode.com/todos`,
    code: ``,
  },
  WAF: {
    name: "WAF",
    problem: `The web application you've scanned does not use WAF. WAF is a specific form of application firewall that filters, monitors, and blocks HTTP traffic to and from a web service`,
    info: `Your application is running on a Ec2 instance in AWS using the NGINX web server. Bloodhound will customize a solution for you based on the signature of your application. You can choose to deploy that fix yourself, or have Bloodhound automatically deploy it for you.`,
    videoUrl: `https://www.youtube.com/embed/5e4BUKeGZNk`,
    downloadUrl: `https://jsonplaceholder.typicode.com/todos`,
    code: ``,
  },
  SSH: {
    name: "SSH",
    problem: `The web application you've scanned has port 22 open (SSH). SSH, or Secure Shell, is a remote administration protocol that allows users to control and modify their remote servers over the Internet.`,
    info: `Your application is running on a Ec2 instance in AWS using the NGINX web server. Bloodhound will customize a solution for you based on the signature of your application. You can choose to deploy that fix yourself, or have Bloodhound automatically deploy it for you.`,
    videoUrl: `https://www.youtube.com/embed/qWKK_PNHnnA`,
    downloadUrl: `https://jsonplaceholder.typicode.com/todos`,
    code: ``,
  },
  WRD: {
    name: "WRD",
    problem: `The web application you've scanned has Windows Remote Desktop open to the public. Microsoft Remote Desktop is a client application that allows you to access and control the resources and data of a remote, or host, computer using an Internet connection.`,
    info: `Your application is running on a Ec2 instance in AWS using the NGINX web server. Bloodhound will customize a solution for you based on the signature of your application. You can choose to deploy that fix yourself, or have Bloodhound automatically deploy it for you.`,
    videoUrl: `https://www.youtube.com/embed/kYhMnw4aJTw`,
    downloadUrl: `https://jsonplaceholder.typicode.com/todos`,
    code: ``,
  },
  DBSERVER: {
    name: "DBSERVER",
    problem: `The web application you've scanned has the database server exposed to the public. A database server is a server which uses a database application that provides database services to other computer programs or to computers, as defined by the client–server model.`,
    info: `Your application is running on a Ec2 instance in AWS using the NGINX web server. Bloodhound will customize a solution for you based on the signature of your application. You can choose to deploy that fix yourself, or have Bloodhound automatically deploy it for you.`,
    videoUrl: `https://www.youtube.com/embed/Fpx_MxdKdWE`,
    downloadUrl: `https://jsonplaceholder.typicode.com/todos`,
    code: ``,
  },
  SSLISSUE: {
    name: "SSL ISSUE",
    problem: `The web application you've scanned has an SSL issue. Secure Sockets Layer (SSL) is a standard security technology for establishing an encrypted link between a server and a client—typically a web server (website) and a browser, or a mail server and a mail client (e.g., Outlook).
    `,
    info: `Your application is running on a Ec2 instance in AWS using the NGINX web server. Bloodhound will customize a solution for you based on the signature of your application. You can choose to deploy that fix yourself, or have Bloodhound automatically deploy it for you.`,
    videoUrl: `https://www.youtube.com/embed/SJJmoDZ3il8`,
    downloadUrl: `https://jsonplaceholder.typicode.com/todos`,
    code: ``,
  },
  WINDOWS_AWS: {
    name: "WINDOWS_AWS",
    problem: `The web application you've scanned does not use HTTP Strict Transport Security (HSTS). HSTS is a web security policy mechanism that helps to protect websites against man-in-the-middle attacks such as protocol downgrade attacks and cookie hijacking. It allows web servers to declare that web browsers (or other complying user agents) should automatically interact with it using only HTTPS connections, which provide Transport Layer Security (TLS/SSL), unlike the insecure HTTP used alone.`,
    info: `Your application is running on a Ec2 instance in AWS using the NGINX web server. Bloodhound will customize a solution for you based on the signature of your application. You can choose to deploy that fix yourself, or have Bloodhound automatically deploy it for you.`,
    videoUrl: `https://www.youtube.com/embed/kYhMnw4aJTw`,
    downloadUrl: `https://jsonplaceholder.typicode.com/todos`,
    code: ``,
  },
  LINUX_AWS: {
    name: "LINUX_AWS",
    problem: `The web application you've scanned does not use HTTP Strict Transport Security (HSTS). HSTS is a web security policy mechanism that helps to protect websites against man-in-the-middle attacks such as protocol downgrade attacks and cookie hijacking. It allows web servers to declare that web browsers (or other complying user agents) should automatically interact with it using only HTTPS connections, which provide Transport Layer Security (TLS/SSL), unlike the insecure HTTP used alone.`,
    info: `Your application is running on a Ec2 instance in AWS using the NGINX web server. Bloodhound will customize a solution for you based on the signature of your application. You can choose to deploy that fix yourself, or have Bloodhound automatically deploy it for you.`,
    videoUrl: `https://www.youtube.com/embed/kYhMnw4aJTw`,
    downloadUrl: `https://jsonplaceholder.typicode.com/todos`,
    code: ``,
  },
};
export default solutionInfoData;
