import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Stepper,
  Grid,
  Paper,
  Container,
  ButtonBase,
  Zoom,
  Popover,
  Grow,
} from "@material-ui/core/";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  stepper: {
    width: "100%",
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  button: {
    marginRight: theme.spacing(1),
  },
}));

export default function StackTimeline(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Container maxWidth="lg">
      <Grid container>
        <Grid item xs={12}>
          <Paper className={classes.paper}>Tech Stack</Paper>
        </Grid>

        <Stepper className={classes.stepper}>
          <StepButton>
            <Typography
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
            >
              {props.data.cloudver ? (
                <>
                  <img
                    className={classes.img}
                    src={`https://bloodhound.securestack.com/images/${props.data.cloudver}.png`}
                    alt={props.data.cloudver}
                  />
                </>
              ) : (
                <></>
              )}
            </Typography>

            <Popover
              id="mouse-over-popover"
              className={classes.popover}
              classes={{
                paper: classes.paper,
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Grow
                direction="left"
                in={true}
                style={{ transitionDelay: "900ms" }}
              >
                <Button> Test</Button>
              </Grow>
              <br />
              <Grow
                direction="left"
                in={true}
                style={{ transitionDelay: "1000ms" }}
              >
                <Button variant="contained" onClick={handlePopoverClose}>
                  Default
                </Button>
              </Grow>
              <br />
              <Grow
                direction="left"
                in={true}
                style={{ transitionDelay: "1100ms" }}
              >
                <Button> Test3</Button>
              </Grow>
            </Popover>

            {props.data.isp ? (
              <Typography
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <img
                  className={classes.img}
                  src={`https://bloodhound.securestack.com/images/${props.data.isp}.png`}
                  alt={props.data.isp}
                />
              </Typography>
            ) : (
              <></>
            )}
          </StepButton>
          <StepButton>
            {props.data.cloudver ? (
              <>
                <img
                  className={classes.img}
                  src={`https://bloodhound.securestack.com/images/${props.data.cloudver}.png`}
                  alt={props.data.cloudver}
                />
              </>
            ) : (
              <></>
            )}
          </StepButton>

          <StepButton>
            {props.data.cmsver ? (
              <img
                className={classes.img}
                src={`https://bloodhound.securestack.com/images/${props.data.cmsver}.png`}
                alt={props.data.cmsver}
              />
            ) : (
              <></>
            )}
          </StepButton>

          <StepButton>
            {props.data.runningon ? (
              <img
                className={classes.img1}
                src={`https://bloodhound.securestack.com/images/${props.data.runningon}.png`}
                alt={props.data.runningon}
              />
            ) : (
              <> </>
            )}
          </StepButton>
          <StepButton>
            {props.data.cdnver ? (
              <img
                className={classes.img}
                src={`https://bloodhound.securestack.com/images/${props.data.cdnver}.png`}
                alt={props.data.cdnver}
              />
            ) : (
              <> </>
            )}
          </StepButton>
          <StepButton>
            {props.data.webserver ? (
              <img
                className={classes.img}
                src={`https://bloodhound.securestack.com/images/${props.data.webserver}.png`}
                alt={props.data.webserver}
              />
            ) : (
              <> </>
            )}
          </StepButton>
          <StepButton>
            {props.data.poweredby ? (
              <img
                className={classes.img}
                src={`https://bloodhound.securestack.com/images/${props.data.poweredby}.png`}
                alt={props.data.poweredby}
              />
            ) : (
              <> </>
            )}
          </StepButton>
          <StepButton>
            {props.data.protectedby ? (
              <img
                className={classes.img}
                src={`https://bloodhound.securestack.com/images/${props.data.protectedby}.png`}
                alt={props.data.protectedby}
              />
            ) : (
              <> </>
            )}
          </StepButton>
        </Stepper>
      </Grid>
    </Container>
  );
}
