const getRecentChanges = async (token, scanreference) => {
  console.log("THIS IS scanref: " + scanreference);
  console.log("THIS IS token: " + token);

  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/recentchanges/${scanreference}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    }
  );
  const responseData = await response.json();
  return responseData[0];
};
export default getRecentChanges;
