import React from "react";
import {
  Typography,
  Grid,
  Paper,
  AppBar,
  Button,
  Grow,
  Toolbar,
  Card,
  CardActionArea,
  CardMedia,
  Tooltip,
  FormControlLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import aws from "../../../images/aws.png";
import nginx from "../../../images/nginx.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import DownloadCode from "../../../functions/DownloadCode";
import { useAuth0 } from "../../../react-auth0-spa";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  typography: {
    fontSize: 18,
    fontFamily: "courier",
  },
  title: {
    fontSize: 30,
    fontFamily: "Orbitron",
  },
  container: {
    display: "flex",
  },
  paper: {
    margin: theme.spacing(1),
    elevation: 0,
  },
}));

function SolutionInfo(props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [raw, setRaw] = React.useState(false);
  const [value, setValue] = React.useState(false);

  const state = {
    value: 'add_header Content-Security-Policy "default-src self;";',
    copied: false,
  };

  const onChange = ({ target: { value } }) => {
    setValue({ value, copied: false });
  };

  const handleCopyClick = ({ target: { innerHTML } }) => {};

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleScriptClick = (e) => {
    e.preventDefault();

    console.log("The link was clicked.");
    DownloadCode(props.data.downloadUrl);
  };

  function handleRawClick() {
    setRaw(true);
  }

  const handleRawClose = () => {
    setRaw(false);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { user } = useAuth0();

  return (
    <Paper elevation={0}>
      <Grid container>
        <Grid item xs={12}>
          <Paper elevation={0} className={classes.paper}>
            <Typography className={classes.title}>
              Problem: {props.data.name}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} className={classes.paper}>
            <Typography className={classes.typography}>
              {props.data.problem}
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <br />
      <Grid container>
        <Grid item xs={12}>
          <Paper elevation={0} className={classes.paper}>
            <Typography className={classes.title}>Info</Typography>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper elevation={0} className={classes.paper}>
            <Typography className={classes.typography}>
              {props.data.info}
            </Typography>
          </Paper>
          <br />
        </Grid>

        <Grid item xs={6}>
          <Paper elevation={0} className={classes.paper}>
            <Card>
              <CardActionArea>
                <Tooltip title={`Solution for ${props.data.name}`}>
                  <CardMedia
                    component="iframe"
                    alt=""
                    height="240"
                    src={props.data.videoUrl}
                  />
                </Tooltip>
              </CardActionArea>
            </Card>
          </Paper>
        </Grid>
      </Grid>
      <div className={classes.root}>
        <AppBar
          elevation={0}
          style={{ backgroundColor: "transparent" }}
          position="static"
        >
          <Toolbar>
            <Typography
              style={{ color: "black" }}
              variant="h6"
              className={classes.title}
            >
              Deploy Solutions:
              <br />
              <br />
            </Typography>
            <Button style={{ marginLeft: 40 }} onClick={handleChange}>
              <Tooltip title="Click for AWS Solution">
                <img src={aws} />
              </Tooltip>
            </Button>
            <Button style={{ marginLeft: 45 }} onClick={handleChange}>
              <Tooltip title="Click for NGINX Solution">
                <img src={nginx} />
              </Tooltip>
            </Button>
          </Toolbar>
        </AppBar>
      </div>

      <div className={classes.root}>
        <div className={classes.container}>
          <Grow in={checked}>
            <Paper elevation={0} className={classes.paper}>
              <Button
                aria-describedby={id}
                variant="contained"
                color="primary"
                onClick={handleScriptClick}
              >
                Download Script
              </Button>
            </Paper>
          </Grow>

          <Grow
            in={checked}
            style={{ transformOrigin: "0 0 0" }}
            {...(checked ? { timeout: 1000 } : {})}
          >
            <Paper elevation={0} className={classes.paper}>
              <Button
                aria-describedby={id}
                variant="contained"
                color="primary"
                onClick={handleRawClick}
                onClose={handleRawClose}
              >
                Raw Code
              </Button>
            </Paper>
          </Grow>
          <Grow
            in={checked}
            style={{ transformOrigin: "0 0 0" }}
            {...(checked ? { timeout: 1000 } : {})}
          >
            <Paper elevation={0} className={classes.paper}>
              <Button
                href={`https://demolab.securestack.com/SignUpBloodhound/${user.email}`}
                aria-describedby={id}
                variant="contained"
                color="primary"
                onClick={handleClick}
              >
                Automate with Securestack
              </Button>
            </Paper>
          </Grow>
        </div>
      </div>
      {raw && (
        <div>
          <Grid container style={{ marginTop: "2%", paddingBottom: "2%" }}>
            <Grid item xs={12}>
              <Paper elevation={0} style={{ textAlign: "center" }}>
                <section className="section">
                  <textarea
                    onChange={onChange}
                    rows={20}
                    cols={60}
                    value={props.data.code}
                  />
                </section>
                <CopyToClipboard
                  onClick={handleCopyClick}
                  text={props.data.code}
                >
                  <button>Copy to clipboard with button</button>
                </CopyToClipboard>
              </Paper>
            </Grid>
          </Grid>
        </div>
      )}
    </Paper>
  );
}

export default SolutionInfo;
