import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import { Typography, Divider, Tooltip } from "@material-ui/core";
import ISP from "../images/internet.png";
import CardComponent from "../components/ServicesCompCard/CardComponent";

import { useFourThreeCardMediaStyles } from "@mui-treasury/styles/cardMedia/fourThree";
import Color from "color";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
    borderRadius: 16,
  },
  typography: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "transparent",
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.2)",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "70%",
      maxHeight: "70%",
    },
  },

  card: ({ color }) => ({
    minWidth: 156,
    borderRadius: 16,
    boxShadow: "none",
    "&:hover": {
      boxShadow: `0 6px 12px 0 ${Color(color)
        .rotate(-12)
        .darken(0.2)
        .fade(0.5)}`,
    },
  }),
}));
const defaultProps = {
  bgcolor: "background.paper",
  m: 1,
  border: 1,
};

export default function ServicesComp(props) {
  const classes = useStyles();
  const { scanData, title } = props;

  return (
    <div className={classes.root}>
      <Grid container spacing={10}>
        <Grid item xs>
          <Paper elevation={0} className={classes.paper}>
            <Typography
              className={classes.typography}
              style={{ fontFamily: "Orbitron" }}
            >{`${title}`}</Typography>
            {title === "ISP" ? (
              <>
                <Typography
                  className={classes.typography}
                  style={{ fontFamily: "Orbitron" }}
                >
                  ({scanData})
                </Typography>
              </>
            ) : (
              <Typography></Typography>
            )}

            <ButtonBase>
              <Tooltip title={scanData}>
                {title === "ISP" ? (
                  <>
                    <img className={classes.img} src={ISP} alt={scanData} />
                  </>
                ) : (
                  <div>
                    <img
                      className={classes.img}
                      src={`https://bloodhound.securestack.com/images/${scanData}.png`}
                      alt={scanData}
                    />
                  </div>
                )}
              </Tooltip>
            </ButtonBase>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
