// src/index.js

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "./react-auth0-spa";
import config from "./auth_config.json";
import history from "./utils/history";
import "bootstrap/dist/css/bootstrap.min.css";
import { ApolloClient, InMemoryCache } from "@apollo/client";

var mixpanel = require("mixpanel-browser");
mixpanel.init("4ac82badb48ff0b8210833e642436ada");

// A function that routes the user to the right place
// after login

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};
const client = new ApolloClient({
  uri: "http://3.104.30.196/graphql",
  cache: new InMemoryCache(),
});
ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    redirect_uri={window.location.origin}
    audience={config.audience} // NEW - specify the audience value
    onRedirectCallback={onRedirectCallback}
  >
    <App />
  </Auth0Provider>,

  document.getElementById("root")
);

serviceWorker.unregister();
