const getDataDB = async (email, scanRef, token) => {
  console.log("THIS IS EMAIL: " + email);
  console.log("THIS IS scanRef: " + scanRef);
  console.log("THIS IS token: " + token);

  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/logsDBscanrefemail/${scanRef}/${email}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    }
  );
  const responseData = await response.json();
  console.log(responseData);
  return responseData[0];
};

export default getDataDB;
