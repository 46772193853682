import React, { useState, useEffect } from "react";
import { useAuth0 } from "./react-auth0-spa";
import { Router, Route, Switch } from "react-router-dom";
import history from "./utils/history";
import { makeStyles } from "@material-ui/core/styles";
import CardComp from "../src/components/ServicesCompCard/CardComp";
import {
  LinearProgress,
  Paper,
  Container,
  Typography,
  Grid,
} from "@material-ui/core";
import ProgressBar from "./components/ProgressBarc";
import BloodHound from "./views/Bloodhound";
import PrivateRoute from "./components/PrivateRoute";
import Skeleton from "@material-ui/lab/Skeleton";
import Profile from "./components/Profile";
import Bloodhoundmain from "./views/Bloodhoundmain";
import Dialog from "./components/PayDialog";
import About from "./views/About";

import Footer from "./components/Footer";
import ScanHistory from "./views/ScanHistory";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider, mergeClasses } from "@material-ui/styles";
import History from "./components/History";
import Targets from "./views/Targets";
import Support from "./views/Support";
import ReactGA from "react-ga";
import SolutionsInfo from "./components/CSPInfo";
import Download from "./components/Download";
import BloodHoundDark from "./views/BloodhoundDark";
import NewAbout from "./views/NewAbout";
import StackTimeline from "../src/components/timeline/StackTimeline";
import SnackBar from "./components/SnackBar";
import dog from "../src/images/dog.png";
import Techstack from "../src/components/Techstack";
import DrawerNav from "./components/NavBar/DrawerNav";
import { ApolloProvider } from "@apollo/client";

import { ApolloClient, InMemoryCache } from "@apollo/client";
ReactGA.initialize("UA-132825352-3");
ReactGA.pageview(window.location.pathname + window.location.search);

var mixpanel = require("mixpanel-browser");
mixpanel.init("4ac82badb48ff0b8210833e642436ada");

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function App() {
  const classes = useStyles();
  console.log(localStorage.getItem("Mode"));
  const [dark, setDark] = useState(
    localStorage.getItem("Mode") == "dark" ? true : false
  );

  const typotheme = createMuiTheme();
  typotheme.typography.h6 = {
    typotheme: "6rem",
    "@media (min-width:600px)": {
      fontSize: "6rem",
    },
    [typotheme.breakpoints.up("md")]: {
      fontSize: 120,
    },
  };
  const theme = createMuiTheme({
    typography: {
      fontFamily: "Arial",
    },
    palette: {
      type: dark ? "dark" : "light",
    },
  });
  const { loading, isAuthenticated } = useAuth0();

  if (loading) {
    return (
      <ThemeProvider theme={typotheme}>
        <div>
          <Paper style={{ backgroundColor: "#505050", minHeight: "100vh" }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="h6"
                  style={{
                    marginTop: "15%",
                    textAlign: "center",
                    fontFamily: "Orbitron",
                    color: "#FFFFFF",
                  }}
                >
                  &lt;bloodhound BETA/&gt;
                  <br />
                  <ProgressBar />
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </ThemeProvider>
    );
  }
  function ChangeTheme(dark) {
    setDark(dark);
  }
  const client = new ApolloClient({
    uri: "http://3.104.30.196/graphql",
    cache: new InMemoryCache(),
  });
  return (
    <ApolloProvider client={client}>
      <div>
        <Paper elevation={0} style={{ minHeight: "100vh" }}>
          <ThemeProvider theme={theme}>
            <header>
              <DrawerNav theme={dark} pushData={ChangeTheme} />
              {/* <Dashboard /> */}
            </header>
            {/* <Router history={history}>
            <Switch>
              <Route path="/" exact component={BloodHound} />
              <Route path="/techstack" component={Techstack} />
              <Route path="/cardcomp" component={CardComp} />
              <Route path="/about" component={About} />
              <Route path="/support" component={Support} />
              <Route path="/newabout" component={NewAbout} />
              <Route path="/bloodhoundscan/:paramHost" component={BloodHound} />

              <Route path="/timeline" component={StackTimeline} />
              <Route path="/snackbar" component={SnackBar} />
              <Route path="/solutionsinfo" component={SolutionsInfo} />
              <Route path="/DrawerNav" component={DrawerNav} />

              <PrivateRoute path="/bloodhound" component={Bloodhoundmain} />
              <PrivateRoute path="/history" component={History} />
              <PrivateRoute path="/targets" component={Targets} />

              {/* <Route path="/websocket" component={WebSocket} />  */}
            {/* <PrivateRoute path="/profile" component={Profile} />
              <PrivateRoute path="/scanhistory" component={ScanHistory} />
              <Route path="/download" component={Download} />
              <PrivateRoute path="*" exact={true} component={BloodHound} />
            </Switch>
            </Router> */}
          </ThemeProvider>
          <footer></footer>
        </Paper>
      </div>
    </ApolloProvider>
  );
}

export default App;
