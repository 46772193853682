const getFreeToken = async () => {
  //Prod IP : 54.66.181.5
  //Prod Domain for node : https://bloodhoundapi.securestack.com/
  //Dev IP : 3.104.105.30
  //Local env : localhost:3001
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/loginfree`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: `${process.env.REACT_APP_FREE_SCAN_USERNAME}`,
      }),
    }
  );
  const responseData = await response.json();
  return responseData;
};
export default getFreeToken;
