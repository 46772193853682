import React, { memo, useEffect, useState } from "react";
import { TextField, Paper, Button, Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import getTargets from "../../functions/getTargets";
import { useAuth0 } from "../../react-auth0-spa";

function AddTodo(props) {
  useEffect(() => {
    getTargetsApi();
  }, []);
  const [targets, setTargets] = useState(false);
  const { isAuthenticated, user, getTokenSilently } = useAuth0();

  async function getTargetsApi() {
    console.log("Hello from targets");
    const token = await getTokenSilently();
    const getTargetsRes = await getTargets(user.email, token);
    setTargets(getTargetsRes);
    console.log(getTargetsRes);
  }

  return (
    <Paper style={{ margin: 16, padding: 16 }}>
      <Grid container>
        <Grid xs={10} md={11} item style={{ paddingRight: 16 }}>
          <Autocomplete
            freeSolo
            onChange={props.onInputChange}
            onKeyPress={props.onInputKeyPress}
            options={
              targets && Object.values(targets).map((option) => option.target)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select or Search"
                margin="normal"
                helperText="Please select a Target"
              />
            )}
          />
          {/* <TextField
              placeholder="Add Todo here"
              value={props.inputValue}
              onChange={props.onInputChange}
              onKeyPress={props.onInputKeyPress}
              fullWidth
            /> */}
        </Grid>
        <Grid xs={2} md={1} item>
          <Button
            fullWidth
            color="secondary"
            variant="outlined"
            onClick={props.onButtonClick}
          >
            Add
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

// export default AddTodo

// const AddTodo = memo((props) => (

// ));
export default AddTodo;
