import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { DialogActions, TextField, Grid, Select } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import postTaggingDB from "../functions/postTaggingDB";
import { useAuth0 } from "../react-auth0-spa";

const region = [
  {
    value: "United States",
    label: "United States",
  },
  {
    value: "Australia",
    label: "Australia",
  },
  {
    value: "Andorra",
    label: "Andorra",
  },
  {
    value: "Antigua and Barbuda",
    label: "Antigua and Barbuda",
  },
  {
    value: "Argentina",
    label: "Argentina",
  },

  {
    value: "Aruba",
    label: "Aruba",
  },

  {
    value: "Azerbaijan",
    label: "Azerbaijan",
  },
  {
    value: "Bahamas",
    label: "Bahamas",
  },
  {
    value: "Bahrain",
    label: "Bahrain",
  },
  {
    value: "Barbados",
    label: "Barbados",
  },
  {
    value: "Belarus",
    label: "Belarus",
  },
  {
    value: "Belgium",
    label: "Belgium",
  },
  {
    value: "Bermuda",
    label: "Bermuda",
  },
  {
    value: "Brunei",
    label: "Brunei",
  },
  {
    value: "Botswana",
    label: "Botswana",
  },
  {
    value: "British Virgin Islands",
    label: "British Virgin Islands",
  },
  {
    value: "Bulgaria",
    label: "Bulgaria",
  },
  {
    value: "Canada",
    label: "Canada",
  },
  {
    value: "Cayman",
    label: "Cayman",
  },
  {
    value: "Chile",
    label: "Chile",
  },
  {
    value: "Croatia",
    label: "Croatia",
  },
  {
    value: "Czech Replublic",
    label: "Czech Replublic",
  },
  {
    value: "Denmark",
    label: "Denmark",
  },
  {
    value: "Dominican Republic",
    label: "Dominican Republic",
  },
  {
    value: "Equatorial Guinea",
    label: "Equatorial Guinea",
  },
  {
    value: "Estonia",
    label: "Estonia",
  },
  {
    value: "Falkland Islands",
    label: "Falkland Islands",
  },
  {
    value: "Faroe Island",
    label: "Faroe Island",
  },
  {
    value: "Finland",
    label: "Finland",
  },
  {
    value: "France",
    label: "France",
  },
  {
    value: "Gabon",
    label: "Gabon",
  },
  {
    value: "Germany",
    label: "Germany",
  },
  {
    value: "Gibraltar",
    label: "Gibraltar",
  },
  {
    value: "Greece",
    label: "Greece",
  },
  {
    value: "Greenland",
    label: "Greenland",
  },
  {
    value: "Guam",
    label: "Guam",
  },
  {
    value: "Hong Kong",
    label: "Hong Kong",
  },
  {
    value: "Hungary",
    label: "Hungary",
  },
  {
    value: "Iceland",
    label: "Iceland",
  },
  {
    value: "India",
    label: "India",
  },
  {
    value: "Iran",
    label: "Iran",
  },
  {
    value: "Ireland",
    label: "Ireland",
  },
  {
    value: "Isle of Man",
    label: "Isle of Man",
  },
  {
    value: "Israel",
    label: "Israel",
  },
  {
    value: "Italy",
    label: "Italy",
  },
  {
    value: "Japan",
    label: "Japan",
  },
  {
    value: "Jersey",
    label: "Jersey",
  },
  {
    value: "Kazakhstan",
    label: "Kazakhstan",
  },
  {
    value: "Korea, South",
    label: "Korea, South",
  },
  {
    value: "Kuwait",
    label: "Kuwait",
  },
  {
    value: "Latvia",
    label: "Latvia",
  },
  {
    value: "Lebanon",
    label: "Lebanon",
  },
  {
    value: "Lithuania",
    label: "Lithuania",
  },

  {
    value: "Macau",
    label: "Macau",
  },
  {
    value: "Malaysia",
    label: "Malaysia",
  },
  {
    value: "Maldives",
    label: "Maldives",
  },
  {
    value: "Malta",
    label: "Malta",
  },
  {
    value: "Mauritius",
    label: "Mauritius",
  },
  {
    value: "Mexico",
    label: "Mexico",
  },
  {
    value: "Montenegro",
    label: "Montenegro",
  },
  {
    value: "Monaco",
    label: "Monaco",
  },

  {
    value: "Montserrat",
    label: "Montserrat",
  },
  {
    value: "Netherlands",
    label: "Netherlands",
  },
  {
    value: "New Caledonia",
    label: "New Caledonia",
  },
  {
    value: "New Zealand",
    label: "New Zealand",
  },
  {
    value: "Northern Mariana Islands",
    label: "Northern Mariana Islands",
  },
  {
    value: "Norway",
    label: "Norway",
  },
  {
    value: "Oman",
    label: "Oman",
  },
  {
    value: "Panama",
    label: "Panama",
  },
  {
    value: "Poland",
    label: "Poland",
  },
  {
    value: "Portugal",
    label: "Portugal",
  },
  {
    value: "Puerto",
    label: "Puerto",
  },
  {
    value: "Qatar",
    label: "Qatar",
  },
  {
    value: "Romania",
    label: "Romania",
  },
  {
    value: "Russia",
    label: "Russia",
  },
  {
    value: "Saint Pierre and Miquelon",
    label: "Saint Pierre and Miquelon",
  },
  {
    value: "Saint Kitts and Nevis",
    label: "Saint Kitts and Nevis",
  },

  {
    value: "San Marino",
    label: "San Marino",
  },
  {
    value: "Saudi",
    label: "Saudi",
  },
  {
    value: "Seychelles",
    label: "Seychelles",
  },
  {
    value: "Singapore",
    label: "Singapore",
  },
  {
    value: "Slovakia",
    label: "Slovakia",
  },
  {
    value: "Slovenia",
    label: "Slovenia",
  },
  {
    value: "Spain",
    label: "Spain",
  },
  {
    value: "Sweden",
    label: "Sweden",
  },
  {
    value: "Switzerland",
    label: "Switzerland",
  },
  {
    value: "Taiwan",
    label: "Taiwan",
  },
  {
    value: "Thailand",
    label: "Thailand",
  },
  {
    value: "Trinidad",
    label: "Trinidad",
  },
  {
    value: "Turkey",
    label: "Turkey",
  },
  {
    value: "Turkmenistan",
    label: "Turkmenistan",
  },
  {
    value: "Turks and Caicos Islands",
    label: "Turks and Caicos Islands",
  },
  {
    value: "United Kingdom",
    label: "United Kingdom",
  },
  {
    value: "Uruguay",
    label: "Uruguay",
  },
  {
    value: "Virgin Islands",
    label: "Virgin Islands",
  },
];

const companySize = [
  {
    value: "1-4",
    label: "1-4",
  },
  {
    value: "5-20",
    label: "5-20",
  },
  {
    value: "21-50",
    label: "21-50",
  },
  {
    value: "51-200",
    label: "51-200",
  },
  {
    value: "201-1000",
    label: "201-1000",
  },
  {
    value: "1001-5000",
    label: "1001-5000",
  },
  {
    value: "5001 - 9,999 employees",
    label: "5001 - 9,999 employees",
  },
  {
    value: "10,000+ employees",
    label: "10,000+ employees",
  },
];

const company = [
  {
    value: "Startup",
    label: "Startup",
  },
  {
    value: "Scaleup",
    label: "Scaleup",
  },
  {
    value: "Support",
    label: "Support",
  },
  {
    value: "SMB",
    label: "SMB",
  },
  {
    value: "SME",
    label: "SME",
  },
  {
    value: "Enterprise",
    label: "Enterprise",
  },
];

const companyVertical = [
  {
    value: "Analytics",
    label: "Analytics",
  },
  {
    value: "Automotive",
    label: "Automotive",
  },
  {
    value: "Banking",
    label: "Banking",
  },
  {
    value: "Big Data",
    label: "Big Data",
  },
  {
    value: "Cloud Provider",
    label: "Cloud Provider",
  },
  {
    value: "Consumer",
    label: "Consumer",
  },
  {
    value: "Crypto",
    label: "Crypto",
  },
  {
    value: "CyberSecurity",
    label: "CyberSecurity",
  },
  {
    value: "E-commerce",
    label: "E-commerce",
  },
  {
    value: "Education",
    label: "Education",
  },
  {
    value: "Electronics",
    label: "Electronics",
  },
  {
    value: "Energy",
    label: "Energy",
  },
  {
    value: "Engineering",
    label: "Engineering",
  },
  {
    value: "Entertainment",
    label: "Entertainment",
  },
  {
    value: "Fashion",
    label: "Fashion",
  },
  {
    value: "Fast-moving consumer goods",
    label: "Fast-moving consumer goods",
  },
  {
    value: "Financial",
    label: "Financial",
  },
  {
    value: "FinTech",
    label: "FinTech",
  },
  {
    value: "Food and beverage",
    label: "Food and beverage",
  },
  {
    value: "Gaming",
    label: "Gaming",
  },
  {
    value: "Government Federal",
    label: "Government Federal",
  },
  {
    value: "Government Local",
    label: "Government Local",
  },
  {
    value: "Government State",
    label: "Government State",
  },
  {
    value: "Healthcare",
    label: "Healthcare",
  },
  {
    value: "Hosting",
    label: "Hosting",
  },
  {
    value: "Information Technology",
    label: "Information Technology",
  },
  {
    value: "IT services or consulting",
    label: "IT services or consulting",
  },
  {
    value: "Insurance",
    label: "Insurance",
  },
  {
    value: "Jewelry",
    label: "Jewelry",
  },

  {
    value: "Legal",
    label: "Legal",
  },
  {
    value: "Managed Service Provider",
    label: "Managed Service Provider (MSP)",
  },
  {
    value: "Managed Security Service Provider",
    label: "Managed Security Service Provider (MSSP)",
  },
  {
    value: "Manufacturing",
    label: "Manufacturing",
  },
  {
    value: "Marketing",
    label: "Marketing",
  },
  {
    value: "Media",
    label: "Media",
  },
  {
    value: "Not-for-profit",
    label: "Not-for-profit",
  },
  {
    value: "Online",
    label: "Online",
  },
  {
    value: "Raw materials",
    label: "Raw materials",
  },
  {
    value: "Real estate",
    label: "Real estate",
  },

  {
    value: "Religion",
    label: "Religion",
  },
  {
    value: "Retail",
    label: "Retail",
  },

  {
    value: "Software Development",
    label: "Software Development",
  },
  {
    value: "Solutions Provider",
    label: "Solutions Provider",
  },
  {
    value: "SAAS Provider",
    label: "SAAS Provider",
  },
  {
    value: "Technology",
    label: "Technology",
  },
  {
    value: "Telecommunications",
    label: "Telecommunications",
  },
  {
    value: "Transportation (Travel)",
    label: "Transportation (Travel)",
  },
  {
    value: "Venture Capital",
    label: "Venture Capital",
  },

  {
    value: "Other",
    label: "Other",
  },
];

export default function Tagging(props) {
  const { isAuthenticated, user, getTokenSilently } = useAuth0();
  const [open, setOpen] = React.useState(true);
  const [country, setCountry] = React.useState();
  const [size, setSize] = React.useState();
  const [vertical, setVertical] = React.useState();
  const [compSize, setCompSize] = React.useState("");

  const tagInfo = {
    murl: props.murl,
    countryinfo: country,
    sizeinfo: size,
    verticalinfo: vertical,
    type: compSize,
  };

  const handleClose = () => {
    setOpen(false);
    props.pushData(false);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: "25ch",
      },
    },
  }));
  const submit = async () => {
    var email;
    if (isAuthenticated) {
      email = user.email;
    }
    console.log(email);

    postTaggingDB(
      localStorage.getItem("scanref"),
      tagInfo.murl,
      tagInfo.countryinfo,
      tagInfo.sizeinfo,
      tagInfo.verticalinfo,
      tagInfo.type,
      email
    );
    setOpen(false);
    props.pushData(false);
  };

  const handleCountry = (event) => {
    setCountry(event.target.value);
  };

  const handleSize = (event) => {
    setSize(event.target.value);
    console.log(event.target.value);
  };

  const handleVertical = (event) => {
    setVertical(event.target.value);
    console.log(event.target.value);
  };

  const handleCompSize = (event) => {
    setCompSize(event.target.value);
    console.log(event.target.value);
  };

  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Thanks for using Bloodhound and we hope you are enjoying it! Deepscans
          typically take about 5 minutes to run. While you wait for the results
          we'd like to ask you a few questions about the URL you scanned so we
          can better present results that are more related to your industry.
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form className={classes.root} noValidate autoComplete="off">
              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    select
                    label="Region"
                    onChange={handleCountry}
                    value={country}
                  >
                    {region.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    select
                    label="Number of employees"
                    onChange={handleSize}
                    value={size}
                  >
                    {companySize.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    select
                    label="Company Type"
                    onChange={handleCompSize}
                    value={compSize}
                  >
                    {company.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    select
                    label="Industry Vertical"
                    onChange={handleVertical}
                    value={vertical}
                  >
                    {companyVertical.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={submit} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
