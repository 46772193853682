const getChangesHistoryTarget = async (email, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/logsDBChangesHistoryTargets/${email}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    }
  );
  const responseData = await response.json();
  return responseData;
};

export default getChangesHistoryTarget;
