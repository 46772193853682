import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  Paper,
  Grid,
  Divider,
  Typography,
  Button,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAuth0 } from "../../../react-auth0-spa";
import { AiOutlineSecurityScan } from "react-icons/ai";
import getOtherDomainsDB from "../../../functions/getOtherDomainsDB";
import { BsFillCollectionFill } from "react-icons/bs";
import postDomainScan from "../../../functions/postDomainScan";
import getDNSDB from "../../../functions/getDNSDB";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  iconButton: {
    padding: 10,
    color: "red",
  },
  height: 28,
  margin: 4,
  backgroundColor: "#FFFFFF",
});

export default function FirewallReport(props) {
  console.log(props.finalscore);
  let IPSERVICE;
  const [data, setData] = useState(false);
  const [scanref, setScanref] = useState(true);
  const { getTokenSilently, user } = useAuth0();
  const [murl, setMurl] = useState(false);
  const [rand, setRand] = useState(false);
  const [realScanref, setRealscanref] = useState(false);

  const classes = useStyles();
  const [otherdomains, setOtherDomainsData] = useState(false);

  async function getotherdomain() {
    const token = await getTokenSilently();
    if (props.data && props.data[0].scanreference) {
      setData(props.data);
      setMurl(props.data[0].rooturl);
      const resOtherDomainsDB = await getOtherDomainsDB(
        props.data[0].scanreference,
        token
      );
      if (
        typeof resOtherDomainsDB != "undefined" &&
        resOtherDomainsDB.length > 0
      ) {
        setOtherDomainsData(resOtherDomainsDB);
        setScanref(false);
      }
    }
  }

  async function realTimeScan() {
    console.log("from realtime function");
    setData(false);
    const token = await getTokenSilently();
    const resReal = await postDomainScan(user.email, murl, token);
    console.log(resReal);
    if (!resReal && !resReal.scanreference) {
      console.log("Something went wrong in Domains");
      const interval = setInterval(async () => {
        console.log("This will run every minute second!");
        const resDNS = await getDNSDB(
          token,
          localStorage.getItem("scanrefDomain")
        );
        console.log(resDNS);
        if (typeof resDNS != "undefined" && resDNS.length > 0) {
          console.log("clear interval");
          clearInterval(interval);
          setData(resDNS);
          localStorage.setItem(
            "priorscanrefdomains",
            localStorage.getItem("scanrefDomain")
          );
        }
      }, 30000);
    } else if (resReal && resReal.domainScan) {
      const resDNS = await getDNSDB(
        token,
        localStorage.getItem("scanrefDomain")
      );
      console.log(resDNS);
      if (typeof resDNS != "undefined" && resDNS.length > 0) {
        console.log(resDNS);
        setData(resDNS);
      }
    }
    // setData(resReal ? resReal : false);
  }

  useEffect(() => {
    if (scanref) {
      getotherdomain();
    }
  }, []);
  function getFinalScore() {
    {
      const min = 1;
      const max = 100;
      const rand = min + Math.random() * (max - min);
      return Math.floor(rand);
    }
  }
  function splitPorts(ports) {
    if (ports) {
      let dataToArray = ports.split(" ").map((item) => {
        const port = item.trim(" ");
        if (port === "80") {
          return "HTTP ";
        } else if (port === "22") {
          return "SSH ";
        } else if (port === "110") {
          return "POP3 ";
        } else if (port === "443") {
          return "HTTPS ";
        } else if (port === "21") {
          return "FTP ";
        } else if (port === "3389") {
          return "RDP ";
        } else if (port === "3306") {
          return "DB ";
        } else {
          return port;
        }
      });

      console.log(dataToArray);
      return dataToArray;
    }
  }

  return (
    <Paper elevation={0} className={classes.paper}>
      <Grid container>
        <Grid item xs={12}></Grid>
      </Grid>
      {data === false ? (
        <div>
          <p>
            <CircularProgress style={{ color: "#ad2007" }} />
          </p>
        </div>
      ) : (
        <div>
          {
            <div>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <Typography variant="h6" component="h6">
                          <strong> Subdomains</strong>
                          <Tooltip
                            title={`Scan Domains for ${murl ? murl : ""}`}
                          >
                            <IconButton
                              onClick={() => realTimeScan()}
                              className={classes.iconButton}
                            >
                              <AiOutlineSecurityScan style={{ fontSize: 40 }} />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="h6" component="h6">
                          <strong> IP Address</strong>
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="h6" component="h6">
                          <strong> Provider </strong>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="h6" component="h6">
                          <strong> IP Services</strong>
                        </Typography>
                      </TableCell>
                      {/* <TableCell align="right">
                        <Typography variant="h6" component="h6">
                          <strong> Time</strong>
                        </Typography>
                      </TableCell> */}
                      <TableCell align="center">
                        <Typography variant="h6" component="h6">
                          <strong> Country </strong>
                        </Typography>
                      </TableCell>

                      {/* <TableCell align="right">
                        <Typography variant="h6" component="h6">
                          <strong> Final Score </strong>
                        </Typography>
                      </TableCell> */}
                    </TableRow>
                  </TableHead>

                  {data.map((row, index) => (
                    <TableBody>
                      {row.subdomains === "WILDCARD" ? (
                        ""
                      ) : (
                        <TableRow key={index}>
                          <TableCell align="left">
                            <Typography
                              style={{ color: "red", fontFamily: "courier" }}
                              variant="h6"
                              component="h6"
                            >
                              {row.subdomains}
                            </Typography>
                          </TableCell>

                          <TableCell align="center" component="th" scope="row">
                            <Typography
                              style={{ fontFamily: "courier" }}
                              variant="h6"
                              component="h6"
                            >
                              {row.subip}
                            </Typography>
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            <Timeline align="alternate">
                              <TimelineItem>
                                <TimelineDot
                                  variant="outlined"
                                  className={classes.dot}
                                >
                                  <img
                                    alt={`${row.subispfull}`}
                                    src={`https://bloodhound.securestack.com/images/architecture/${row.subispfull}.png`}
                                  />
                                </TimelineDot>
                              </TimelineItem>
                            </Timeline>
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            <Typography
                              style={{
                                fontFamily: "courier",
                              }}
                              variant="h6"
                              component="h6"
                            >
                              {/* {row.ports} */}
                              {(IPSERVICE = splitPorts(row.ports))}
                            </Typography>
                          </TableCell>
                          {/* <TableCell align="right" component="th" scope="row">
                            <Typography
                              style={{ fontFamily: "courier" }}
                              variant="h6"
                              component="h6"
                            >
                              {row.lastscanned.substring(16, 11)}
                            </Typography>
                          </TableCell> */}
                          <TableCell align="center" component="th" scope="row">
                            <Typography
                              style={{ fontFamily: "courier" }}
                              variant="h6"
                              component="h6"
                            >
                              {row.subcountry}
                            </Typography>
                          </TableCell>
                          {/* <TableCell component="th" scope="row">
                            <Typography
                              style={{ fontFamily: "orbitron" }}
                              variant="h5"
                              component="h5"
                            >
                              <strong>
                                {props.finalscore ? (
                                  props.finalscore > 80 ? (
                                    <Typography
                                      style={{
                                        color: "#42f542",
                                        fontFamily: "Orbitron",
                                      }}
                                      variant="h4"
                                      component="h4"
                                    >
                                      {props.finalscore - 20}
                                    </Typography>
                                  ) : props.finalscore >= 50 &&
                                    props.finalscore < 80 ? (
                                    <Typography
                                      style={{
                                        color: "#eb7d34",
                                        fontFamily: "Orbitron",
                                      }}
                                      variant="h4"
                                      component="h4"
                                    >
                                      {props.finalscore - 10}
                                    </Typography>
                                  ) : (
                                    <Typography
                                      style={{
                                        color: "red",
                                        fontFamily: "Orbitron",
                                      }}
                                      variant="h4"
                                      component="h4"
                                    >
                                      {props.finalscore - 5}
                                    </Typography>
                                  )
                                ) : (
                                  ""
                                )}
                              </strong>
                            </Typography>
                          </TableCell> */}

                          <Divider
                            className={classes.divider}
                            orientation="vertical"
                          />
                          <TableCell align="right">
                            <Tooltip title={`Scan subdomain ${row.subdomains}`}>
                              <IconButton
                                className={classes.iconButton}
                                href={`/bloodhoundscan/${row.subdomains}`}
                              >
                                <AiOutlineSecurityScan
                                  style={{ fontSize: 40 }}
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title={`Deep Scan subdomain ${row.subdomains}`}
                            >
                              <IconButton
                                className={classes.iconButton}
                                href={`/bloodhoundscandeep/${row.subdomains}`}
                              >
                                <BsFillCollectionFill
                                  style={{ fontSize: 40 }}
                                />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  ))}
                </Table>
              </TableContainer>

              <br />
            </div>
          }
        </div>
      )}
      {!otherdomains ? (
        <div>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6" component="h6">
                      <strong> Target URL</strong>
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="h6" component="h6">
                      <strong> Otherdomains</strong>
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* <TableRow>
                  <TableCell component="th" scope="row">
                    <Typography
                      style={{ fontFamily: "courier" }}
                      variant="h6"
                      component="h6"
                    >
                      {murl}
                    </Typography>
                  </TableCell>

                  <TableCell align="right">
                    <Typography
                      style={{ color: "red", fontFamily: "courier" }}
                      variant="h6"
                      component="h6"
                    >
                      {murl}
                    </Typography>
                  </TableCell>

                  <TableCell align="right">
                    <Tooltip title="Scan otherdomain">
                      <IconButton
                        className={classes.iconButton}
                        href={`/bloodhoundscan/${murl}`}
                      >
                        <AiOutlineSecurityScan style={{ fontSize: 40 }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      href={`/bloodhoundscandeep/${murl}`}
                      title={`Deep Scan otherdomain`}
                    >
                      <IconButton className={classes.iconButton}>
                        <BsFillCollectionFill style={{ fontSize: 40 }} />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow> */}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <div>
          {
            <div>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6" component="h6">
                          <strong> Target URL</strong>
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="h6" component="h6">
                          <strong> Date</strong>
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="h6" component="h6">
                          <strong> Time</strong>
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="h6" component="h6">
                          <strong> Otherdomains</strong>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {otherdomains.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          <Typography
                            style={{ fontFamily: "courier" }}
                            variant="h6"
                            component="h6"
                          >
                            {row.rooturl}
                          </Typography>
                        </TableCell>
                        <TableCell align="right" component="th" scope="row">
                          <Typography
                            style={{ fontFamily: "courier" }}
                            variant="h6"
                            component="h6"
                          >
                            {row.lastscanned.substring(
                              0,
                              row.lastscanned.length - 14
                            )}
                          </Typography>
                        </TableCell>

                        <TableCell align="right" component="th" scope="row">
                          <Typography
                            style={{ fontFamily: "courier" }}
                            variant="h6"
                            component="h6"
                          >
                            {row.lastscanned.substring(16, 11)}
                          </Typography>
                        </TableCell>

                        <TableCell align="right">
                          <Typography
                            style={{ color: "red", fontFamily: "courier" }}
                            variant="h6"
                            component="h6"
                          >
                            {row.domains}
                          </Typography>
                        </TableCell>

                        <TableCell align="right">
                          <Tooltip title={`Scan otherdomain ${row.domains}`}>
                            <IconButton
                              className={classes.iconButton}
                              href={`/bloodhoundscan/${row.domains}`}
                            >
                              <AiOutlineSecurityScan style={{ fontSize: 40 }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            href={`/bloodhoundscandeep/${row.domains}`}
                            title={`Deep Scan otherdomain ${row.domains}`}
                          >
                            <IconButton className={classes.iconButton}>
                              <BsFillCollectionFill style={{ fontSize: 40 }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          }
        </div>
      )}
      <br />
      <br />
      <br />
      <br />
    </Paper>
  );
}
