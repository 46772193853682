import React, {useEffect } from "react";

export default function PostFreeUser(props){
    
  

    useEffect(() => {
    const callApi = async () => {
    try{
         //Prod IP : 54.66.181.5
         //Prod Domain for node : https://bloodhoundapi.securestack.com/
         //Dev IP : 3.104.105.30
         //Local env: localhost:3001
        const response = await fetch("https://bloodhoundapi.securestack.com/loginfree", {
        method: "POST",
        headers: {
        Accept: "application/json",
        "Content-Type": "application/json",  
        },

        body: JSON.stringify({ 
        username: "freeuser"
        }),
    });

        const responseData = await response.json();
        console.log(responseData)
      
        await props.pushData(responseData);
      } catch (error) {
        console.error(error);
      }
    };
    callApi();
  }, []);

  return <div> 
  </div>;
}

