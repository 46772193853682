import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Paper } from "@material-ui/core";
import GridSolution from "../Solution/GridSolution";
import SolutionInfoData from "../../Data/SolutionInfoData";

const useStyles = makeStyles((theme) => ({
  actionArea: {
    borderRadius: 16,
    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.2)",
    },
  },
}));

export default function Solutions(props) {
  const classes = useStyles();

  {
    console.log(props.data);
    console.log(SolutionInfoData);
  }

  const solutionData = {
    csp: {
      cspdescription: `Does not implement security headers into web server environment for CSP`,
      value: `${props.data.csp}`,
      fix: "CSP",
      ignore: "IGNORE",
      severity: "medium",
      shade: "orange",
    },

    hsts: {
      hstsdescription: `Does not implement security headers into web server environment for HSTS`,
      value: `${props.data.hsts}`,
      fix: "HSTS",
      ignore: "IGNORE",
      severity: "medium",
      shade: "orange",
    },

    referrerpolicy: {
      rpdescription: `Does not implement security headers into web server environment for Referrer Policy`,
      value: `${props.data.referrerpolicy}`,
      fix: "referrerpolicy",
      ignore: "IGNORE",
      severity: "medium",
      shade: "orange",
    },

    httpsredirect: {
      httpsdescription: `Does not automatically send your customers to the secure version of your APP`,
      value: `${props.data.https_redirect}`,
      fix: "httpsredirect",
      ignore: "IGNORE",
      severity: "critical",
      shade: "red",
    },

    loadbalancer: {
      loaddescription: `No elasticity and load balancing`,
      value: `${props.data.loadbalanced}`,
      fix: "loadbalancer",
      ignore: "IGNORE",
      severity: "medium",
      shade: "orange",
    },

    waf: {
      wafdescription: `No web application firewall (waf) which filters, monitors, and blocks HTTP traffic to and from a web application`,
      value: `${props.data.waf}`,
      fix: "waf",
      ignore: "IGNORE",
      severity: "medium",
      shade: "orange",
    },

    ssh: {
      sshdescription: `SSH is open to the public on TCP port 22! `,
      value: `${props.data.port22}`,
      fix: "ssh",
      ignore: "IGNORE",
      severity: "critical",
      severity: "high",
      shade: "red",
    },
    wrd: {
      wrddescription: `Windows Remote Desktop is open to the public on TCP port 3389 `,
      value: `${props.data.port3389}`,
      fix: "wrd",
      ignore: "IGNORE",

      severity: "high",
      shade: "red",
    },

    dbserver: {
      dbdescription: `Database server is open to the public on TCP port 3306 `,
      value: `${props.data.port3306}`,
      fix: "dbserver",
      ignore: "IGNORE",

      severity: "high",
      shade: "red",
    },

    sslissue: {
      sslissuedescription: `There is an SSL cert issue with this domain`,
      value: `${props.data.sslissue}`,
      fix: "sslissue",
      ignore: "IGNORE",
      severity: "high",
      shade: "red",
    },

    numberunecryptedpages: {
      unencryptedpagesdescription: `There is unencrypted content on this web domain. Number of Pages unencrypted: ${props.data.numberunencryptedpages}`,
      value: `${props.data.numberunencryptedpages}`,
      fix: "numberunecryptedpages",
      ignore: "IGNORE",
      severity: "medium",
      shade: "orange",
    },

    windows_aws: {
      windows_awsdescription: `Deploy a hardened Windows server AMI in AWS`,
      value: `${props.data.windows && props.data.cloudver}`,
      fix: "windows_aws",
      ignore: "IGNORE",
      severity: "medium",
      shade: "orange",
    },

    linux_aws: {
      linux_aws: `Deploy a hardened Linux server AMI in AWS`,
      value: `${props.data.linux && props.data.cloudver}`,
      fix: "linux_aws",
      ignore: "IGNORE",
      severity: "high",
      shade: "red",
    },
    centralized: {
      centralizeddescription: `Add centralized application and system logging so security events can be captured and alerts sent to the correct teams`,
      value: ``,
      fix: "FIX",
      ignore: "IGNORE",
      severity: "low",
      shade: "blue",
    },
  };
  return (
    <div>
      <Paper style={{ marginTop: 30 }} elevation={0}>
        {Object.values(solutionData).map((row, index) => (
          <Fragment>
            {!props.data.https_redirect ? (
              <GridSolution
                description={row.httpsdescription}
                fix={row.fix}
                ignore={row.ignore}
                severity={row.severity}
                shade={row.shade}
              />
            ) : (
              <></>
            )}
            {!props.data.loadbalanced ? (
              <GridSolution
                description={row.loaddescription}
                fix={row.fix}
                ignore={row.ignore}
                severity={row.severity}
                shade={row.shade}
              />
            ) : (
              <></>
            )}

            {props.data.sslissue ? (
              <GridSolution
                description={row.sslissuedescription}
                fix={row.fix}
                ignore={row.ignore}
                severity={row.severity}
                shade={row.shade}
              />
            ) : (
              <></>
            )}

            {props.data && props.data.numberunencryptedpages !== 0 ? (
              <GridSolution
                description={row.unencryptedpagesdescription}
                fix={row.fix}
                ignore={row.ignore}
                severity={row.severity}
                shade={row.shade}
              />
            ) : (
              <></>
            )}

            {props.data || !props.data ? (
              <GridSolution
                description={row.centralizeddescription}
                fix={row.fix}
                ignore={row.ignore}
                severity={row.severity}
                shade={row.shade}
              />
            ) : (
              <></>
            )}

            {props.data.windows && props.data.cloudver === "aws" ? (
              <GridSolution
                description={row.windows_awsdescription}
                fix={row.fix}
                ignore={row.ignore}
                severity={row.severity}
                shade={row.shade}
              />
            ) : (
              <></>
            )}
            {props.data.linux && props.data.cloudver === "aws" ? (
              <GridSolution
                description={row.linux_aws}
                fix={row.fix}
                ignore={row.ignore}
                severity={row.severity}
                shade={row.shade}
              />
            ) : (
              <></>
            )}

            {!props.data.waf ? (
              <GridSolution
                description={row.wafdescription}
                fix={row.fix}
                ignore={row.ignore}
                severity={row.severity}
                shade={row.shade}
              />
            ) : (
              <></>
            )}
            {props.data.port22 ? (
              <GridSolution
                description={row.firewalldescription}
                fix={row.fix}
                ignore={row.ignore}
                severity={row.severity}
                shade={row.shade}
              />
            ) : (
              <></>
            )}
            {/* {props.data.port3306 ? (
              <GridSolution
                description={row.firewalldescription}
                fix={row.fix}
                ignore={row.ignore}
                severity={row.severity}
                shade={row.shade}
              />
            ) : (
              <></>
            )} */}
            {props.data.port3389 ? (
              <GridSolution
                description={row.firewalldescription}
                fix={row.fix}
                ignore={row.ignore}
                severity={row.severity}
                shade={row.shade}
              />
            ) : (
              <></>
            )}

            {!props.data.hsts ? (
              <GridSolution
                description={row.hstsdescription}
                fix={row.fix}
                ignore={row.ignore}
                severity={row.severity}
                shade={row.shade}
              />
            ) : (
              <></>
            )}

            {props.data.cspdatafull === "0" ? (
              <GridSolution
                description={row.cspdescription}
                fix={row.fix}
                data={SolutionInfoData.CSP}
                ignore={row.ignore}
                severity={row.severity}
                shade={row.shade}
              />
            ) : (
              <></>
            )}

            {props.data.referrerpolicy === 0 ? (
              <GridSolution
                description={row.rpdescription}
                fix={row.fix}
                ignore={row.ignore}
                severity={row.severity}
                shade={row.shade}
              />
            ) : (
              <></>
            )}
            {props.data.port22 === 1 ? (
              <GridSolution
                description={row.sshdescription}
                fix={row.fix}
                ignore={row.ignore}
                severity={row.severity}
                shade={row.shade}
              />
            ) : (
              <></>
            )}
            {props.data.port3389 === 1 ? (
              <GridSolution
                description={row.wrddescription}
                fix={row.fix}
                ignore={row.ignore}
                severity={row.severity}
                shade={row.shade}
              />
            ) : (
              <></>
            )}
            {props.data.port3306 === 1 ? (
              <GridSolution
                description={row.dbdescription}
                fix={row.fix}
                ignore={row.ignore}
                severity={row.severity}
                shade={row.shade}
              />
            ) : (
              <></>
            )}
          </Fragment>
        ))}
        {props.data.wordpress === 1 &&
        (props.data.apache === 1 || props.data.php === 1 || props.data.nginx) &&
        (props.data.serverhost === 1 || props.data.ec2host === 1) ? (
          <GridSolution
            description="Your WordPress server is not secure"
            fix="fix"
            ignore="ignore"
          />
        ) : (
          <></>
        )}
        {props.data.wordpress === 1 &&
        props.data.cloudfronthost === 1 &&
        (props.data.php === 1 || props.data.apache === 1) ? (
          <GridSolution
            description="Hardened Wordpress Product in CloudBuilder"
            fix="fix"
            ignore="ignore"
            severity="low"
            shade="blue"
          />
        ) : (
          <></>
        )}
      </Paper>
      <br />
      <br />
      <br />
    </div>
  );
}
