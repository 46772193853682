import React from "react";
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";
import BuildIcon from "@material-ui/icons/Build";
import { Typography, Grid, Container, Paper, Button } from "@material-ui/core/";
import Stepper from "./timeline/Stepper";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import FormSelection from "./FormSelection";
import RecentTestData from "./utils/RecentTestData.json";
import ScoreCard from "./ScoreCard";
import SingleMurlGauge from "./Gauge/SingleMurlGauge";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.primary,
  },

  root: {
    flexGrow: 1,
  },
  typography: {
    fontFamily: "Orbitron",
    fontSize: 20,

    color: theme.palette.text.primary,
  },
}));

export default function Architecture() {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Grid container>
        <Grid item xs={6}>
          <Typography className={classes.typography}>ARCHITECTURE</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography style={{ textAlign: "right" }}>
            <Button
              style={{ color: "#ad2007", backgroundColor: "none" }}
              endIcon={<ArrowRightAltIcon style={{ fontSize: 40 }} />}
            >
              SEE INSIDE
            </Button>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Stepper />
        </Grid>
      </Grid>
    </Paper>
  );
}
