import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Paper, Typography, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
  },
  typography: {
    fontFamily: "Orbitron",
    fontSize: 30,
    backgroundColor: "#ad2007",
    textAlign: "center",
    color: "white",
  },
  murl: {
    fontFamily: "courier",
    fontSize: 20,
  },
  score: {
    fontFamily: "Orbitron",
    fontSize: 20,
    color: "green",
  },
  worstscore: {
    fontFamily: "Orbitron",
    fontSize: 20,
    color: "#ad2007",
  },

  paper: {
    marginTop: theme.spacing(3),
    width: "100%",
    overflowX: "auto",
    marginBottom: theme.spacing(2),
    margin: "auto",
  },
  title: {
    fontFamily: "Orbitron",
    fontSize: 40,
    textAlign: "center",
  },
}));

export default function LeaderBoard(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <Paper className={classes.paper}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography align="center" className={classes.typography}>
                        Top Scores
                      </Typography>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.values(props.data).map((row, index) => (
                    <TableRow key={row.index}>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.murl}>
                          {row.murl}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography className={classes.score}>
                          {row.securityscore}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>

      {/* <Grid item md={4}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography className={classes.typography}>
                      Lowest Scores
                    </Typography>
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.values(props.worstdata).map((row, index) => (
                  <TableRow key={row.index}>
                    <TableCell component="th" scope="row">
                      <Typography className={classes.murl}>
                        {row.murl}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography className={classes.worstscore}>
                        {row.securityscore}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid> */}
    </React.Fragment>
  );
}
