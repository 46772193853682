import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { Button, Grid, Paper, Tooltip, Container } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Typing from "react-typing-animation";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import CloudFirewallIcon from "../../../images/ArchitectureImages/cloud-security.png";
import FirewallIcon from "../../../images/ArchitectureImages/shield.png";
import apps from "../../../images/ArchitectureImages/apps.png";
import ec2host from "../../../images/ArchitectureImages/ec2host.png";
import Application from "../Architecture/Application";

import OSInfo from "../Architecture/OperatingSystem";
import WebServer from "../Architecture/WebServer";
import AppServer from "../Architecture/AppServer";
import Cloud from "../Architecture/Cloud";
import CDN from "../Architecture/CDN";
import AppsIcon from "@material-ui/icons/Apps";

//test popup
import SolutionInfo from "../Solution/SolutionInfo";
import SolutionInfoData from "../../Data/SolutionInfoData";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
  },
  dot: {
    backgroundColor: "white",

    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.2)",
    },
  },
  secondaryTail: {
    height: "25px",
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function ArchitectureTimeline(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openWebServer, setOpenWebserver] = React.useState(false);
  const [openAppServer, setOpenAppserver] = React.useState(false);
  const [openCloud, setOpenCloud] = React.useState(false);
  const [openCDN, setOpenCDN] = React.useState(false);

  const [openApplication, setOpenApplication] = React.useState(false);

  function openPopup() {
    setOpenWebserver(false);
    setOpenAppserver(false);
    setOpenCloud(false);
    setOpenCDN(false);
    setOpenApplication(false);
    setOpen(true);
  }
  function openPopupWebServer() {
    setOpen(false);
    setOpenAppserver(false);
    setOpenCloud(false);
    setOpenCDN(false);
    setOpenApplication(false);
    setOpenWebserver(true);
  }
  function openPopupAppServer() {
    setOpenWebserver(false);
    setOpen(false);
    setOpenCloud(false);
    setOpenCDN(false);
    setOpenApplication(false);
    setOpenAppserver(true);
  }
  function openPopupCloud() {
    setOpenWebserver(false);
    setOpen(false);
    setOpenAppserver(false);
    setOpenCDN(false);
    setOpenApplication(false);
    setOpenCloud(true);
  }
  function openPopupCDN() {
    setOpenWebserver(false);
    setOpen(false);
    setOpenAppserver(false);
    setOpenCloud(false);
    setOpenApplication(false);
    setOpenCDN(true);
  }
  function openPopupApplication() {
    setOpenWebserver(false);
    setOpen(false);
    setOpenAppserver(false);
    setOpenCloud(false);
    setOpenCDN(false);
    setOpenApplication(true);
  }

  return (
    <div>
      <Container>
        <Timeline align="alternate">
          {props.data.cdnver && (
            <TimelineItem>
              {props.data.waflocation === "cdn" && (
                <TimelineOppositeContent>
                  <Typography variant="body2" color="textSecondary">
                    <Tooltip title="Protected by WAF">
                      <img src={FirewallIcon} />
                    </Tooltip>
                  </Typography>
                </TimelineOppositeContent>
              )}
              <TimelineSeparator>
                <Button onClick={openPopupCDN}>
                  <TimelineDot variant="outlined" className={classes.dot}>
                    <Tooltip title="Click for Insights">
                      <img
                        src={`https://bloodhound.securestack.com/images/architecture/${props.data.cdnver}.png`}
                      />
                    </Tooltip>
                  </TimelineDot>
                </Button>
                <TimelineConnector className={classes.secondaryTail} />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h6" component="h1">
                    CDN:
                  </Typography>
                  <Typography style={{ fontFamily: "orbitron" }}>
                    <Typing speed={100}>
                      {props.data.cdnver.toUpperCase()}
                    </Typing>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          )}
          {(props.data.cloudver || props.data.isp) && (
            <React.Fragment>
              <TimelineItem>
                {(props.data.waflocation === "cloud" ||
                  props.data.waflocation === "isp") && (
                  <React.Fragment>
                    <TimelineOppositeContent>
                      <Typography variant="body2" color="textSecondary">
                        <Tooltip title="Protected by WAF">
                          <img src={CloudFirewallIcon} />
                        </Tooltip>
                      </Typography>
                    </TimelineOppositeContent>
                  </React.Fragment>
                )}

                <TimelineSeparator>
                  {props.data.cloudver && (
                    <Button onClick={openPopupCloud}>
                      <TimelineDot variant="outlined" className={classes.dot}>
                        <Tooltip title="Click for Insights">
                          <img
                            src={`https://bloodhound.securestack.com/images/architecture/${props.data.cloudver}.png`}
                          />
                        </Tooltip>
                      </TimelineDot>
                    </Button>
                  )}

                  {props.data.isp && (
                    <Button onClick={openPopup}>
                      <TimelineDot variant="outlined" className={classes.dot}>
                        <Tooltip title="Click for Insights">
                          <img
                            src={`https://bloodhound.securestack.com/images/architecture/internet.png`}
                          />
                        </Tooltip>
                      </TimelineDot>
                    </Button>
                  )}
                  <TimelineConnector className={classes.secondaryTail} />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h6" component="h1">
                      {props.data.cloudver && <Typography>CLOUD:</Typography>}
                      {props.data.isp && <Typography>ISP:</Typography>}
                    </Typography>

                    {props.data.cloudver && (
                      <Typography style={{ fontFamily: "orbitron" }}>
                        <Typing speed={100}>
                          {props.data.cloudver.toUpperCase()}
                        </Typing>
                      </Typography>
                    )}
                    {props.data.isp && (
                      <Typography style={{ fontFamily: "orbitron" }}>
                        <Typing speed={100}>
                          {props.data.isp.toUpperCase()}
                        </Typing>
                      </Typography>
                    )}
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            </React.Fragment>
          )}

          {/* start */}

          {props.data.cloudver === "aws" &&
            (props.data.serverhost || props.data.ec2host) && (
              <TimelineItem>
                <TimelineSeparator>
                  {props.data.cloudver && (
                    <React.Fragment>
                      <Button onClick={openPopupCloud}>
                        <TimelineDot variant="outlined" className={classes.dot}>
                          <Tooltip title="Click for Insights">
                            <img src={ec2host} />
                          </Tooltip>
                        </TimelineDot>
                      </Button>
                    </React.Fragment>
                  )}
                  <TimelineConnector className={classes.secondaryTail} />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h6" component="h1">
                      {<Typography>EC2:</Typography>}
                    </Typography>

                    {props.data.cloudver && (
                      <Typography style={{ fontFamily: "orbitron" }}>
                        <Typing speed={100}>
                          {props.data.cloudver.toUpperCase()}
                        </Typing>
                      </Typography>
                    )}
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            )}
          {/* finish */}
          {props.data.runningon && (
            <TimelineItem>
              {props.data.waflocation === "os" && (
                <TimelineOppositeContent>
                  <Typography variant="body2" color="textSecondary">
                    <Tooltip title="Protected by WAF">
                      <img src={FirewallIcon} />
                    </Tooltip>
                  </Typography>
                </TimelineOppositeContent>
              )}
              <TimelineSeparator>
                <Button onClick={openPopup}>
                  <TimelineDot variant="outlined" className={classes.dot}>
                    <Tooltip title="Click for Insights">
                      <img
                        src={`https://bloodhound.securestack.com/images/architecture/${props.data.runningon}.png`}
                      />
                    </Tooltip>
                  </TimelineDot>
                </Button>
                <TimelineConnector
                  variant="outlined"
                  className={classes.secondaryTail}
                />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h6" component="h1">
                    OPERATING SYSTEM:
                  </Typography>
                  <Typography style={{ fontFamily: "orbitron" }}>
                    <Typing speed={100}>
                      {props.data.runningon.toUpperCase()}
                    </Typing>

                    <Grid>
                      {/* <Button
                      variant="outlined"
                      size="small"
                      color="secondary"
                      onClick={() => {}}
                      style={{
                        color: "#ad2007",
                      }}
                    >
                      Insight
                    </Button> */}
                      <Tooltip title="5 Vulnerabilities Found">
                        <ErrorOutlineOutlinedIcon style={{ color: "red" }} />
                      </Tooltip>
                    </Grid>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          )}
          {props.data.webserver && (
            <TimelineItem>
              {props.data.waflocation === "webserver" && (
                <TimelineOppositeContent>
                  <Typography variant="body2" color="textSecondary">
                    <Tooltip title="Protected by WAF">
                      <img src={FirewallIcon} />
                    </Tooltip>
                  </Typography>
                </TimelineOppositeContent>
              )}
              <TimelineSeparator>
                <Button onClick={openPopupWebServer}>
                  <TimelineDot variant="outlined" className={classes.dot}>
                    <Tooltip title="Click for Insights">
                      <img
                        src={`https://bloodhound.securestack.com/images/architecture/${props.data.webserver}.png`}
                      />
                    </Tooltip>
                  </TimelineDot>
                </Button>
                <TimelineConnector className={classes.secondaryTail} />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h6" component="h1">
                    WEBSERVER:
                  </Typography>
                  <Typography style={{ fontFamily: "orbitron" }}>
                    <Typing speed={100}>
                      {props.data.webserver.toUpperCase()}
                    </Typing>
                    <Grid>
                      {/* <Button
                      variant="outlined"
                      size="small"
                      color="secondary"
                      onClick={() => {}}
                      style={{
                        color: "#ad2007",
                      }}
                    >
                      Insight
                    </Button> */}
                      <Tooltip title="4 Vulnerabilities Found">
                        <ErrorOutlineOutlinedIcon style={{ color: "red" }} />
                      </Tooltip>
                    </Grid>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          )}
          {props.data.cmsver && (
            <TimelineItem>
              <TimelineSeparator>
                <Button onClick={openPopup}>
                  <TimelineDot variant="outlined" className={classes.dot}>
                    <Tooltip title="Click for Insights">
                      <img
                        src={`https://bloodhound.securestack.com/images/architecture/${props.data.cmsver}.png`}
                      />
                    </Tooltip>
                  </TimelineDot>
                </Button>

                <TimelineConnector className={classes.secondaryTail} />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h6" component="h1">
                    CMS:
                  </Typography>
                  <Typography style={{ fontFamily: "orbitron" }}>
                    <Typing speed={100}>
                      {props.data.cmsver.toUpperCase()}
                    </Typing>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          )}
          {props.data.poweredby && (
            <TimelineItem>
              <TimelineSeparator>
                <Button onClick={openPopupAppServer}>
                  <TimelineDot variant="outlined" className={classes.dot}>
                    <Tooltip title="Click for Insights">
                      <img
                        src={`https://bloodhound.securestack.com/images/architecture/${props.data.poweredby}.png`}
                      />
                    </Tooltip>
                  </TimelineDot>
                </Button>
                <TimelineConnector className={classes.secondaryTail} />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h6" component="h1">
                    APPLICATION SERVER:
                  </Typography>
                  <Typography style={{ fontFamily: "orbitron" }}>
                    <Typing speed={100}>
                      {props.data.poweredby.toUpperCase()}
                    </Typing>
                    <Grid>
                      {/* <Button
                      variant="outlined"
                      size="small"
                      color="secondary"
                      onClick={() => {}}
                      style={{
                        color: "#ad2007",
                      }}
                    >
                      Insight
                    </Button> */}
                      <Tooltip title="2 Vulnerabilities Found">
                        <ErrorOutlineOutlinedIcon style={{ color: "orange" }} />
                      </Tooltip>
                    </Grid>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          )}
          {props.data.applicationexists && props.data.applicationexists != 0 && (
            <TimelineItem>
              <TimelineSeparator>
                <Button onClick={openPopupApplication}>
                  <TimelineDot variant="outlined" className={classes.dot}>
                    <Tooltip title="Click for Insights">
                      <img src={apps} />
                    </Tooltip>
                  </TimelineDot>
                </Button>
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h6" component="h6">
                    APPLICATIONS LIST
                  </Typography>
                  <Typography style={{ fontFamily: "orbitron" }}>
                    <Typing speed={100}></Typing>
                    <Grid>
                      {/* <Button
                      variant="outlined"
                      size="small"
                      color="secondary"
                      onClick={() => {}}
                      style={{
                        color: "#ad2007",
                      }}
                    >
                      Insight
                    </Button> */}
                      <Tooltip title="2 Vulnerabilities Found">
                        <ErrorOutlineOutlinedIcon style={{ color: "orange" }} />
                      </Tooltip>
                    </Grid>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          )}
        </Timeline>
        <br />

        <Grid>
          {open && (
            <OSInfo data={props.data} firewallInfo={props.firewallInfo} />
          )}
        </Grid>
        <Grid>{openApplication && <Application data={props.data} />}</Grid>
        <Grid>
          {openWebServer && (
            <WebServer data={props.data} firewallInfo={props.firewallInfo} />
          )}
        </Grid>
        <Grid>{openCDN && <CDN data={props.data} />}</Grid>
        <Grid>
          {openAppServer && (
            <AppServer
              data={props.data}
              appser={props.data.poweredby}
              firewallInfo={props.firewallInfo}
            />
          )}
        </Grid>
        <Grid>{openCloud && <Cloud data={props.data} />}</Grid>
        {/* {open && (
        <Grid container>
          <Grid item xs={12}>
            <MapGL
              style={{ width: "100%", height: "400px" }}
              mapStyle="mapbox://styles/bengiov/ckccymu3e2i9l1ild34v6rbkd"
              accessToken={process.env.REACT_APP_MAP_TOKEN}
              latitude={props.data.latitude}
              longitude={props.data.longitude}
              zoom={11}
            />
          </Grid>
        </Grid>
      )} */}
      </Container>
    </div>
  );
}
