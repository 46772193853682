import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import WarningIcon from "@material-ui/icons/Warning";
import ProblemTestData from "./utils/ProblemTestData.json";
import { Typography, Grid, Container, Paper, Button } from "@material-ui/core/";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SingleMurlGauge from "./Gauge/SingleMurlGauge";
import CancelIcon from "@material-ui/icons/Cancel";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.primary,
  },
  paperIcon: {
    textAlign: "left",
  },
  typography: {
    color: theme.palette.text.secondary,
    opacity: 0.4,
  },
  typographyTitle: {
    fontFamily: "Orbitron",
    fontSize: 20,
    padding: theme.spacing(1),
    color: theme.palette.text.primary,
    paddingBottom: "10px",
  },
}));
export default function ScoreCard() {
  const classes = useStyles();
  return (
    <div>
      <Paper elevation={0} className={classes.paper}>
        <Grid container spacing={2}>
          <>
            <Grid item xs={10}>
              <Paper elevation={0} className={classes.paper}>
                <Typography>Supports Encryption</Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper elevation={0} className={classes.paperIcon}>
                <CancelIcon style={{ color: "#ad2007" }} />
              </Paper>
            </Grid>

            <Grid item xs={10}>
              <Paper elevation={0} className={classes.paper}>
                <Typography>Overall Scalability</Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper elevation={0} className={classes.paperIcon}>
                <CancelIcon style={{ color: "#ad2007" }} />
              </Paper>
            </Grid>

            <Grid item xs={10}>
              <Paper elevation={0} className={classes.paper}>
                <Typography>Web Application Firewall</Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper elevation={0} className={classes.paperIcon}>
                <CheckCircleIcon style={{ color: "green" }} />
              </Paper>
            </Grid>
            <Grid item xs={10}>
              <Paper elevation={0} className={classes.paper}>
                <Typography className={classes.typography}>
                  Public Attack Surface
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper elevation={0} className={classes.paperIcon}>
                <CheckCircleIcon style={{ color: "green", opacity: 0.2 }} />
              </Paper>
            </Grid>
          </>
        </Grid>
      </Paper>
    </div>
  );
}
