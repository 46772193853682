const getTimeoutData = async (token, scanreference) => {
  console.log("token from getTimeoutData" + token);
  console.log("scanreference from getTimeoutData" + scanreference);
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/logsdbtimeoutdata/${scanreference}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    }
  );
  const responseData = await response.json();
  return responseData;
};
export default getTimeoutData;
