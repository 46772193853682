import React, { useState, useEffect } from "react";
import { useAuth0 } from "../react-auth0-spa";
import getStatsDB from "../functions/getStatsDB";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Paper, Typography, Container, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ReactGA from "react-ga";
import mixpanel from "mixpanel-browser";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import getPriorscan from "../functions/getPriorscan";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function History() {
  const classes = useStyles();

  const { isAuthenticated, getTokenSilently, user } = useAuth0();
  const [progress, setProgress] = useState(true);
  const [data, setData] = useState(false);
  const [init, setInit] = React.useState(true);
  const [searchUpdate, setSearchUpdate] = React.useState(false);
  const [hostname, setHostname] = useState({
    value: "",
    error: true,
    errorMsg: "Invalid Name",
  });

  useEffect(() => {
    {
      isAuthenticated && init && getScanHistory();
      {
        console.log(data);
      }
    }
  });
  const searchMurl = async (e, value, reason) => {
    console.log(value);
    console.log(reason);
    if (value && value !== null) {
      setSearchUpdate(value);
    } else {
      setSearchUpdate(false);
    }
  };
  const getScanHistory = async () => {
    localStorage.removeItem("priorscanref");
    localStorage.removeItem("priorscanrefdomains");
    setProgress(true);
    const token = await getTokenSilently();
    console.log("THIS IS returned Token FROM Get" + JSON.stringify(token));
    const resGetDB = await getStatsDB(user.email, token);
    console.log(resGetDB);
    setData(resGetDB);
    setProgress(false);
    setInit(false);
  };
  const setlocalstorage = async () => {};
  const getDomainsRef = async (item) => {
    localStorage.setItem("scanref", item.scanreference);
    const token = await getTokenSilently();
    const priorscanrefdomains = await getPriorscan(item.scanreference, token);
    console.log(priorscanrefdomains.priorscanref);

    localStorage.setItem(
      "priorscanrefdomains",
      priorscanrefdomains.priorscanref
    );
    mixpanel.track("Scan history report selected");
    ReactGA.event({
      category: "History",
      action: "Opened a history report",
    });
  };
  return (
    <Paper elevation={0}>
      <Paper evelation={15}>
        {data === false ? (
          <Paper className={classes.paper}>
            <Typography
              style={{
                height: 300,
                textAlign: "center",
                fontSize: "20px",
                fontFamily: "courier",
              }}
            >
              <CircularProgress style={{ color: "#ad2007" }} />
              <Alert severity="error">
                Please allow up to 15 minutes for this scan to take effect
              </Alert>
            </Typography>
          </Paper>
        ) : (
          <div>
            {
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Autocomplete
                          freeSolo
                          onChange={searchMurl}
                          options={Object.values(data).map(
                            (option) => option.murl
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search Target"
                              margin="normal"
                              variant="outlined"
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="h4" component="h4">
                          <strong> Date</strong>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="h4" component="h4">
                          <strong> Time</strong>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="h4" component="h4">
                          <strong> Final Score</strong>
                        </Typography>
                      </TableCell>

                      <TableCell align="center">
                        <Typography variant="h4" component="h4">
                          <strong> Report</strong>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {searchUpdate ? (
                    <TableBody>
                      {Object.values(data).map((item, index) => (
                        <TableRow key={index}>
                          {item.murl === searchUpdate && (
                            <React.Fragment>
                              <TableCell component="th" scope="row">
                                <Typography
                                  style={{ color: "", fontFamily: "courier" }}
                                  variant="h5"
                                  component="h5"
                                >
                                  {item.murl}
                                </Typography>
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Typography
                                  align="center"
                                  style={{ color: "", fontFamily: "courier" }}
                                  variant="h5"
                                  component="h5"
                                >
                                  {item.scannedtime.substring(
                                    0,
                                    item.scannedtime.length - 14
                                  )}
                                </Typography>
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Typography
                                  align="center"
                                  style={{ color: "", fontFamily: "courier" }}
                                  variant="h5"
                                  component="h5"
                                >
                                  {item.scannedtime.substring(16, 11)}
                                </Typography>
                              </TableCell>

                              <TableCell align="center">
                                {(parseInt(item.finalscore) >= 50 &&
                                  parseInt(item.finalscore) < 80) ||
                                (parseInt(searchUpdate.finalscore) >= 50 &&
                                  parseInt(searchUpdate.finalscore) < 80) ? (
                                  <>
                                    <Typography
                                      style={{
                                        color: "#eb7d34",
                                        fontFamily: "Orbitron",
                                      }}
                                      variant="h4"
                                      component="h4"
                                    >
                                      {item.finalscore}
                                    </Typography>
                                  </>
                                ) : (
                                  parseInt(item.finalscore) > 80 && (
                                    <>
                                      <Typography
                                        style={{
                                          color: "#42f542",
                                          fontFamily: "Orbitron",
                                        }}
                                        variant="h4"
                                        component="h4"
                                      >
                                        {item.finalscore}
                                      </Typography>
                                    </>
                                  )
                                )}
                                {parseInt(item.finalscore) < 50 && (
                                  <>
                                    <Typography
                                      style={{
                                        color: "red",
                                        fontFamily: "Orbitron",
                                      }}
                                      variant="h4"
                                      component="h4"
                                    >
                                      {item.finalscore}
                                    </Typography>
                                  </>
                                )}
                              </TableCell>
                              <TableCell>
                                <Grid item xs>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{ background: "#ad2007" }}
                                    size="small"
                                    href="/bloodhound"
                                    onClick={() => {
                                      getDomainsRef(item);
                                    }}
                                  >
                                    Get Report
                                  </Button>
                                </Grid>
                              </TableCell>
                            </React.Fragment>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <TableBody>
                      {Object.values(data).map((row, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            <Typography
                              style={{ color: "", fontFamily: "courier" }}
                              variant="h5"
                              component="h5"
                            >
                              {row.murl}
                            </Typography>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Typography
                              align="center"
                              style={{ color: "", fontFamily: "courier" }}
                              variant="h5"
                              component="h5"
                            >
                              {row.scannedtime.substring(
                                0,
                                row.scannedtime.length - 14
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Typography
                              align="center"
                              style={{ color: "", fontFamily: "courier" }}
                              variant="h5"
                              component="h5"
                            >
                              {row.scannedtime.substring(16, 11)}
                            </Typography>
                          </TableCell>

                          <TableCell align="center">
                            {parseInt(row.finalscore) > 50 &&
                            parseInt(row.finalscore) < 80 ? (
                              <>
                                <Typography
                                  style={{
                                    color: "#eb7d34",
                                    fontFamily: "Orbitron",
                                  }}
                                  variant="h4"
                                  component="h4"
                                >
                                  {row.finalscore}
                                </Typography>
                              </>
                            ) : (
                              parseInt(row.finalscore) > 80 && (
                                <>
                                  <Typography
                                    style={{
                                      color: "#42f542",
                                      fontFamily: "Orbitron",
                                    }}
                                    variant="h4"
                                    component="h4"
                                  >
                                    {row.finalscore}
                                  </Typography>
                                </>
                              )
                            )}
                            {parseInt(row.finalscore) < 50 && (
                              <>
                                <Typography
                                  style={{
                                    color: "red",
                                    fontFamily: "Orbitron",
                                  }}
                                  variant="h4"
                                  component="h4"
                                >
                                  {row.finalscore}
                                </Typography>
                              </>
                            )}
                          </TableCell>
                          <TableCell>
                            <Grid item xs>
                              <Button
                                variant="contained"
                                color="secondary"
                                style={{ background: "#ad2007" }}
                                size="small"
                                href="/bloodhound"
                                onClick={() => {
                                  getDomainsRef(row);
                                }}
                              >
                                Get Report
                              </Button>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            }
          </div>
        )}
      </Paper>
      <br />
      <br />
    </Paper>
  );
}
