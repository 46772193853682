const getChangesTimeline = async (murl, email, token) => {
  console.log("THIS IS murl: " + murl);
  console.log("THIS IS email: " + email);

  console.log("THIS IS token: " + token);

  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/logsDBScanrefQuickScan/${murl}/${email}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    }
  );
  const responseData = await response.json();
  console.log(responseData);
  return responseData;
};

export default getChangesTimeline;
