import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

import { Button, Grid, Paper, Container } from "@material-ui/core/";
function SecLine(props) {
  const state = {
    series: [
      {
        name: "HSTS",
        data: props.chartData.hsts,
        //data: [1, 1, 0, 1, 0, 1, 1, 1, 0, 0, 1, 0, 1, 0, 0, 0],
      },
      {
        name: "CSP ",
        data: props.chartData.csp,
        // data: [0, 0, 0, 0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 0, 0, 0],
      },
      {
        name: "HTTP REDIRECT",
        data: props.chartData.redirect,
        // data: [0, 3, 3, 0, 0, 3, 3, 0, 3, 3, 0, 3, 3, 0, 3, 3],
      },
      {
        name: "WAF",
        data: props.chartData.waf,
        // data: [4, 0, 4, 4, 4, 4, 4, 4, 0, 0, 4, 0, 0, 0, 0, 0],
      },
      {
        name: "Firewall",
        data: props.chartData.firewall,
        // data: [5, 5, 5, 5, 5, 0, 0, 0, 0, 0, 5, 0, 0, 5, 5, 5],
      },
      {
        name: "Scalabitity",
        data: props.chartData.scalability,
        // data: [0, 0, 0, 0, 0, 6, 6, 6, 6, 6, 6, 6, 0, 0, 0, 6],
      },
      {
        name: "Policy",
        data: props.chartData.policy,
        //data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 7, 7, 7, 7, 7],
      },
    ],
    options: {
      chart: {
        height: 1000,

        type: "line",
        zoom: {
          enabled: true,
        },
        animations: {
          enabled: true,
        },
      },
      stroke: {
        width: [3, 3, 3, 3, 3, 3, 3],
        curve: "straight",
      },
      labels: {},
      dataLabels: {
        enabled: false,
      },
      labels: props.chartData.time,
      title: {
        text: `Comparision of Scans: ${
          props.murl ? props.murl : "Please select a Target..."
        }`,
      },
      xaxis: {},
      yaxis: {
        tickAmount: 7,
        labels: {
          maxWidth: 500,

          offsetX: 25,
          style: {
            fontSize: 10,
            colors: [],
          },
          formatter: function (val, index) {
            console.log(val);
            if (val === 1) {
              return "HSTS";
            }
            if (val === 2) {
              return "CSP";
            }
            if (val === 3) {
              return "HTTP REDIRECT";
            }
            if (val === 4) {
              return "SCALABILITY";
            }
            if (val === 5) {
              return "WAF";
            }
            if (val === 6) {
              return "FIREWALL";
            }
            if (val === 7) {
              return "POLICY";
            } else {
              if (val === 0) {
                return "OFF";
              }
            }
          },
          offsetY: 20,
          offsetX: 40,
        },
      },
    },
  };

  return (
    <div>
      <div id="card">
        <div id="chart">
          <Grid
            container
            style={{ backgroundColor: "#FFFFFF", color: "black" }}
          >
            <Grid item xs={12}>
              <Chart
                options={state.options}
                series={state.series}
                type="line"
                height={750}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
export default SecLine;
