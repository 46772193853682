import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, Typography, Box } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { ProgressBar } from "react-bootstrap";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import ScoreCardGraphs from "./Charts/Gauge/ScoreCardGraphs";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "transparent",
  },
  typography: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  bargraph: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  hidegrid: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

export default function ScoreCardMobile(props) {
  const classes = useStyles();
  return (
    <div className={classes.hidegrid}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            elevation={0}
            style={{
              textAlign: "center",
              backgroundColor: "transparent",
            }}
          >
            <Typography
              className={classes.typography}
              variant="h4"
              component="h4"
            >
              <strong> SCORECARD FOR</strong>
            </Typography>
            <Typography
              className={classes.typography}
              variant="h4"
              component="h4"
              style={{ fontFamily: "Orbitron" }}
            >
              {props.data.murl}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <br />
      <br />
      <Grid container spacing={1}>
        <Grid item md={4} xs={6}>
          <Paper elevation={0} className={classes.paper}>
            <Typography
              className={classes.typography}
              variant="h5"
              component="h5"
            >
              <strong> WHAT WAS TESTED:</strong>
            </Typography>
          </Paper>
        </Grid>
        <Grid item md={4} xs={6}>
          <Paper elevation={0} className={classes.paper}>
            <Typography
              className={classes.typography}
              variant="h5"
              component="h5"
            >
              <strong> ARE YOU PROTECTED?</strong>
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <br />
      <Grid container spacing={3}>
        <Grid item md={4} xs={6}>
          <Tooltip title="Target supports SSL/TLS and redirects insecure traffic to secure site">
            <Paper elevation={0} className={classes.paper}>
              <Typography
                className={classes.typography}
                style={{ fontFamily: "Orbitron" }}
                variant="h6"
                component="h6"
              >
                <strong> Supports Encryption </strong>
              </Typography>
            </Paper>
          </Tooltip>
        </Grid>
        <Grid item md={4} xs={6}>
          <Paper elevation={0} className={classes.paper}>
            {props.data.https_redirect === "1" ? (
              <CheckIcon style={{ color: "#00FF00", fontSize: 50 }} />
            ) : (
              <ClearIcon style={{ color: "#FF1A1A", fontSize: 50 }} />
            )}
          </Paper>
        </Grid>

        <Grid item md={4} xs={6}>
          <Tooltip title="Supports HSTS and there are no insecure images or content in the target">
            <Paper elevation={0} className={classes.paper}>
              <Typography
                style={{ fontFamily: "Orbitron" }}
                className={classes.typography}
                variant="h6"
                component="h6"
              >
                <strong>All Traffic and Content is Encrypted</strong>
              </Typography>
            </Paper>
          </Tooltip>
        </Grid>
        <Grid item md={4} xs={6}>
          <Paper elevation={0} className={classes.paper}>
            {props.data.hsts === "1" ? (
              <CheckIcon style={{ color: "#00FF00", fontSize: 50 }} />
            ) : (
              <ClearIcon style={{ color: "#FF1A1A", fontSize: 50 }} />
            )}
          </Paper>
        </Grid>

        <Grid item md={4} xs={6}>
          <Tooltip title="Target supports elasticity, caching, load balancing and/or CDN">
            <Paper elevation={0} className={classes.paper}>
              <Typography
                className={classes.typography}
                style={{ fontFamily: "Orbitron" }}
                variant="h6"
                component="h6"
              >
                <strong>Overall Scalability</strong>
              </Typography>
            </Paper>
          </Tooltip>
        </Grid>
        <Grid item md={4} xs={6}>
          <Paper elevation={0} className={classes.paper}>
            {props.data.loadbalanced === "1" ? (
              <CheckIcon style={{ color: "#00FF00", fontSize: 50 }} />
            ) : (
              <ClearIcon style={{ color: "#FF1A1A", fontSize: 50 }} />
            )}
          </Paper>
        </Grid>

        <Grid item md={4} xs={6}>
          <Tooltip title="WAF protects your target from attacks and hidden vulnerabilities in your code">
            <Paper elevation={0} className={classes.paper}>
              <Typography
                className={classes.typography}
                style={{ fontFamily: "Orbitron" }}
                variant="h6"
                component="h6"
              >
                <strong>Web Application Firewall</strong>
              </Typography>
            </Paper>
          </Tooltip>
        </Grid>
        <Grid item md={4} xs={6}>
          <Paper elevation={0} className={classes.paper}>
            {props.data.waf === "1" ? (
              <CheckIcon style={{ color: "#00FF00", fontSize: 50 }} />
            ) : (
              <ClearIcon style={{ color: "#FF1A1A", fontSize: 50 }} />
            )}
          </Paper>
        </Grid>

        <Grid item md={4} xs={6}>
          <Tooltip title="SSH, RDP or Database ports are open">
            <Paper elevation={0} className={classes.paper}>
              <Typography
                className={classes.typography}
                style={{ fontFamily: "Orbitron" }}
                variant="h6"
                component="h6"
              >
                <strong>Public Attack Surface</strong>
              </Typography>
            </Paper>
          </Tooltip>
        </Grid>

        <Grid item md={4} xs={6}>
          <Paper elevation={0} className={classes.paper}>
            {console.log("3306 - port===>" + props.data.port3306)}
            {props.data.port22 === "0" &&
            props.data.port3389 === "0" &&
            props.data.port3306 === "0" ? (
              <CheckIcon style={{ color: "#00FF00", fontSize: 50 }} />
            ) : (
              <ClearIcon style={{ color: "#FF1A1A", fontSize: 50 }} />
            )}
          </Paper>
        </Grid>

        <Grid item md={4} xs={6}>
          <Tooltip title="Target uses security headers to add additional layers of security">
            <Paper elevation={0} className={classes.paper}>
              <Typography
                className={classes.typography}
                style={{ fontFamily: "Orbitron" }}
                variant="h6"
                component="h6"
              >
                <strong>Webserver Optimization</strong>
              </Typography>
            </Paper>
          </Tooltip>
        </Grid>
        <Grid item md={4} xs={6}>
          <Paper elevation={0} className={classes.paper}>
            {props.data.cspdatafull === "0" ? (
              <ClearIcon style={{ color: "#FF1A1A", fontSize: 50 }} />
            ) : (
              <CheckIcon style={{ color: "#00FF00", fontSize: 50 }} />
            )}
          </Paper>
        </Grid>

        <Grid item md={4} xs={6}>
          <Tooltip title="Target has a referrer and a privacy policy">
            <Paper elevation={0} className={classes.paper}>
              <Typography
                className={classes.typography}
                style={{ fontFamily: "Orbitron" }}
                variant="h6"
                component="h6"
              >
                <strong>Enforces Privacy</strong>
              </Typography>
            </Paper>
          </Tooltip>
        </Grid>
        <Grid item md={4} xs={6}>
          <Paper elevation={0} className={classes.paper}>
            {props.data.referrerpolicy === "1" ? (
              <CheckIcon style={{ color: "#00FF00", fontSize: 50 }} />
            ) : (
              <ClearIcon style={{ color: "#FF1A1A", fontSize: 50 }} />
            )}
          </Paper>
        </Grid>

        <Grid item md={4} xs={6}>
          <Tooltip title="Target enforces data sovereignty">
            <Paper elevation={0} className={classes.paper}>
              <Typography
                className={classes.typography}
                style={{ fontFamily: "Orbitron" }}
                variant="h6"
                component="h6"
              >
                <strong>Sovereignty</strong>
              </Typography>
            </Paper>
          </Tooltip>
        </Grid>
        <Grid item md={4} xs={6}>
          <Paper elevation={0} className={classes.paper}>
            {props.data.sovereignissue === "0" &&
            props.data.nocdnsovereignissue === "0" ? (
              <CheckIcon style={{ color: "#00FF00", fontSize: 50 }} />
            ) : (
              <ClearIcon style={{ color: "#FF1A1A", fontSize: 50 }} />
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
