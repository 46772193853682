import React from "react";
import { Typography, Grid, Container, Paper } from "@material-ui/core/";
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.primary,
  },

  typography: {
    fontFamily: "Courier New",
    fontSize: 15,
  },
  header: {
    fontFamily: "Orbitron",
    fontSize: 20,
  },
  subtitle: {
    color: theme.palette.text.secondary,
    fontSize: 15,
  },
  typographyValue: {
    textAlign: "right",
    padding: theme.spacing(1),

    color: "#ad2007",
  },
}));

export default function Summary() {
  const classes = useStyles();

  return (
    <div>
      <Paper className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography className={classes.header}>SUMMARY</Typography>
          </Grid>
          <Grid item xs={3}>
            <Paper elevation={0} className={classes.paper}>
              <Typography>Apps scanned </Typography>

              <Typography className={classes.subtitle}>90% of total</Typography>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper elevation={0} className={classes.typographyValue}>
              <Typography className={classes.typography}>3</Typography>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper elevation={0} className={classes.paper}>
              <Typography>Changes over 30 days </Typography>
              <Typography className={classes.subtitle}>up 2%</Typography>
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper elevation={0} className={classes.typographyValue}>
              <Typography className={classes.typography}>10</Typography>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper elevation={0} className={classes.paper}>
              <Typography> Managed Endpoints</Typography>
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper elevation={0} className={classes.typographyValue}>
              <Typography className={classes.typography}>25</Typography>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper elevation={0} className={classes.paper}>
              <Typography> Drift</Typography>
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper elevation={0} className={classes.typographyValue}>
              <Typography className={classes.typography}>10%</Typography>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper elevation={0} className={classes.paper}>
              <Typography> Managed Assets</Typography>
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper elevation={0} className={classes.typographyValue}>
              <Typography className={classes.typography}>30</Typography>
            </Paper>
          </Grid>
          <Grid item xs={5}>
            <Paper elevation={0} className={classes.paper}>
              <Typography> Average Endpoint Score</Typography>
              <Typography className={classes.subtitle}>Up 2%</Typography>
            </Paper>
          </Grid>
          <Grid item xs={1}>
            <Paper elevation={0} className={classes.typographyValue}>
              <Typography className={classes.typography}>75</Typography>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
