import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Typography, Button } from "@material-ui/core";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "justify",
    color: theme.palette.text.secondary,
  },
  dot: {
    backgroundColor: "white",

    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.2)",
    },
  },
}));

function CDN(props) {
  const classes = useStyles();

  function Details(props) {
    return (
      <React.Fragment>
        {props.data.waflocation === "cdn" && (
          <Grid item xs={12}>
            <Paper elevation={0} className={classes.paper}>
              <Typography
                style={{ fontFamily: "courier" }}
                variant="h6"
                component="h6"
              >
                Protected by:
                <strong>{props.data.wafoutput}</strong>
              </Typography>
            </Paper>
          </Grid>
        )}
      </React.Fragment>
    );
  }
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <Timeline align="alternate">
            <TimelineItem>
              <TimelineDot variant="outlined" className={classes.dot}>
                <img
                  src={`https://bloodhound.securestack.com/images/architecture/${props.data.cdnver}.png`}
                />
              </TimelineDot>
            </TimelineItem>
          </Timeline>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid container item xs={4} spacing={2}>
          <Details data={props.data} />
        </Grid>
        <Grid container item xs={1} spacing={2}></Grid>
        <Grid container item xs={4} spacing={2}></Grid>

        <Grid container item xs={3} spacing={2}></Grid>
      </Grid>
    </div>
  );
}

export default CDN;
