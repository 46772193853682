import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Button, Tooltip, Typography } from "@material-ui/core";
import { useAuth0 } from "../../../react-auth0-spa";
import mixpanel from "mixpanel-browser";
import ReactGA from "react-ga";

import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";

import Popover from "@material-ui/core/Popover";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SolutionInfoData from "../../Data/SolutionInfoData";
import SolutionInfo from "../../TabComp/Solution/SolutionInfo";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

export default function SolutionButton(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);

  const { user } = useAuth0();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {props.fix === "CSP" || props.fix === "HSTS" || 
      props.fix === "numberunecryptedpages" || 
      props.fix==="referrerpolicy" || props.fix==="httpsredirect" ||
      props.fix==="LOADBALANCER" || props.fix==="waf"||
      props.fix==="ssh" || props.fix==="wrd" || props.fix==="dbserver" ||
      props.fix==="sslissue" || props.fix==="windows_aws" || props.fix==="linux_aws"
        ? (
        <div> 
          <Button
            aria-owns={openPopover ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            variant="outlined"
            size="small"
            fullWidth
            color="primary"
            onClick={handleClickOpen}
            style={{
              color: "#006bff",
            }}
          >
            FIX
          </Button>

          <Popover
            id="mouse-over-popover"
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            open={openPopover}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            Click for Solution.
          </Popover>

          <Dialog
            maxWidth="lg"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title"></DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {props.fix === "CSP" && (
                  <SolutionInfo data={SolutionInfoData.CSP} />
                )}
                {props.fix === "HSTS" && (
                  <SolutionInfo data={SolutionInfoData.HSTS} />
                )}
                {props.fix === "numberunecryptedpages" && (
                  <SolutionInfo data={SolutionInfoData.UNENCRYPTEDPAGES} />
                )}
                {props.fix === "referrerpolicy" && (
                  <SolutionInfo data={SolutionInfoData.REFERRERPOLICY}/>
                )}
                {props.fix==="httpsredirect" && (
                  <SolutionInfo data={SolutionInfoData.HTTPSREDIRECT}/>
                )}
                 {props.fix==="loadbalancer" && (
                  <SolutionInfo data={SolutionInfoData.LOADBALANCER}/>
                )}
                 {props.fix==="waf" && (
                  <SolutionInfo data={SolutionInfoData.WAF}/>
                )}
                {props.fix==="ssh" && (
                  <SolutionInfo data={SolutionInfoData.SSH}/>
                )}
                {props.fix==="wrd" && (
                  <SolutionInfo data={SolutionInfoData.WRD}/>
                )}
                {props.fix==="dbserver" && (
                  <SolutionInfo data={SolutionInfoData.DBSERVER}/>
                )}
                {props.fix==="sslissue" && (
                  <SolutionInfo data={SolutionInfoData.SSLISSUE}/>
                )}
                 {props.fix==="windows_aws" && (
                  <SolutionInfo data={SolutionInfoData.WINDOWS_AWS}/>
                )}
                {props.fix==="linux_aws" && (
                  <SolutionInfo data={SolutionInfoData.LINUX_AWS}/>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                size="large"
                onClick={handleClose}
                color="primary"
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <Button
          href={`https://demolab.securestack.com/SignUpBloodhound/${user.email}`}
          variant="outlined"
          fullWidth
          size="small"
          color="primary"
          onClick={() => {
            mixpanel.track(
              `${user.email} Clicked on FIX for ${props.description}`
            );
            ReactGA.event({
              // category: `${user.email}`,
              category: `Solutions`,
              action: `Clicked on FIX for ${props.description}`,
            });
          }}
          style={{ color: "#006bff" }}
        >
          FIX
        </Button>
      )}
    </div>
  );
}
