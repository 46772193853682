const postDataDB = async (email, hostname, token) => {
  console.log("THIS IS EMAIL: " + email);
  console.log("THIS IS hostname: " + hostname);
  console.log("THIS IS token: " + token);

  const random = Math.random();
  const min = 1;
  const max = 1000;
  const rand = min + Math.random() * (max - min);
  console.log(random + rand);
  localStorage.setItem("scanref", random + rand);

  let responseData = false;

  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/bloodhoundquick`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        hostname: `${hostname}`,
        scanref: `${random + rand}`,
        email: `${email}`,
      }),
    }
  )
    .then((rows) => {
      responseData = rows.json();
      console.log(responseData);
    })
    .catch(function (err) {
      console.log(err);
      return err;
    });

  console.log(response);
  return responseData;
};
export default postDataDB;
