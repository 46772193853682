import React from "react";
import {
  Avatar,
  Button,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ButtonAppBarCollapse from "./ButtonAppBarCollapse";
import { useAuth0 } from "../../react-auth0-spa";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "absolute",
    left: 0,
  },
  buttonBar: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    margin: "10px",
    paddingLeft: "60px",
    left: 0,
    position: "relative",
    width: "100%",
    background: "transparent",
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  button: {
    marginRight: "auto",
  },
  title: {
    flexGrow: 1,
  },
  loginButton: {
    marginLeft: "auto",
  },
}));

export default function AppBarCollapse() {
  const { isAuthenticated } = useAuth0();

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ButtonAppBarCollapse>
        <MenuItem>
          <Button style={{ color: "#ad2007" }} href="/">
            <Typography
              style={{ color: "#ad2007" }}
              variant="h7"
              component="h7"
            >
              <strong>Bloodhound</strong>
            </Typography>
          </Button>
        </MenuItem>
        <MenuItem>
          <Button style={{ color: "#ad2007" }} href="/ScanHistory">
            <Typography
              style={{ color: "#ad2007" }}
              variant="h7"
              component="h7"
            >
              <strong>ScanHistory</strong>
            </Typography>
          </Button>
        </MenuItem>
        <MenuItem>
          <Button style={{ color: "#ad2007" }} href="/about">
            <Typography
              style={{ color: "#ad2007" }}
              variant="h7"
              component="h7"
            >
              {" "}
              <strong> About</strong>
            </Typography>
          </Button>
        </MenuItem>
      </ButtonAppBarCollapse>
      <div className={classes.buttonBar} id="appbar-collapse">
        <Button style={{ color: "#ad2007" }} href="/">
          <Typography style={{ color: "#ad2007" }} variant="h6" component="h6">
            {" "}
            <strong>Bloodhound</strong>
          </Typography>
        </Button>
        {isAuthenticated && (
          <Button style={{ color: "#ad2007" }} href="/ScanHistory">
            <Typography
              style={{ color: "#ad2007" }}
              variant="h6"
              component="h6"
            >
              {" "}
              <strong>ScanHistory</strong>
            </Typography>
          </Button>
        )}
        <Button style={{ color: "#ad2007" }} href="/about">
          <Typography style={{ color: "#ad2007" }} variant="h6" component="h6">
            {" "}
            <strong> About</strong>
          </Typography>
        </Button>
      </div>
    </div>
  );
}
