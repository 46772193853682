// src/views/ExternalApi.js
import React, { useState, useEffect } from "react";
import mixpanel from "mixpanel-browser";
import { Grid, Paper, MenuItem, Container } from "@material-ui/core";
import PostFreeUser from "../components/API/PostFreeUser";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
//import MapGL from "@urbica/react-map-gl";
//import "mapbox-gl/dist/mapbox-gl.css";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Typography, Link, Button, ButtonBase } from "@material-ui/core";
import PrivateRoute from "../components/PrivateRoute";
import Box from "@material-ui/core/Box";
import ResponsiveImg from "../components/ResponsiveImg";
import OverviewScore from "../components/TabComp/Overview/OverviewScore";
import ServicesComp from "../components/ServicesComp";
import Solutions from "../components/TabComp/Solution/Solutions";
import Analytics from "../components/TabComp/Overview/TechStackAnalytics";
import getDataDB from "../functions/getDataDB";
import getFirewallDB from "../functions/getFirewallDB";
import updateDataDB from "../functions/updateDataDB";
import getProblemsDB from "../functions/getProblemsDB";
import getTechstackDB from "../functions/getTechstackDB";
import getRecentChanges from "../functions/getRecentChanges";
import StateChange from "../components/TabComp/Changes/StateChange";
import getGradesDB from "../functions/getGradesDB";
import getDNSRec from "../functions/getDNSRec";
import getDNSDB from "../functions/getDNSDB";
import { useAuth0 } from "../react-auth0-spa";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import FirewallReport from "../components/TabComp/Firewall/FirewallReport";
import DNS from "../components/TabComp/DNS/DNS";
import socketIOClient from "socket.io-client";
import ProgressBarc from "../components/ProgressBarc";
import ProblemFound from "../components/ProblemFound";
import LegalPopUp from "../components/LegalPopUp";
import FullScoreCard from "../components/TabComp/Overview/FullScoreCard";
import ReactGA from "react-ga";
import TimerIcon from "@material-ui/icons/Timer";
import ArchitectureTimeline from "../components/TabComp/Architecture/ArchitectureTimeline";
import SolutionInfoData from "../components/Data/SolutionInfoData";
import { AiFillPropertySafety } from "react-icons/ai";
import NoScanrefAlert from "../components/NoScanrefAlert";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}
function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  buttonBar: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    margin: "10px",
    paddingLeft: "60px",
    left: 0,
    position: "relative",
    width: "100%",
    background: "transparent",
  },
  verticalBar: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    margin: "50px",
    boxShadow: "none",
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  button: {
    marginRight: "auto",
  },
  image: {
    position: "relative",
    height: 100,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundPosition: "center 40%",
  },

  imageBackdrop: {
    position: "absolute",
    color: "#ffffff",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${
      theme.spacing(1) + 6
    }px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

//const ENDPOINT = "http://3.25.69.68:3001";
const ENDPOINT = "https://bloodhoundapi.securestack.com/";

const Bloodhoundmain = (props) => {
  const socket = socketIOClient(ENDPOINT);
  const scanref = localStorage.getItem("scanref");
  const priorscanref = localStorage.getItem("priorscanref");
  const priorscanrefdomains = localStorage.getItem("priorscanrefdomains");
  console.log("domains scanref from " + priorscanrefdomains);
  console.log(scanref);

  const loadDataOnce = () => {
    const { solutionClick } = props.location.state
      ? props.location.state
      : false;
    setValue(solutionClick);
  };
  // const { solutionClick } = props.location.state ? props.location.state : false;

  const [socketData, setSocketData] = useState(false);
  const [data, setData] = useState(false);
  const [dnsdata, setDNSData] = useState(false);
  const [firewallData, setFirewallData] = useState(false);
  const [problemsData, setProblemsData] = useState(false);
  const [techstackData, setTechstackData] = useState(false);
  const [gradesData, setGradesData] = useState(false);
  const [otherdomainsData, setOtherDomainsData] = useState(false);
  const [progress, setProgress] = useState(false);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { isAuthenticated, user, getTokenSilently } = useAuth0();
  const [open, setOpen] = React.useState(true);
  const [result, setResults] = React.useState(true);
  const [DNSRec, setDNSRecData] = React.useState(false);
  const [emailUpdate, setEmailUpdate] = React.useState(false);
  const [time, setTime] = React.useState("");
  const [changes, setChanges] = useState(false);
  const [noScanrefAlert, setNoScanrefAlert] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("scanref") === null) {
      console.log("no scanref");
      setNoScanrefAlert(true);
    }
    socket.on(`${localStorage.getItem("scanref")}`, receivedData);
    // {
    //   solutionClick && setValue(solutionClick);

    //socket.emit('CheckIfFinished', "test")
  });

  const scanData = [
    { name: "internet", title: "ISP" },
    { name: "cloudver", title: "CLOUD" },
    { name: "cmsver", title: "CMS" },
    { name: "runningon", title: "RUNNINGON" },
    { name: "cdnver", title: "CDN" },
    { name: "webserver", title: "WEBSERVER" },
    { name: "poweredby", title: "POWEREDBY" },
    { name: "protectedby", title: "PROTECTEDBY" },
  ];
  const images = [
    {
      //url:`${dog}`,
      title: "SOLUTIONS",
      width: "30%",
      // titlelogin: "FREE FULL REPORT",
    },
  ];
  //socket function
  const receivedData = (recdata) => {
    const recData = JSON.parse(recdata);
    console.log("localstorage Item scanref" + localStorage.getItem("scanref"));
    console.log("received data from websocket" + recData.scanref);

    if (scanref === recData.scanref) {
      console.log("Background Scan is finished" + recData.finished);
      console.log(JSON.parse(recdata));
      setSocketData(JSON.parse(recdata));
      console.log(recdata);
      socket.off("data", receivedData);
      async function getDNSDATA() {
        const token = await getTokenSilently();
        //get DNS
        const resDNS = await getDNSDB(
          token,
          priorscanrefdomains
            ? localStorage.getItem("priorscanrefdomains")
            : localStorage.getItem("scanref")
        );
        console.log(resDNS);
        setDNSData(resDNS);
        //Get Firewall
        const resfirewallDB = await getFirewallDB(
          localStorage.getItem("scanref"),
          token
        );
        console.log(resfirewallDB);
        setFirewallData(resfirewallDB);
        //Get TechStack
        const resTechStackDB = await getTechstackDB(
          localStorage.getItem("scanref"),
          token
        );
        console.log(resTechStackDB);
        if (typeof resTechStackDB != "undefined" && resTechStackDB.length > 0) {
          setTechstackData(resTechStackDB);
        }
        //Get Grades
        const resGradesDB = await getGradesDB(
          localStorage.getItem("scanref"),
          token
        );
        console.log(resGradesDB);
        if (typeof resGradesDB != "undefined") {
          setGradesData(resGradesDB);
        }
        // Get DNSRECORD
        const resDNSRecord = await getDNSRec(
          localStorage.getItem("scanref"),
          token
        );
        console.log(resDNSRecord);
        if (typeof resDNSRecord != "undefined" && resDNSRecord.length > 0) {
          setDNSRecData(resDNSRecord);
        }
        //*********** */
        //******** */
        //Get OtherDomains
        // const resOtherDomainsDB = await getOtherDomainsDB(
        //   dnsdata[0].scanreference,
        //   token
        //   )
        //   console.log(resOtherDomainsDB)
        //   if(typeof resOtherDomainsDB !== "undefined" && resOtherDomainsDB.length >= 0){
        //     setOtherDomainsData(resOtherDomainsDB)
        //   }
        //******** */
      }
      getDNSDATA();
    }
  };
  console.log(socketData);

  //End Socket function

  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (newValue === 0) {
      mixpanel.track(`${user.email} Clicked on Overview TAB`);
      ReactGA.event({
        //   category: `${user.email}`,
        category: "Bloodhoundmain",
        //   action: `Clicked on IGNORE for /${props.description}`
        action: `Clicked on Overview Tab`,
      });
    } else if (newValue === 1) {
      console.log("Assets");
      mixpanel.track(`${user.email} Clicked on Assets TAB`);
      ReactGA.event({
        //   category: `${user.email}`,
        category: "Bloodhoundmain",
        action: `Clicked on Assets Tab`,
      });
    } else if (newValue === 2) {
      console.log("Network");
      mixpanel.track(`${user.email} Clicked on Network TAB`);
      ReactGA.event({
        //   category: `${user.email}`,
        category: "Bloodhoundmain",
        action: `Clicked on Network Tab`,
      });
    } else if (newValue === 4) {
      console.log("Reports");
      mixpanel.track(`${user.email} Clicked on Reports TAB`);
      ReactGA.event({
        //   category: `${user.email}`,
        category: "Bloodhoundmain",
        action: `Clicked on Reports Tab`,
      });
    } else if (newValue === 5) {
      console.log("Solutions");
      mixpanel.track(`${user.email} Clicked on Solutions TAB`);
      ReactGA.event({
        //   category: `${user.email}`,
        category: "Bloodhoundmain",
        action: `Clicked on Solutions`,
      });
    }
  };

  //Main

  const getTokenDataNewUser = async (tokenfromapicomp) => {
    const token = await getTokenSilently();
    console.log("scanref from if loop" + scanref);
    if (
      scanref &&
      scanref !== "undefined" &&
      (!priorscanref || priorscanref === "undefined")
    ) {
      //update Email and get Quick Data
      const emailUpdated = await updateDataDB(user.email, scanref, token);
      console.log(emailUpdated);
      getAllData(token, scanref, priorscanrefdomains);
    } else if (priorscanref && priorscanref !== "undefined") {
      console.log("Priorscanref is ==>" + priorscanref);
      getAllData(token, priorscanref, priorscanrefdomains);
      console.log("from function that sends prior scanref ==>" + priorscanref);
    }
  };
  const getAllData = async (token, reference, priorscanrefdomains) => {
    setProgress(true);
    console.log("THIS IS returned Token FROM Get" + JSON.stringify(token));

    const resGetDB = await getDataDB(user.email, reference, token);

    //******** */
    //Reload page if quickData is not populated
    if (reference && typeof resGetDB == "undefined") {
      console.log("Reload working");
      console.log("reference is ==>" + reference);
      getTokenDataNewUser();
      //window.location.reload(true);
    } else {
      console.log("Reload not working if its still loading");
      setData(resGetDB);
      setResults(false);

      const trimmedtime = resGetDB.scannedtime.substring(
        0,
        resGetDB.scannedtime.length - 14
      );
      setTime(trimmedtime);
    }

    //Get Firewall
    const resfirewallDB = await getFirewallDB(reference, token);
    console.log(resfirewallDB);
    if (typeof resfirewallDB != "undefined" && resfirewallDB.length > 0) {
      setFirewallData(resfirewallDB);
    }
    //******** */

    //Get Problems
    const resProblemsDB = await getProblemsDB(reference, token);
    console.log(resProblemsDB);
    if (typeof resProblemsDB != "undefined") {
      setProblemsData(resProblemsDB);
    }
    //******** */

    //Get TechStack
    const resTechStackDB = await getTechstackDB(reference, token);
    console.log(resTechStackDB);
    if (typeof resTechStackDB != "undefined" && resTechStackDB.length > 0) {
      setTechstackData(resTechStackDB);
    }
    //******** */

    //Get Grades
    const resGradesDB = await getGradesDB(reference, token);
    console.log(resGradesDB);
    if (typeof resGradesDB != "undefined") {
      setGradesData(resGradesDB);
    }
    //******** */
    // Get DNSRECORD
    const resDNSRecord = await getDNSRec(reference, token);
    console.log(resDNSRecord);
    if (typeof resDNSRecord != "undefined" && resDNSRecord.length > 0) {
      setDNSRecData(resDNSRecord);
    }

    //*********** */
    //Get OtherDomains
    // const resOtherDomainsDB = await getOtherDomainsDB(
    //   dnsdata[0].scanreference,
    //   token
    //   )
    //   console.log(dnsdata)
    //   console.log(resOtherDomainsDB)
    //   if(typeof resOtherDomainsDB != "undefined" && resOtherDomainsDB.length > 0){
    //     setOtherDomainsData(resOtherDomainsDB)
    //   }
    //******** */

    // await localStorage.removeItem("scanref");
    console.log(data);
    //Get DNS
    const resDNS = await getDNSDB(
      token,
      priorscanrefdomains
        ? localStorage.getItem("priorscanrefdomains")
        : localStorage.getItem("scanref")
    );
    console.log(resDNS);
    if (typeof resDNS != "undefined" && resDNS.length > 0) {
      //resDNS.map(e => e === null ? '' : e )
      setDNSData(resDNS);
    }
    //******** */
    setProgress(false);
    //******** */
  };

  return (
    <Paper elevation={0}>
      {noScanrefAlert && <NoScanrefAlert />}
      {console.log("SCANREF from line 151=>" + localStorage.getItem("scanref"))}
      {isAuthenticated &&
      user["http://localhost:3000/is_new"].toString() === "true" ? (
        <>
          <LegalPopUp open={true} />
          <Collapse in={open}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              variant="outlined"
              severity="info"
            >
              Welcome To Bloodhound
            </Alert>
          </Collapse>
        </>
      ) : (
        <></>
      )}
      {isAuthenticated && localStorage.getItem("scanref") ? (
        <div>
          <PostFreeUser pushData={getTokenDataNewUser} />
        </div>
      ) : (
        <div>
          <PostFreeUser pushData={getTokenDataNewUser} />
          {console.log("Result is true")}
        </div>
      )}

      {!result ? (
        <React.Fragment>
          <OverviewScore data={data} time={time} grades={gradesData} />
          <br />
          <br />

          <Paper elevation={15}>
            <div className={classes.root}>
              <AppBar elevation={0} position="static" color="white">
                <div className={classes.verticalBar} id="appbar-collapse">
                  <Tabs
                    orientation="vertical"
                    variant="fullWidth"
                    value={value}
                    elevation={0}
                    indicatorColor="secondary"
                    textColor="#ad2007"
                    onChange={handleChange}
                  >
                    <LinkTab label="Overview" {...a11yProps(0)} />
                    <LinkTab label="Assets" {...a11yProps(1)} />
                    <LinkTab label="Network" {...a11yProps(2)} />
                    <LinkTab label="Architecture" {...a11yProps(3)} />
                    <LinkTab label="Solutions" {...a11yProps(4)} />
                    <LinkTab label="Changes" {...a11yProps(5)} />
                  </Tabs>
                </div>
                <div className={classes.buttonBar} id="appbar-collapse">
                  <Tabs
                    variant="fullWidth"
                    value={value}
                    indicatorColor="secondary"
                    textColor="#ad2007"
                    onChange={handleChange}
                  >
                    <LinkTab label="Overview" {...a11yProps(0)} />
                    <LinkTab label="Assets" {...a11yProps(1)} />
                    <LinkTab label="Network" {...a11yProps(2)} />
                    <LinkTab label="Architecture" {...a11yProps(3)} />
                    <LinkTab label="Solutions" {...a11yProps(4)} />
                    <LinkTab label="Changes" {...a11yProps(5)} />
                  </Tabs>
                </div>
              </AppBar>
            </div>
            <TabPanel value={value} index={0}>
              <br />
              <br />
              <Typography align="center" variant="h4" component="h4">
                <strong> YOUR APP INCLUDES THESE ASSETS </strong>
              </Typography>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={3}
              >
                {Object.keys(data)
                  .filter(
                    (key) =>
                      scanData.filter((i) => i.name === key).length > 0 &&
                      data[key] !== null &&
                      data[key] !== ""
                  )
                  .map((key) => {
                    return (
                      <ServicesComp
                        scanData={data[key]}
                        title={
                          scanData.filter((obj) => obj.name === key)[0].title
                        }
                      />
                    );
                  })}
              </Grid>
              <br />
              <Grid container spacing={3}>
                <Grid style={{ textAlign: "center" }} item xs={12}>
                  <Typography variant="h4" component="h4">
                    <strong>Elapsed Time:</strong> <br />
                  </Typography>
                  <Typography
                    variant="h4"
                    component="h4"
                    style={{ fontFamily: "Orbitron" }}
                  >
                    <TimerIcon style={{ fontSize: 40 }} />
                    {data.elapsedtime} seconds
                  </Typography>
                  <br />
                  <Typography variant="h4" component="h4">
                    <strong>Time Scanned:</strong> <br />
                  </Typography>
                  <Typography
                    variant="h4"
                    component="h4"
                    style={{ fontFamily: "Orbitron" }}
                  >
                    {data.scannedtime.substring(
                      0,
                      data.scannedtime.length - 14
                    )}
                    &nbsp;{data.scannedtime.substring(19, 11)}
                  </Typography>
                </Grid>
              </Grid>
              {/* <Grid container>
                <Grid item xs={12}>
                  <MapGL
                    style={{ width: "100%", height: "400px" }}
                    mapStyle="mapbox://styles/bengiov/ckccymu3e2i9l1ild34v6rbkd"
                    accessToken={process.env.REACT_APP_MAP_TOKEN}
                    latitude={40.444328}
                    longitude={-79.953155}
                    zoom={11}
                  />
                </Grid>
              </Grid> */}
              <br />
              <Container>
                <Grid container spacing={3}>
                  <Grid item xs>
                    <FullScoreCard data={data} />
                  </Grid>
                </Grid>
              </Container>
              <br />
              <Analytics data={techstackData} />
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    style={{ textAlign: "center" }}
                    variant="h4"
                    component="h4"
                  >
                    <strong> PROBLEMS FOUND</strong>
                  </Typography>
                </Grid>
              </Grid>

              <ProblemFound data={problemsData} />
              <Grid item xs={12}>
                <Paper elevation={0} className={classes.paper}>
                  {images.map((image) => (
                    <ButtonBase
                      onClick={() => {
                        setValue(4);
                      }}
                      focusRipple
                      key={image.title}
                      className={classes.image}
                      focusVisibleClassName={classes.focusVisible}
                      style={{
                        width: image.width,
                      }}
                    >
                      <span
                        className={classes.imageSrc}
                        style={{
                          // backgroundImage: `url(${image.url})`,
                          backgroundColor: "#008000",
                        }}
                      />
                      <span className={classes.imageBackdrop} />
                      <span className={classes.imageButton}>
                        <Typography
                          component="span"
                          variant="subtitle1"
                          color="inherit"
                          className={classes.imageTitle}
                        >
                          {image.title}
                          <span className={classes.imageMarked} />
                        </Typography>
                      </span>
                    </ButtonBase>
                  ))}
                </Paper>
              </Grid>
            </TabPanel>

            <TabPanel value={value} index={1}>
              <DNS data={dnsdata} finalscore={gradesData.finalscore} />
            </TabPanel>

            <TabPanel value={value} index={2}>
              <FirewallReport data={firewallData} dnsrec={DNSRec} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <ArchitectureTimeline data={data} firewallInfo={firewallData} />
            </TabPanel>

            <TabPanel value={value} index={4}>
              <Solutions data={data} problems={problemsData} />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <StateChange data={data} grades={gradesData} />
            </TabPanel>
          </Paper>
        </React.Fragment>
      ) : (
        <div>
          <Typography
            style={{
              height: 10,
              marginLeft: "30%",
              marginRight: "15%",
              position: "relative",
              width: "80%",
            }}
          >
            15-30 seconds for quick scan to take full effect. <br />
            Please allow upto 2-10 minutes for our deep scan...
            {/* You dont have any Scans. Please <Link href="/">Start</Link> Scanning
            ... <br />
            If you have scanned earlier please GetReport from your

            <Link href="/History">History</Link>. */}
          </Typography>
          <br />
          <br />
          <ProgressBarc
            style={{
              height: 10,
              marginLeft: "25%",
              marginRight: "25%",
              position: "relative",
              width: "50%",
              backgroundColor: "#ad2007",
            }}
            color="#61ed68"
          />
        </div>
      )}
      <br />
      <br />
    </Paper>
  );
};
export default Bloodhoundmain;
