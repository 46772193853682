import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const theme = createMuiTheme();

theme.typography.h3 = {
  fontSize: "2.2rem",
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "7rem",
  },
};

export default function Test() {
  return (
    <ThemeProvider theme={theme}>
      <Typography
        variant="h3"
        style={{
          marginTop: "15%",
          textAlign: "center",
          fontFamily: "Orbitron",
          color: "#FFFFFF",
        }}
      >
        &lt;bloodhound/&gt;
      </Typography>
    </ThemeProvider>
  );
}
