import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Typography,
  TextField,
  IconButton,
  Grid,
  Paper,
  Tooltip,
  Container,
} from "@material-ui/core";
import getTargets from "../functions/getTargets";
import { useAuth0 } from "../react-auth0-spa";
import CompBar from "../components/Charts/Bar/CompBar";
import Gauge from "../components/Charts/Gauge/Gauge";
import getlogsbymurlemail from "../functions/getlogsbymurlemail";
import getGradesDB from "../functions/getGradesDB";
import getLogCompMurl from "../functions/getLogCompMurl";
import CompareInputGrid from "../components/Comparision/CompareInputGrid";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import GridCompare from "../components/Comparision/GridCompare";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import QueueOutlinedIcon from "@material-ui/icons/QueueOutlined";
import StackPopUp from "./Comparision/StackPopUp";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import postStack from "../functions/postStack";
import getStackDB from "../functions/getStackDB";
import SelectStackPopUp from "./Comparision/SelectStackPopUp";

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "#ad2007",
  },
  checked: {},
}))(Switch);

export default function CompareMurl(props) {
  useEffect(() => {
    getTargetsApi();
  }, []);

  const [chartData, setChartData] = useState([]);
  const [count, setCount] = useState(1);
  const [view, setView] = useState(false);
  const [murl, setMurl] = useState(false);
  const [showPop, setShowPop] = useState(false);

  const [gridCountState, setGridCountState] = useState([]);

  const [targets, setTargets] = useState(false);
  const { isAuthenticated, user, getTokenSilently } = useAuth0();

  const [state, setState] = React.useState({
    checkedC: false,
  });
  const [stack, setStack] = React.useState(false);

  async function getdataDB(murl, item) {
    // setChartData1(false);
    const token = await getTokenSilently();
    const getChangesRes = await getlogsbymurlemail(
      murl,
      `${user.email}`,
      token
    );
    console.log(getChangesRes);
    const getGradesDBRes = await getLogCompMurl(
      getChangesRes.scanreference,
      user.email,
      token
    );
    // getGradesDBRes && setGrade(getGradesDBRes.finalscore);

    const chartD = await CreateChartData(getGradesDBRes, murl, item);
    return chartD;
  }
  async function CreateChartData(data, murl, item) {
    setChartData(false);
    console.log(item);
    console.log("HSTS IS:" + data.hsts);
    const hsts = data.hsts !== 0 ? 1 : 0;
    const csp = data.cspdatafull !== "0" ? 1 : 0;
    const redirect = data.https_redirect ? 1 : 0;
    const scalability = data.loadbalanced ? 1 : 0;
    const waf = data.waf ? 1 : 0;
    const firewall = !data.port22 && !data.port3389 && !data.port3306 ? 1 : 0;
    const policy = data.referrerpolicy ? 1 : 0;
    const sovereign = !data.sovereignissue && !data.nocdnsovereignissue ? 1 : 0;
    const securityscore = data.securityscore;
    const scalabilityscore = data.scalabilityscore;
    const finalscore = data.finalscore;
    const sslissue = data.sslissue ? 0 : 1;

    var array = [...chartData];
    let index = array.findIndex((record) => record.name == murl);
    console.log(index);
    if (index !== -1) {
      array[index] = {
        name: murl,
        data: [
          hsts,
          csp,
          redirect,
          scalability,
          waf,
          firewall,
          policy,
          sovereign,
          sslissue,
        ],
        securityscore: securityscore,
        scalabilityscore: scalabilityscore,
        finalscore: finalscore,
      };
      setChartData(array);
    } else if (chartData.length >= parseInt(item)) {
      console.log(parseInt(item));
      array[parseInt(item)] = {
        name: murl,
        data: [
          hsts,
          csp,
          redirect,
          scalability,
          waf,
          firewall,
          policy,
          sovereign,
          sslissue,
        ],
        securityscore: securityscore,
        scalabilityscore: scalabilityscore,
        finalscore: finalscore,
      };
      setChartData(array);
    } else {
      setChartData([
        ...chartData,
        {
          name: murl,
          data: [
            hsts,
            csp,
            redirect,
            scalability,
            waf,
            firewall,
            policy,
            sovereign,
            sslissue,
          ],
          securityscore: securityscore,
          scalabilityscore: scalabilityscore,
          finalscore: finalscore,
        },
      ]);
    }
    return {
      name: murl,
      data: [
        hsts,
        csp,
        redirect,
        scalability,
        waf,
        firewall,
        policy,
        sovereign,
        sslissue,
      ],
      securityscore: securityscore,
      scalabilityscore: scalabilityscore,
      finalscore: finalscore,
    };
  }
  function removeMurl(item) {
    console.log(item);
    var array = [...chartData]; // make a separate copy of the array
    // let index = array.findIndex((record) => record.name === "securestack.com");
    array.splice(item, 1);
    console.log(array);
    setChartData(array);

    var grid = [...gridCountState]; // make a separate copy of the array
    grid.splice(item, 1);
    console.log(grid);
    setGridCountState(grid);
  }
  async function getTargetsApi() {
    console.log("Hello from targets");
    const token = await getTokenSilently();
    const getTargetsRes = await getTargets(user.email, token);
    setTargets(getTargetsRes);
    // console.log(getTargetsRes);
  }
  const getpushdata = (value, item) => {
    getdataDB(value, item);
  };
  const getpushdataSelectStack = (data) => {
    let res = [];
    setStack(false);
    console.log(data);
    let dataToArray = data.split(",").map((item) => item.trim());
    console.log(dataToArray);
    dataToArray.map(async (item) => {
      const resD = await getdataDB(item, 0);
      console.log(resD);
      res.push(resD);
      console.log(res);
      await setChartData(res);
    });
  };
  const getpushdataPop = (data) => {
    console.log(data);
    if (data.response === "ok") {
      setMurl(data.data);
      postStackDB(data);
    } else {
      setMurl(false);
    }

    setShowPop(false);
  };
  async function postStackDB(data) {
    const token = await getTokenSilently();
    const res = await postStack(user.email, data.data, data.name, token);
    console.log(res);
  }
  async function selectStack() {
    const token = await getTokenSilently();
    const res = await getStackDB(user.email, token);

    if (res.length === 0) {
      console.log("Empty response");
    } else {
      setStack(res);
      console.log(res);
    }
  }
  const handleGrid = () => {
    setGridCountState([...gridCountState, { grid: count + 1 }]);
  };
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    setView(!view);
  };
  const addTargetsToStack = () => {
    setMurl(false);
    let array = [];
    Object.values(chartData).map((item) => {
      array.push(item.name);
    });
    setMurl(array);
    setShowPop(true);
  };
  return (
    <div>
      <Grid>
        {Object.keys(gridCountState).map((item, index) => (
          <Grid container>
            <Grid data xs={10}>
              <CompareInputGrid
                item={item}
                targets={targets}
                pushData={getpushdata}
              />
            </Grid>
            <Grid data xs={2}></Grid>
            {item == gridCountState.length - 1 ? (
              <>
                <IconButton
                  onClick={() => {
                    handleGrid();
                  }}
                >
                  <Tooltip title="Add Target to Compare">
                    <AddBoxOutlinedIcon style={{ fontSize: 30 }} />
                  </Tooltip>
                </IconButton>
                <IconButton
                  onClick={() => {
                    removeMurl(item);
                  }}
                >
                  <Tooltip title="Remove Target ">
                    <DeleteOutlineOutlinedIcon style={{ fontSize: 30 }} />
                  </Tooltip>
                </IconButton>
                <IconButton
                  onClick={() => {
                    !chartData.length <= 0 && addTargetsToStack();
                  }}
                >
                  <Tooltip title="Add to the stack">
                    <QueueOutlinedIcon style={{ fontSize: 30 }} />
                  </Tooltip>
                </IconButton>
              </>
            ) : (
              <Typography style={{ fontSize: 25, color: "#ad2007" }}>
                VS
              </Typography>
            )}
          </Grid>
        ))}
        <Grid data xs={1}>
          {gridCountState.length === 0 && (
            <React.Fragment>
              <IconButton
                onClick={() => {
                  handleGrid();
                }}
              >
                <Tooltip title="Add Target to Compare">
                  <AddBoxOutlinedIcon style={{ fontSize: 30 }} />
                </Tooltip>
              </IconButton>
              <IconButton
                onClick={() => {
                  selectStack();
                }}
              >
                <Tooltip title="Select from the Stack">
                  <ListAltOutlinedIcon style={{ fontSize: 30 }} />
                </Tooltip>
              </IconButton>
            </React.Fragment>
          )}
        </Grid>
        <br /> <br /> <br />
        <Grid container>
          <Grid data xs={1}>
            {gridCountState.length !== 0 && (
              <IconButton
                onClick={() => {
                  selectStack();
                }}
              >
                <Tooltip title="Select from the Stack">
                  <ListAltOutlinedIcon style={{ fontSize: 30 }} />
                </Tooltip>
              </IconButton>
            )}
          </Grid>
          <Grid data xs={8}></Grid>
          <Grid data xs={3}>
            <Typography component="div">
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography style={{ fontFamily: "Orbitron" }}>
                    Tabular-View
                  </Typography>
                </Grid>
                <Grid item>
                  <AntSwitch
                    checked={state.checkedC}
                    onChange={handleChange}
                    name="checkedC"
                  />
                </Grid>
                <Grid item>
                  <Typography style={{ fontFamily: "Orbitron" }}>
                    Chart-View
                  </Typography>
                </Grid>
              </Grid>
            </Typography>
          </Grid>
        </Grid>
        {!view ? (
          <GridCompare
            data={
              chartData.length <= 0
                ? [
                    {
                      name: "Please select",
                      data: [0, 0, 0, 0, 0, 0, 0, 0],
                    },
                  ]
                : chartData
            }
          />
        ) : (
          <CompBar
            chartdata={
              chartData.length <= 0
                ? [
                    {
                      name: "Please select",
                      data: [0, 0, 0, 0, 0, 0, 0, 0],
                    },
                  ]
                : chartData
            }
          />
        )}
      </Grid>
      {showPop && <StackPopUp murl={murl} pushData={getpushdataPop} />}
      {stack && (
        <SelectStackPopUp data={stack} pushData={getpushdataSelectStack} />
      )}
      {console.log(chartData)}
    </div>
  );
}

// async function getdataDB2(murl) {
//   setChartData2(false);
//   const token = await getTokenSilently();
//   const getChangesRes = await getlogsbymurlemail(
//     murl,
//     `${user.email}`,
//     token
//   );
//   console.log(getChangesRes);
//   const getGradesDBRes = await getGradesDB(
//     getChangesRes.scanreference,
//     token
//   );
//   setGrade2(getGradesDBRes.finalscore);
//   CreateChartData2(getChangesRes);
// }
// function CreateChartData2(data) {
//   const hsts = data.hsts !== "0" ? 1 : 0;
//   const csp = data.cspdatafull !== "0" ? 1 : 0;
//   const redirect = data.https_redirect ? 1 : 0;
//   const scalability = data.loadbalanced ? 1 : 0;
//   const waf = data.waf ? 1 : 0;
//   const firewall = !data.port22 && !data.port3389 && !data.port3306 ? 1 : 0;
//   const policy = data.referrerpolicy ? 1 : 0;
//   const sovereign = !data.sovereignissue && !data.nocdnsovereignissue ? 1 : 0;

//   setChartData2({
//     csp: csp,
//     hsts: hsts,
//     redirect: redirect,
//     scalability: scalability,
//     firewall: firewall,
//     waf: waf,
//     policy: policy,
//     sovereign: sovereign,
//   });
// }
// const handleTargetValue1 = (event, value, reason) => {
//   setMurl1(value);
//   getdataDB1(value);
// };
// const handleTargetValue2 = (event, value, reason) => {
//   setMurl2(value);
//   getdataDB2(value);
// };
