import React, { useState, useEffect } from "react";
import postSchedule from "../../functions/postSchedule";
import { useAuth0 } from "../../react-auth0-spa";
import { makeStyles } from "@material-ui/core/styles";

import {
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  Divider,
  Tooltip,
  IconButton,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
const useStyles = makeStyles({
  button: {
    color: "#ad2007",
  },
});
const scheduleTime = [
  {
    value: "daily",
    label: "Daily",
  },
  {
    value: "weekly",
    label: "Weekly",
  },
  { value: "monthly", label: "Monthly" },
  {
    value: "0",
    label: "Unschedule",
  },
];
function SchedulePop(props) {
  const [open, setOpen] = useState(true);
  const [schedmurl, setSchedMurl] = useState(false);
  const [occurence, setOccurence] = useState();
  const { isAuthenticated, getTokenSilently, user } = useAuth0();
  const classes = useStyles();
  const scheduleInfo = {
    scheduleinfo: occurence,
    email: user.email,
  };
  const handleOccurence = (event) => {
    setOccurence(event.target.value);
    console.log(event.target.value);
  };
  const handleClose = () => {
    setOpen(false);
    props.pushData(false);
    console.log(open);
  };
  const handleSubmit = () => {
    UpdateDatabaseSchedule();
  };
  async function UpdateDatabaseSchedule() {
    let email;
    if (isAuthenticated) {
      email = user.email;
    }
    await postSchedule(
      scheduleInfo.email,
      props.murl,
      scheduleInfo.scheduleinfo,
      "token"
    );
    props.pushData(false);
    setOpen(false);
    console.log(
      scheduleInfo.email,
      scheduleInfo.scheduleinfo,
      scheduleInfo.murl
    );
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Scheduled Scan</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please select a scan occurence from the list below.
        </DialogContentText>
        <TextField
          fullWidth
          onChange={handleOccurence}
          value={occurence}
          select
          label="Occurrence"
        >
          {scheduleTime.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button className={classes.button} onClick={handleClose}>
          Cancel
        </Button>
        <Button className={classes.button} onClick={handleSubmit}>
          Schedule
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default SchedulePop;
