import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    width: "99.91%",
    display: "flex-centered",
    marginTop: 50,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

// function createData(port, state, service, description) {
//   return { port, state, service, description };
// }

// const rows = [

//   createData('21/TCP', "Open", "ftp", "Pure-FTPd"),
//   createData('21/TCP', "Open", "ftp", "Pure-FTPd"),
//   createData('21/TCP', "Open", "ftp", "Pure-FTPd"),
//   createData('21/TCP', "Open", "ftp", "Pure-FTPd"),
//   createData('21/TCP', "Open", "ftp", "Pure-FTPd"),

// ];

export default function FirewallReport(props) {
  const classes = useStyles();
  {
    console.log(props.data);
  }

  return (
    <Paper elevation={0}>
      {props.data === false ? (
        <div>
          <p>still Loading...</p>
        </div>
      ) : (
        <div>
          <br />
          <Typography
            style={{ textAlign: "center" }}
            variant="h4"
            component="h4"
          >
            <strong>ANALYTICS AND ADDITIONAL COMPONENTS</strong>
          </Typography>
          <br />
          <div className={classes.root}>
            <Grid
              container
              spacing={1}
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid container item xs={12} spacing={3}>
                {props.data.map((row) => (
                  <Grid item xs={4}>
                    <Paper elevation={0} className={classes.paper}>
                      <Typography
                        style={{ fontFamily: "Orbitron" }}
                        variant="h6"
                        component="h6"
                      >
                        {row.component}
                      </Typography>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </div>
          <br />
          <br />
        </div>
      )}
    </Paper>
  );
}
